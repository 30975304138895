import React from "react";

const Footer = () => {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "3%",
        fontSize: "1rem",
        // lineHeight: "1rem",
        color: "#012970",
      }}
    >
      ©Copyright Orchvate. All Rights Reserved. <br />
    </div>
  );
};

export default Footer;
