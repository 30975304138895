import { Paper } from "@mui/material";
import React from "react";
import logo from "../../assets/img/logo.png";
const TermsAndConditions = () => {
  return (
    <div
      style={{
        //   margin: "20px 10px",
        padding: "2% 10%",
        // maxWidth: "800px",
        margin: "20px auto",
      }}
    >
      <Paper sx={{ padding: "2% 5%" }} elevation={10}>
        <div>
          <div
            className="fr"
            style={{
              alignItems: "center",
            }}
          >
            {" "}
            <a href="/">
              {" "}
              <img height="40px" width="auto" src={logo} alt="JoBro" />
            </a>
            <h1 style={{ textAlign: "center" }}>Terms and Conditions</h1>
          </div>

          <p>
            Welcome to JoBro! These Terms and Conditions govern your access to
            and use of our application and services. By accessing or using our
            application, you agree to be bound by these Terms and Conditions. If
            you do not agree with any part of these Terms and Conditions, you
            may not use our application.
          </p>
          <h3>1. Account Registration</h3>
          <p>
            1.1. In order to access certain features of our application, you may
            be required to create an account. You agree to provide accurate,
            current, and complete information during the registration process
            and to update such information to keep it accurate, current, and
            complete.
          </p>
          <p>
            1.2. You are responsible for maintaining the confidentiality of your
            account credentials and for all activities that occur under your
            account. You agree to notify us immediately of any unauthorized use
            of your account or any other breach of security.
          </p>
          <h3>2. Use of Our Application</h3>
          <p>
            2.1. Our application uses generative AI to assist with job search,
            CV creation, interview preparation, and other related tasks. While
            we strive to provide accurate and helpful suggestions, we cannot
            guarantee the accuracy, completeness, or reliability of the
            information generated by our AI.
          </p>
          <p>
            2.2. You acknowledge and agree that any reliance on the information
            generated by our AI is at your own risk. We recommend that you
            verify any information obtained through our application before
            making decisions based on it.
          </p>

          <h3>3. User Data</h3>
          <p>
            3.1. By using our application, you consent to the collection, use,
            and storage of your personal information in accordance with our
            Privacy Policy. We may collect and store information such as your
            name, email address, CV details, and usage data.
          </p>
          <p>
            3.2. We will not use your personal information for any illegal or
            unauthorized purposes. We are committed to protecting the privacy
            and security of your personal information and will not disclose it
            to third parties except as described in our Privacy Policy or with
            your consent.
          </p>

          <h3>4. Intellectual Property</h3>
          <p>
            4.1. All content and materials available on our application,
            including but not limited to text, graphics, logos, images, and
            software, are the property of [Company Name] or its licensors and
            are protected by copyright, trademark, and other intellectual
            property laws.
          </p>
          <p>
            4.2. You may not modify, reproduce, distribute, transmit, display,
            or create derivative works of any content or materials from our
            application without our prior written consent.
          </p>

          <h3>5. Limitation of Liability</h3>
          <p>
            5.1. To the fullest extent permitted by law, [Company Name] and its
            officers, directors, employees, agents, and affiliates shall not be
            liable for any direct, indirect, incidental, special, consequential,
            or punitive damages arising out of or in any way connected with your
            use of our application.
          </p>
          <p>
            5.2. In no event shall [Company Name]'s total liability to you for
            all damages, losses, and causes of action, whether in contract, tort
            (including negligence), or otherwise, exceed the amount paid by you,
            if any, for accessing or using our application.
          </p>

          <h3>6. Changes to These Terms and Conditions</h3>
          <p>
            6.1. We reserve the right to modify or update these Terms and
            Conditions at any time without prior notice. Any changes will be
            effective immediately upon posting to our application. Your
            continued use of our application after any such changes constitutes
            your acceptance of the new Terms and Conditions.
          </p>

          <h3>7. Governing Law</h3>
          <p>
            7.1. These Terms and Conditions shall be governed by and construed
            in accordance with the laws of India. Any disputes arising under or
            in connection with these Terms and Conditions shall be subject to
            the exclusive jurisdiction of the courts of India.
          </p>

          <h3>8. Contact Us</h3>
          <p>
            8.1. If you have any questions or concerns about these Terms and
            Conditions, please contact us at{" "}
            <a href="mailto:admin@orchvate.com">admin@orchvate.com</a>.
          </p>

          <p>
            Thank you for using JoBro! We hope you find our application helpful
            in your job search and career advancement endeavors.
          </p>
        </div>
      </Paper>
    </div>
  );
};

export default TermsAndConditions;
