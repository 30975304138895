// rootReducer.js
import { combineReducers } from "redux";
import userReducer from "./userReducer";
import cVReducer from "./cvReducer";

const rootReducer = combineReducers({
  user: userReducer,

  // ... other reducers if any
});

export default rootReducer;
