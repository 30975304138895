import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Button,
  ButtonGroup,
  Divider,
  Fade,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../assets/css/guides.css";
import { Link } from "react-router-dom";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { styled } from "@mui/material/styles";

const style = {
  borderColor: "divider",
  backgroundColor: "background.paper",
  border: "1px solid",
};
const guidesText = {
  button_01: "How to set up LinkedIn Account",
  button_04: "How to add work experience to LinkedIn Account",
  button_05: "How do I upload my CV to my LinkedIn Profile?",
  button_06: "What should be added in the About Me section in LinkedIn?",
  button_07: "Should the LinkedIn About Me Section be in the first person?",
  button_08:
    "What should we add in LinkedIn Work Experience section– only Organization, Role, and Period or do we give a brief description?",
  button_09: "Where do we mention Projects on LinkedIn?",
  button_10: "Where do we mention Awards on LinkedIn?",
  button_11: "Where do we include Certifications on LinkedIn?",
  button_12:
    "How do we build our network on LinkedIn? Mention various tips such as - Joining Specific Groups etc.",
  button_13:
    "What’s the process to get recommendations and Endorsements on LinkedIn from our network?",
  button_14: "What should an ideal headline on LinkedIn look like?",
  button_15: "How do I post an article on LinkedIn?",
};

const cvText = {
  button_18: "Should the CV be limited to 1 page?",
  button_19: "Should I include my picture in the CV?",
  button_20:
    "Should I include my internship experience? Where should I include it – under work experience?",
  button_21:
    "Should I include my academic projects? If yes then where should I include it?",
  button_22: "How to mention Achievements/Accomplishments?",
  button_23: "What details should come on top with my Name?",
  button_24: "Should languages be mentioned?",
  button_25: "Should I mention I am looking for WFH or Hybrid?",
  button_26: "Should I mention Location Preference?",
  button_27: "Should I mention my Address on the CV?",
  button_28: "What is the best format for a CV?",
  button_29: "Should I include my GPA on my CV?",
  button_30: "How far back should I list my work experience on my CV?",
  button_31: "Should I include hobbies and interests on my CV?",
  button_32: "How should I address employment gaps on my CV?",
  button_33: "Is it necessary to tailor my CV for each job application?",
  button_34: "Should I include references on my CV?",
  button_35: "How do I highlight my skills effectively on my CV?",
  button_36: "Can I use templates for my CV?",
  button_37: "Should I include a career objective or summary on my CV?",
  button_38: "Is it okay to include volunteer experience on my CV?",
  button_39: "How should I list my education on my CV?",
  button_40:
    "Should I include personal information such as marital status or age on my CV?",
  button_41: "How do I make my CV stand out to employers?",
  button_42: "Should I include a cover letter with my CV?",
  button_43: "Is it acceptable to use graphics or colors on my CV?",
  button_44: "How do I list certifications and licenses on my CV?",
  button_45: "Should I include temporary or short-term jobs on my CV?",
  button_46:
    "How do I handle employment gaps on my CV due to illness or family responsibilities?",
  button_47: "Should I include my salary expectations on my CV?",
};

const interviewText = {
  button_48: "How should I dress for the interview?",
  button_49: "What should I research about the company before the interview?",
  button_50: "How do I answer the 'Tell me about yourself' question?",
  button_51:
    "How much time do I keep for my response to the Qs – Tell me about yourself?",
  button_52: "What questions should I ask the interviewer?",
  button_53:
    "How do I explain gaps in my employment history during the interview?",
  button_54: "How can I prepare for behavioral interview questions?",
  button_55: "Should I bring copies of my resume to the interview?",
  button_56:
    "How do I handle nerves or anxiety before and during the interview?",
  button_57:
    "Is it appropriate to discuss salary and benefits during the interview?",
  button_58: "How do I answer what my expectations are regarding the CTC?",
  button_59:
    "How do I explain why I'm leaving my current job during the interview?",
  button_60:
    "How best can I phrase my response to why I'm looking for a job because the truth is I have been terminated by my current employer and am currently will very soon be jobless?",
  button_61:
    "What should I do if I don't know the answer to an interview question?",
  button_62: "Should I send a thank-you note after the interview?",
  button_63: "How do I talk about my weaknesses during the interview?",
  button_64:
    "Is it okay to ask about the company culture during the interview?",
  button_65: "How do I explain why I want to work for this company?",
  button_66: "Should I mention other job offers during the interview?",
  button_67:
    "How do I demonstrate my qualifications and experience effectively during the interview?",
  button_68:
    "Is it acceptable to bring up flexible work arrangements during the interview?",
  button_69:
    "How do I handle behavioral questions that ask about conflict resolution?",
  button_70:
    "Should I prepare a list of references to provide during the interview?",
  button_71:
    "How do I mention my achievements or accomplishments in an impactful way?",
  button_72:
    "What are they expecting to hear when they ask about accomplishments or achievements?",
  button_73:
    "Can I ask the name or names of the people and their roles who are interviewing me if they do not introduce themselves?",
  button_74: "How do I justify I am the best fit for the role?",
  button_75: "How do I justify if I am looking to change my domain?",
  button_76:
    "I have a lot of experience, and in my job search, I am getting the feedback that I am overqualified. How do I address this?",
  button_77:
    "I often face rejection by the Recruiter when they hear how much my current CTC? How do I deal with this?",
};
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Guides = () => {
  return (
    <div className="fc page-container">
      <div className="fc main-header-div">
        <span className="page-heading"> Guides</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> / guides
        </span>
      </div>

      <div style={{ gap: "4%", margin: "2% 4%" }} className=" fr">
        <section id="guides-section1">
          <div id="guides-sec1-div1">
            <Accordion>
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>LinkedIn Guides</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Object.keys(guidesText).map((key) => (
                  <Link to={`/guides/${key}`} key={guidesText[key]}>
                    <div
                      id="guides-sec1-div1-items"
                      className="fr"
                      fullWidth
                      key={key}
                    >
                      {guidesText[key]}
                    </div>{" "}
                  </Link>
                ))}{" "}
              </AccordionDetails>
            </Accordion>
            <Accordion
            // expanded={expanded === "panel2"}
            // onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography>CV Guides</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {Object.keys(cvText).map((key) => (
                    <Link to={`/guides/${key}`} key={cvText[key]}>
                      <div
                        id="guides-sec1-div1-items"
                        className="fr"
                        fullWidth
                        key={key}
                      >
                        {cvText[key]}
                      </div>{" "}
                    </Link>
                  ))}{" "}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>Interview Guides</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {Object.keys(interviewText).map((key) => (
                    <Link to={`/guides/${key}`} key={interviewText[key]}>
                      <div
                        id="guides-sec1-div1-items"
                        className="fr"
                        fullWidth
                        key={key}
                      >
                        {interviewText[key]}
                      </div>{" "}
                    </Link>
                  ))}{" "}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          {/* <div id="guides-sec1-acc">
            <Accordion>
              <AccordionSummary disabled sx={{ color: "blue" }}>
                <strong> Deserunt ut unde corporis</strong>
              </AccordionSummary>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Debitis adipisci eius?
              </AccordionSummary>
              <AccordionDetails>
                Ut quasi odit odio totam accusamus vero eius. Nostrum asperiores
                voluptatem eos nulla ab dolores est asperiores iure. Quo est
                quis praesentium aut maiores. Corrupti sed aut expedita fugit
                vero dolorem. Nemo rerum sapiente. A quaerat dignissimos.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Debitis adipisci eius?
              </AccordionSummary>
              <AccordionDetails>
                Ut quasi odit odio totam accusamus vero eius. Nostrum asperiores
                voluptatem eos nulla ab dolores est asperiores iure. Quo est
                quis praesentium aut maiores. Corrupti sed aut expedita fugit
                vero dolorem. Nemo rerum sapiente. A quaerat dignissimos.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Debitis adipisci eius?
              </AccordionSummary>
              <AccordionDetails>
                Ut quasi odit odio totam accusamus vero eius. Nostrum asperiores
                voluptatem eos nulla ab dolores est asperiores iure. Quo est
                quis praesentium aut maiores. Corrupti sed aut expedita fugit
                vero dolorem. Nemo rerum sapiente. A quaerat dignissimos.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Debitis adipisci eius?
              </AccordionSummary>
              <AccordionDetails>
                Ut quasi odit odio totam accusamus vero eius. Nostrum asperiores
                voluptatem eos nulla ab dolores est asperiores iure. Quo est
                quis praesentium aut maiores. Corrupti sed aut expedita fugit
                vero dolorem. Nemo rerum sapiente. A quaerat dignissimos.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Debitis adipisci eius?
              </AccordionSummary>
              <AccordionDetails>
                Ut quasi odit odio totam accusamus vero eius. Nostrum asperiores
                voluptatem eos nulla ab dolores est asperiores iure. Quo est
                quis praesentium aut maiores. Corrupti sed aut expedita fugit
                vero dolorem. Nemo rerum sapiente. A quaerat dignissimos.
              </AccordionDetails>
            </Accordion>
          </div> */}
        </section>
        {/* <section id="guides-section2">
          <div id="guides-acc-1">
            <Accordion>
              <AccordionSummary disabled sx={{ color: "blue" }}>
                <strong> Deserunt ut unde corporis</strong>
              </AccordionSummary>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Debitis adipisci eius?
              </AccordionSummary>
              <AccordionDetails>
                Ut quasi odit odio totam accusamus vero eius. Nostrum asperiores
                voluptatem eos nulla ab dolores est asperiores iure. Quo est
                quis praesentium aut maiores. Corrupti sed aut expedita fugit
                vero dolorem. Nemo rerum sapiente. A quaerat dignissimos.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Omnis fugiat quis repellendus?
              </AccordionSummary>
              <AccordionDetails>
                In minus quia impedit est quas deserunt deserunt et. Nulla non
                quo dolores minima fugiat aut saepe aut inventore. Qui nesciunt
                odio officia beatae iusto sed voluptatem possimus quas. Officia
                vitae sit voluptatem nostrum a.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Et occaecati praesentium aliquam modi incidunt?
              </AccordionSummary>
              <AccordionDetails>
                Voluptates magni amet enim perspiciatis atque excepturi itaque
                est. Sit beatae animi incidunt eum repellat sequi ea saepe
                inventore. Id et vel et et. Nesciunt itaque corrupti quia
                ducimus. Consequatur maiores voluptatum fuga quod ut non fuga.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Quo unde eaque vero dolor quis ipsam?
              </AccordionSummary>
              <AccordionDetails>
                Numquam ut reiciendis aliquid. Quia veritatis quasi ipsam sed
                quo ut eligendi et non. Doloremque sed voluptatem at in voluptas
                aliquid dolorum.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Debitis adipisci eius?
              </AccordionSummary>
              <AccordionDetails>
                Ut quasi odit odio totam accusamus vero eius. Nostrum asperiores
                voluptatem eos nulla ab dolores est asperiores iure. Quo est
                quis praesentium aut maiores. Corrupti sed aut expedita fugit
                vero dolorem. Nemo rerum sapiente. A quaerat dignissimos.
              </AccordionDetails>
            </Accordion>
          </div>
          <div id="guides-acc-2">
            <Accordion>
              <Accordion>
                <AccordionSummary disabled sx={{ color: "blue" }}>
                  <strong> Dolore occaecati ducimus quam</strong>
                </AccordionSummary>
              </Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Debitis adipisci eius?
              </AccordionSummary>
              <AccordionDetails>
                Ut quasi odit odio totam accusamus vero eius. Nostrum asperiores
                voluptatem eos nulla ab dolores est asperiores iure. Quo est
                quis praesentium aut maiores. Corrupti sed aut expedita fugit
                vero dolorem. Nemo rerum sapiente. A quaerat dignissimos.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Debitis adipisci eius?
              </AccordionSummary>
              <AccordionDetails>
                Ut quasi odit odio totam accusamus vero eius. Nostrum asperiores
                voluptatem eos nulla ab dolores est asperiores iure. Quo est
                quis praesentium aut maiores. Corrupti sed aut expedita fugit
                vero dolorem. Nemo rerum sapiente. A quaerat dignissimos.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Debitis adipisci eius?
              </AccordionSummary>
              <AccordionDetails>
                Ut quasi odit odio totam accusamus vero eius. Nostrum asperiores
                voluptatem eos nulla ab dolores est asperiores iure. Quo est
                quis praesentium aut maiores. Corrupti sed aut expedita fugit
                vero dolorem. Nemo rerum sapiente. A quaerat dignissimos.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Debitis adipisci eius?
              </AccordionSummary>
              <AccordionDetails>
                Ut quasi odit odio totam accusamus vero eius. Nostrum asperiores
                voluptatem eos nulla ab dolores est asperiores iure. Quo est
                quis praesentium aut maiores. Corrupti sed aut expedita fugit
                vero dolorem. Nemo rerum sapiente. A quaerat dignissimos.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Debitis adipisci eius?
              </AccordionSummary>
              <AccordionDetails>
                Ut quasi odit odio totam accusamus vero eius. Nostrum asperiores
                voluptatem eos nulla ab dolores est asperiores iure. Quo est
                quis praesentium aut maiores. Corrupti sed aut expedita fugit
                vero dolorem. Nemo rerum sapiente. A quaerat dignissimos.
              </AccordionDetails>
            </Accordion>
          </div>
        </section> */}
      </div>
    </div>
  );
};

export default Guides;
