import React, { useEffect, useState } from "react";
import WorkExperiencePage from "./WorkExperiencePage";
import EducationPage from "./CVeducation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";

import Certification from "./Certification";
import Achievements from "./Achievements";
import axios from "axios";
import "../../assets/css/Cv.css";
import { useDispatch, useSelector } from "react-redux";
import AIGenAbout from "./AIGenAbout";
import { updateCv } from "../../store/action";
import Hobbies from "./CV Components/Hobbies";
import Languages from "./CV Components/Languages";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? "normal" : "400",
    color: personName.indexOf(name) === -1 ? "grey" : "black",
  };
}
const CvBuilder = ({ baseUrl }) => {
  // const DummyUrl = "http://localhost:8000/api";
  const cvBaseUrl = " https://jobrobackendcv.azurewebsites.net/api/";
  // const cvBaseUrl = "http://localhost:8000/api/";

  const [dataSaved, setDataSaved] = useState(false);
  const [skillList, setSkillList] = useState([]);
  const [CvSavedResponse, setCvSavedResponse] = useState();
  const [achievements, setAchievements] = useState([]);
  const [technicalSkills, setTechnicalSkills] = useState([]);
  const [cvName, setCvName] = useState("");
  const navigate = useNavigate();
  // const handleSkillsChange = (event) => {
  //   const newSkills = event.target.value;
  //   setSelectedSkills(newSkills);
  // };

  const handleSkillsChange = (event) => {
    const {
      target: { value },
    } = event;
    setTechnicalSkills(typeof value === "string" ? value.split(",") : value);
  };

  //work Experience
  const [workExperiences, setWorkExperiences] = useState([
    {
      id: Date.now().toString(36),
      startDate: "",
      endDate: "",
      employer: "",
      role: "",
      description: "",
      currentlyWorking: null,
    },
  ]);
  const handleWorkExperienceChange = (newWorkExperiences) => {
    setWorkExperiences(newWorkExperiences);
  };

  const addWorkExperience = () => {
    setWorkExperiences((prevExperiences) => [
      ...prevExperiences,
      {
        id: Date.now().toString(36),
        startDate: "",
        endDate: "",
        employer: "",
        role: "",
        description: "",
        currentlyWorking: null,
      },
    ]);
  };

  const handleWorkInputChange = (id, field, value) => {
    setWorkExperiences((prevExperiences) =>
      prevExperiences.map((experience) =>
        experience.id === id ? { ...experience, [field]: value } : experience
      )
    );
  };
  const deleteWorkExperience = (id) => {
    setWorkExperiences((prevExperiences) =>
      prevExperiences.filter((experience) => experience.id !== id)
    );
  };
  //Education
  const handleEducationChange = (newEducations) => {
    setEducations(newEducations);
  };
  const [educations, setEducations] = useState([
    {
      id: Date.now().toString(36),
      startDate: "",
      endDate: "",
      institution: "",
      degree: "",
      currentlyEnrolled: null,
    },
  ]);

  const addEducation = () => {
    setEducations((prevEducations) => [
      ...prevEducations,
      {
        id: Date.now().toString(36),
        startDate: "",
        endDate: "",
        institution: "",
        degree: "",
        currentlyEnrolled: null,
      },
    ]);
  };

  const handleEducationInputChange = (id, field, value) => {
    setEducations((prevEducations) =>
      prevEducations.map((education) =>
        education.id === id ? { ...education, [field]: value } : education
      )
    );
  };

  const deleteEducation = (id) => {
    setEducations((prevEducations) =>
      prevEducations.filter((education) => education.id !== id)
    );
  };

  //Certification
  const handleCertificationChange = (newCertificates) => {
    setCertifications(newCertificates);
  };

  const [certifications, setCertifications] = useState([]);

  const handleCertificateInputChange = (id, field, value) => {
    setCertifications((prevCert) =>
      prevCert.map((certt) =>
        certt.id === id ? { ...certt, [field]: value } : certt
      )
    );
  };
  const handleAddCertification = () => {
    setCertifications((prevCert) => [
      ...prevCert,
      {
        id: Date.now().toString(36),
        startDate: null,
        endDate: null,
        title: "",
        link: "",
      },
    ]);
  };

  const handleRemoveCertification = (id) => {
    setCertifications((prevCertifications) =>
      prevCertifications.filter((cert) => cert.id !== id)
    );
  };

  //Achievement
  const handleAchievementChange = (index, newach) => {
    const updatedAchievements = [...achievements];
    updatedAchievements[index] = newach;
    setAchievements(updatedAchievements);
  };
  const handleAddAchievement = () => {
    setAchievements([...achievements, ""]);
  };

  const handleRemoveAchievement = (index) => {
    const updatedAchievements = [...achievements];
    updatedAchievements.splice(index, 1);
    setAchievements(updatedAchievements);
  };

  //JD

  const [jobdescription, setJobdescription] = useState("");
  //Role
  const [role, setRole] = useState("");
  const user_id = useSelector((state) => {
    return state.user.user_id;
  });

  //Hobbies
  const [hobbies, setHobbies] = useState([]);
  const handleHobbiesChange = (event) => {
    const {
      target: { value },
    } = event;
    setHobbies(typeof value === "string" ? value.split(",") : value);
  };

  //Language
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const handleLanguageChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLanguages(typeof value === "string" ? value.split(",") : value);
  };

  const userData = useSelector((state) => {
    return state.user.userData;
  });

  const [about, setAbout] = useState(userData.about);
  const [finalAbout, setFinalAbout] = useState();
  const [openDialog, setOpenDialog] = useState(true);
  const [selectedCvId, setSelectedCvId] = useState();
  const cvListState = useSelector((state) => {
    return state.user.CvListState;
  });
  const handleCloseDialog = () => {
    const foundObject = cvListState.find((obj) => obj._id === selectedCvId);
    // Check if foundObject is not undefined before accessing its properties
    if (foundObject) {
      setTechnicalSkills(foundObject.skills);
      setWorkExperiences(foundObject.work_experience);
      setEducations(foundObject.education);
      setCertifications(foundObject.certifications);
      setAchievements(foundObject.achievements);
      setJobdescription(foundObject.jobdescription);
      setAbout(foundObject.about);
      setFinalAbout(foundObject.about);
      setRole(foundObject.role);
      setOpenDialog(false);
      setHobbies(foundObject.hobbies);
      setSelectedLanguages(foundObject.selectedLanguages);

      setToFillCv(false);
    } else {
      console.log("Object not found");
      // Handle case where object is not found
    }
  };
  const dispatch = useDispatch();

  const saveCV = async () => {
    try {
      // console.log("user", user_id);
      const data = {
        User_id: user_id,
        cv: {
          work_experience: workExperiences,
          education: educations,
          skills: technicalSkills,
          achievements: achievements,
          certifications: certifications,
          jobdescription: jobdescription,
          about: finalAbout ? finalAbout : about,
          hobbies: hobbies,
          languages: selectedLanguages,
          role: role,
          template: template,
        },
      };
      console.log("CV savedkkkk:", data);
      const res = await axios.post(`${cvBaseUrl}createUser`, data);
      // console.log("res", response);
      setCvSavedResponse(res.data);
      // console.log("saved", res.data);
      setDataSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/v1/get_skills`);
        // console.log("THIS IS CV BUILDER", response.data);
        setSkillList(response.data);
      } catch (error) {}
    };
    callApi();
  }, []);
  const location = useLocation();
  const { toAddJobdescription, template } = location.state;

  const [toFillCv, setToFillCv] = useState(true);
  return (
    <div className="fc  page-container">
      <div className="fc main-header-div">
        <span className="page-heading"> CV Builder</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> / <Link to="/cvList">cv assistant</Link> / cv
          builder
        </span>
      </div>

      <div>
        {toFillCv && cvListState ? (
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {
                "Do you want to use any of the previous CV's to fill in the details?"
              }
            </DialogTitle>

            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                sx={{ padding: "0 3%" }}
              >
                {cvListState.map((item, index) => {
                  // console.log("l", cvListState);
                  // console.log("k", item);
                  return (
                    <FormControlLabel
                      value={item._id}
                      onClick={() => setSelectedCvId(item._id)}
                      control={<Radio />}
                      label={item.name}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
            <DialogActions>
              <Button
                onClick={() => {
                  setToFillCv(false);
                  setOpenDialog(false);
                }}
              >
                NO
              </Button>

              <Button onClick={handleCloseDialog} autoFocus>
                YES
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <div>
            {" "}
            {dataSaved ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  value={cvName}
                  onChange={(e) => {
                    setCvName(e.target.value);
                  }}
                  required
                  id="outlined-basic"
                  label="Name Your File"
                  variant="outlined"
                />
                <Button
                  onClick={async () => {
                    try {
                      const data = {
                        User_id: user_id,
                        Cv_id: CvSavedResponse.cvId,
                        Cv_Name: cvName,
                      };

                      const res = await axios.put(
                        `${cvBaseUrl}updateCvName`,
                        data
                      );

                      const res2 = await axios.get(
                        `${cvBaseUrl}getUserCV/${user_id}`
                      );

                      if (res2.data.success === true) {
                        dispatch(updateCv(res2.data.Cv));
                      }
                      navigate("/cvList");
                    } catch (error) {
                      console.error("Error updating CV name:", error);
                    }
                  }}
                >
                  SAVE
                </Button>
              </div>
            ) : (
              <div className="cv-info-container">
                {toAddJobdescription === "true" && (
                  <div style={{ margin: "0 5% 3% 5%" }}>
                    <h1 style={{}}>Job Description</h1>
                    <TextField
                      fullWidth
                      sx={{ margin: "" }}
                      name="job description"
                      value={jobdescription}
                      onChange={(e) => {
                        setJobdescription(e.target.value);
                      }}
                      required
                      id="outlined-basic"
                      label="Job Description"
                      variant="outlined"
                    />
                  </div>
                )}

                <div
                  style={{
                    margin: "0 5%",
                  }}
                >
                  <h1 style={{ marginTop: "1%" }}>Role</h1>
                  <TextField
                    fullWidth
                    sx={{ margin: "2% 0", marginTop: "0" }}
                    defaultValue={role}
                    onChange={(e) => setRole(e.target.value)}
                    required
                    id="outlined-basic"
                    label="Role"
                    variant="outlined"
                  />
                </div>
                <div
                  style={{
                    margin: "0 5%",
                  }}
                >
                  <h1 style={{ marginTop: "3%" }}>Skills</h1>
                  <FormControl
                    sx={{ marginTop: "0", marginBottom: "-2.5%" }}
                    fullWidth
                  >
                    {/* <InputLabel id="demo-multiple-chip-label">Skills</InputLabel> */}
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={technicalSkills}
                      onChange={handleSkillsChange}
                      input={<OutlinedInput id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {skillList.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, technicalSkills)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <WorkExperiencePage
                  onChange={handleWorkExperienceChange}
                  workExperiences={workExperiences}
                  setWorkExperiences={setWorkExperiences}
                  handleWorkInputChange={handleWorkInputChange}
                  addWorkExperience={addWorkExperience}
                  deleteWorkExperience={deleteWorkExperience}
                  jobdescription={jobdescription}
                />
                <EducationPage
                  onChange={handleEducationChange}
                  educations={educations}
                  setEducations={setEducations}
                  handleeducationInputChange={handleEducationInputChange}
                  deleteEducation={deleteEducation}
                  addEducation={addEducation}
                />
                <Certification
                  onChange={handleCertificationChange}
                  certifications={certifications}
                  setCertifications={setCertifications}
                  handleCertificateInputChange={handleCertificateInputChange}
                  handleAddCertification={handleAddCertification}
                  handleRemoveCertification={handleRemoveCertification}
                />
                <Achievements
                  achievements={achievements}
                  handleAddAchievement={handleAddAchievement}
                  handleRemoveAchievement={handleRemoveAchievement}
                  handleAchievementChange={handleAchievementChange}
                  jobdescription={jobdescription}
                />
                <AIGenAbout
                  about={about}
                  setAbout={setAbout}
                  finalAbout={finalAbout}
                  setFinalAbout={setFinalAbout}
                  jobdescription={jobdescription}
                  workExperiences={workExperiences}
                  educations={educations}
                  skills={technicalSkills}
                />
                <Hobbies
                  hobbies={hobbies}
                  setHobbies={setHobbies}
                  handleHobbiesChange={handleHobbiesChange}
                />
                <Languages
                  selectedLanguages={selectedLanguages}
                  setSelectedLanguages={setSelectedLanguages}
                  handleLanguageChange={handleLanguageChange}
                />

                <div
                  className="fr"
                  style={{
                    margin: "5%",
                    justifyContent: "right",
                    paddingBottom: "2%",
                  }}
                >
                  <Button
                    style={{ width: "fit-content", backgroundColor: "#012970" }}
                    variant="contained"
                    onClick={saveCV}
                  >
                    Save Details
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CvBuilder;
