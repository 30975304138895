import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";

import AiGenAch from "./AiGenAch";

const Achievements = ({
  loading,
  achievements,
  handleAddAchievement,
  handleRemoveAchievement,
  handleAchievementChange,
  jobdescription,
}) => {
  return (
    <div className="fc" style={{ margin: "5%", width: "90%" }}>
      <h1>Achievements</h1>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div>
          {achievements.map((achievementItem, index) => (
            <AiGenAch
              index={index}
              achievement={achievementItem}
              handleAchievementChange={handleAchievementChange}
              handleRemoveAchievement={handleRemoveAchievement}
              jobdescription={jobdescription}
            />
          ))}

          <Button
            style={{
              width: "fit-content",
              margin: "2% 0",
              backgroundColor: "#012970",
            }}
            variant="contained"
            onClick={handleAddAchievement}
          >
            +Add Achievement
          </Button>
        </div>
      )}
    </div>
  );
};

export default Achievements;
