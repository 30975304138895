import React, { useState } from "react";

// const appId = "<INSERT_SPEECHLY_APP_ID_HERE>"; // Replace with your actual appId
// const SpeechlySpeechRecognition = createSpeechlySpeechRecognition(appId);
// SpeechRecognition.applyPolyfill(SpeechlySpeechRecognition);

import "../../assets/css/help.css";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
const Help = () => {
  return (
    <div className="fc page-container">
      <div className="fc main-header-div">
        <span className="page-heading"> Help</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> / <Link to="/cvList">help</Link>
        </span>
      </div>
      <div>
        <div>
          <TextField label="" />
        </div>

        <div>
          <TextField label="" />
        </div>
      </div>
    </div>
  );
};

export default Help;
