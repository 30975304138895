import axios from "axios";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const CSResult = ({ JobroRes }) => {
  const baseUrl = "https://jobro.orchvate.com/api/v1/";

  return (
    <div>
      <div
        style={{
          // padding: "2%",
          // boxShadow:
          //   " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          margin: "3% 0",
          whiteSpace: "pre-line",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          height: "fit-content",

          padding: "3%",
          gap: "1rem",
        }}
      >
        <h2>JoBro Response:</h2> {JobroRes}
      </div>
      <div
        style={{
          padding: "1%",
          fontFamily: "Cutive Mono, monospace",
          textAlign: "center",
        }}
      >
        Disclaimer Remember that while AI can assist in various tasks, its
        performance is contingent on the quality of the data it interacts with.
        Taking steps to ensure data accuracy is essential for obtaining reliable
        and meaningful results. Thank you for your understanding and commitment
        to using AI responsibly. Remember that while AI can assist in various
        tasks, its performance is contingent on the quality of the data it
        interacts with. Taking steps to ensure data accuracy is essential for
        obtaining reliable and meaningful results. Thank you for your
        understanding and commitment to using AI responsibly.
      </div>
    </div>
  );
};

export default CSResult;
