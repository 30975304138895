import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const COResult = ({ baseUrl, fieldOfInterest, yoe }) => {
  const [result, setResult] = useState([]);

  const user_id = useSelector((state) => {
    return state.user.user_id;
  });
  useEffect(() => {
    const callAPI = async () => {
      if (result.length === 0) {
        try {
          const data = {
            user_id: user_id,

            field_of_interest: fieldOfInterest,
            years_of_exp: yoe,
          };
          // console.log("data", data);
          const response = await axios.post(
            `${baseUrl}/api/v1/insert_career_option`,
            data
          );
          const result = await response.data;
          // console.log("HI", result);
          setResult(result);
        } catch (error) {
          console.error(error);
        }
      }
    };

    callAPI();
  }, []);
  return (
    <div>
      <div
        className="fc"
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          height: "fit-content",

          padding: "3%",
          margin: "5% 20%",
          gap: "1rem",
        }}
      >
        <span>
          <strong style={{ fontSize: "1.5rem" }}>JoBro Response:</strong>
        </span>
        <div>
          {result && result.ai_response ? (
            <div style={{ whiteSpace: "pre-line" }}> {result.ai_response}</div>
          ) : (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          )}
        </div>
      </div>
      <div
        style={{
          padding: "0 3%",
          fontFamily: "Cutive Mono, monospace",
          textAlign: "center",
        }}
      >
        Disclaimer Remember that while AI can assist in various tasks, its
        performance is contingent on the quality of the data it interacts with.
        Taking steps to ensure data accuracy is essential for obtaining reliable
        and meaningful results. Thank you for your understanding and commitment
        to using AI responsibly. Remember that while AI can assist in various
        tasks, its performance is contingent on the quality of the data it
        interacts with. Taking steps to ensure data accuracy is essential for
        obtaining reliable and meaningful results. Thank you for your
        understanding and commitment to using AI responsibly.
      </div>
    </div>
  );
};

export default COResult;
