import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const InterpersonalskillsBlock = ({
  item,
  isLast,
  currIndex,
  setCurrIndex,
  setUserRes_Interpersonal_Skills,
  handleSelectionChange,
  UserRes_Interpersonal_Skills,
  UserRes_ND_Characteristics,
  UserRes_Executive_Functioning,
  SkillList_exec_func,
  SkillList_inter_pers,
}) => {
  const OptionList = {
    "Applies to me a lot": 5,
    "Applies to me": 4,
    "Applies to me somewhat": 3,
    "Applies to me a little": 2,
    "Does not apply to me at all": 1,
  };

  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    const skillResponses = UserRes_Interpersonal_Skills[item.SkillName] || [];
    const initialResponse = skillResponses.find(
      (response) => response.Description === item.Description
    )?.Response;

    if (initialResponse !== undefined) {
      const initialOption = Object.keys(OptionList).find(
        (key) => OptionList[key] === initialResponse
      );
      setSelectedValue(initialOption);
    } else {
      setSelectedValue("");
    }
  }, [
    currIndex,
    UserRes_Interpersonal_Skills,
    item.Description,
    item.SkillName,
  ]);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const saveResponse = () => {
    const updatedResponse = {
      Description: item.Description,
      Response: OptionList[selectedValue],
    };

    setUserRes_Interpersonal_Skills((prevResponses) => {
      const newResponses = { ...prevResponses };

      if (!newResponses[item.SkillName]) {
        newResponses[item.SkillName] = [];
      }

      const responseIndex = newResponses[item.SkillName].findIndex(
        (response) => response.Description === item.Description
      );

      if (responseIndex > -1) {
        newResponses[item.SkillName][responseIndex] = updatedResponse;
      } else {
        newResponses[item.SkillName].push(updatedResponse);
      }

      return newResponses;
    });
  };

  const handleNext = () => {
    saveResponse();
    setCurrIndex(currIndex + 1);
    console.log("Updated Responses:", UserRes_Interpersonal_Skills);
  };

  const handlePrevious = () => {
    saveResponse();
    setCurrIndex(currIndex - 1);
    if (currIndex === 0) {
      handleSelectionChange("div2");
    }
  };

  const navigate = useNavigate();
  const SaveFinal = () => {
    saveResponse();
    navigate("/GenerateReport", {
      state: {
        UserRes_Interpersonal_Skills: UserRes_Interpersonal_Skills,
        UserRes_ND_Characteristics: UserRes_ND_Characteristics,
        UserRes_Executive_Functioning: UserRes_Executive_Functioning,
        SkillList_exec_func: SkillList_exec_func,
        SkillList_inter_pers: SkillList_inter_pers,
      },
    });
  };

  return (
    <div>
      <div className="fc main-div-selfAssess">
        <div id="selfAssess-heading">{item.Description}</div>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            sx={{ gap: "5px" }}
            className="fc"
            value={selectedValue}
            onChange={handleRadioChange}
          >
            {Object.keys(OptionList).map((optionItem, index) => (
              <FormControlLabel
                key={index}
                value={optionItem}
                control={<Radio />}
                label={optionItem}
                defaultChecked={optionItem === selectedValue}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <div className="fr" style={{ justifyContent: "space-between" }}>
          <Button variant="outlined" onClick={handlePrevious}>
            Previous
          </Button>
          {isLast ? (
            <Button variant="outlined" onClick={SaveFinal}>
              Next
            </Button>
          ) : (
            <Button variant="outlined" onClick={handleNext}>
              Next
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default InterpersonalskillsBlock;
