import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";

const ICGeneratedQuestions = ({ typeOfInterview, role, yoe, baseUrl }) => {
  const [quesAns, setQuesAns] = useState("");
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    const callApi = async () => {
      try {
        setLoading(true);
        const data = {
          user_id: "1",
          interview_type: typeOfInterview,
          position: role,
          years_work_exp: yoe,
        };
        const response = await axios.post(
          `${baseUrl}/api/v1/insert_interview_question`,
          data
        );
        // console.log(response.data);
        // console.log("HOOO", response.data.ai_response);

        // const temp = response.data.ai_response.split("\n");
        // const questionsArray = questionsString.split('\n\n');
        // const temp = DOMPurify.sanitize(response.data.ai_response);
        setQuesAns(response.data.ai_response);
        // console.log("Hi", quesAns);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    callApi();
  }, []);

  return (
    <div
    // className="page-container"
    >
      {/* <div className="fc ">
        <span className="page-heading"> Guides</span>
        <span className="page-top-item2">
          <Link to="/">Home</Link> /{" "}
          <Link to="/interviewCoach">Interview Coach</Link> /{" "}
          <Link to="/interviewQuestions">Typical Interview Questions</Link>
        </span>
      </div> */}
      <div
        className="fc"
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          height: "fit-content",

          padding: "3%",
          gap: "1rem",
        }}
      >
        <span>
          <strong style={{ fontSize: "1.5rem" }}>JoBro Response:</strong>
        </span>
        <div>
          {isLoading && (
            <Box
              sx={{
                display: "flex",

                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {quesAns && <div style={{ whiteSpace: "pre-line" }}> {quesAns}</div>}
        </div>
      </div>
    </div>
  );
};

export default ICGeneratedQuestions;
