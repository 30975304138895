import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CSResult from "./CSResult";

const QuesForInterviewer = () => {
  const [Role, setRole] = useState("");
  const [TypeOfInterview, setTypeOfInterview] = useState("");
  const [LevelOfInterviewer, setLevelOfInterviewer] = useState("");
  const [JobDescription, setJobDescription] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [AboutCompany, setAboutCompany] = useState("");
  const [loading, setLoading] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [JobroRes, setJobroRes] = useState("");
  // const dummyUrl = "http://localhost:8000/api";
  const dummyUrl = "https://jobrobackendcv.azurewebsites.net/api/";

  const baseUrl = "https://jobro.orchvate.com/api/v1/";

  const handleProceed = async () => {
    setLoading(true);
    try {
      const data = {
        role: Role,
        interviewType: TypeOfInterview,
        interviewerLevel: LevelOfInterviewer,
        jobDescription: JobDescription,
        companyWebsite: companyWebsite,
        aboutCompany: AboutCompany,
      };
      console.log("dddd", data);
      const res = await axios.post(`${dummyUrl}/quesForInterview`, data);
      console.log("ffff", res);
      const data2 = {
        button_id: "button_88",
      };

      const response = await axios.post(`${baseUrl}fetch_prompt`, data2);
      let prompt = response.data.prompt;
      console.log("LLLL", prompt);
      prompt = prompt.replace(/\[\[Role\]\]/g, Role);
      prompt = prompt.replace(/\[\[Type_of_Interview\]\]/g, TypeOfInterview);
      prompt = prompt.replace(
        /\[\[Position_of_Interviewer\]\]/g,
        LevelOfInterviewer
      );
      prompt = prompt.replace(/\[\[Job_Description\]\]/g, JobDescription);
      prompt = prompt.replace(/\[\[Company_Website\]\]/g, companyWebsite);
      prompt = prompt.replace(/\[\[About_Company\]\]/g, AboutCompany);

      console.log("lll", prompt);
      const res2 = await axios.post(`${baseUrl}get_ai_response`, {
        prompt_message: prompt,
      });
      setJobroRes(res2.data.ai_response);
    } catch (error) {
      console.log(error);
    } finally {
      setProceed(true);
      setLoading(false);
    }
  };
  return (
    <div className="page-container">
      <div className="fc main-header-div">
        <span className="page-heading">Question For Interviewer</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> /{" "}
          <Link to="/knowledgeBase">knowledge base</Link> / question for
          interviewer
        </span>
      </div>

      {proceed ? (
        <CSResult JobroRes={JobroRes} />
      ) : (
        <div
          className=" fc"
          style={{
            gap: "2rem",
            margin: "2% auto",
            // width: "800px",
            width: "60%",
          }}
        >
          <TextField
            required
            fullWidth
            id="outlined-required"
            label="Role for which you are applying"
            value={Role}
            onChange={(e) => {
              setRole(e.target.value);
            }}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Type of Interview
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Type of Interview"
              value={TypeOfInterview}
              onChange={(e) => {
                setTypeOfInterview(e.target.value);
              }}
            >
              <MenuItem value={"Technical Interview"}>
                Technical Interview{" "}
              </MenuItem>
              <MenuItem value={"HR Interview"}>HR Interview </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Level of the Interviewer
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Level of the Interviewer"
              value={LevelOfInterviewer}
              onChange={(e) => {
                setLevelOfInterviewer(e.target.value);
              }}
            >
              <MenuItem value={"Beginner"}>Beginner </MenuItem>
              <MenuItem value={"Intermediate"}>Intermediate </MenuItem>

              <MenuItem value={"Advanced"}>Advanced</MenuItem>
            </Select>
          </FormControl>
          <TextField
            required
            fullWidth
            id="outlined-required"
            label="Job Description "
            rows={8}
            multiline
            value={JobDescription}
            onChange={(e) => {
              setJobDescription(e.target.value);
            }}
          />
          <TextField
            required
            fullWidth
            id="outlined-required"
            label="Company Website"
            value={companyWebsite}
            onChange={(e) => {
              setCompanyWebsite(e.target.value);
            }}
          />
          <TextField
            required
            fullWidth
            id="outlined-required"
            label="About the Company "
            value={AboutCompany}
            onChange={(e) => {
              setAboutCompany(e.target.value);
            }}
          />

          <Button
            variant="contained"
            onClick={handleProceed}
            sx={{
              width: "fit-content",
              padding: "1%",
              alignSelf: "center",
              backgroundColor: "#012970",
            }}
          >
            {" "}
            {loading ? <CircularProgress /> : "Proceed"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default QuesForInterviewer;
