export const updateSession = (sessionId) => {
  return {
    type: "UPDATE_SESSION",
    payload: sessionId,
  };
};
export const updateCv = (cvList) => {
  return {
    type: "UPDATE_CV",
    payload: cvList,
  };
};
export const updateUser = (userData) => {
  return {
    type: "UPDATE_USER",
    payload: userData,
  };
};

// export const login = (sessionId, user) => {
//   return (dispatch) => {
//     dispatch(updateSession(sessionId));
//     dispatch(updateUser(user));
//   };
// };

export const logout = () => {
  return {
    type: "LOGOUT",
  };
};

export const login = (isAuth) => {
  return {
    type: "LOGIN",
    payload: isAuth,
  };
};

export const updateUserId = (user_id) => {
  return {
    type: "UPDATE_USER_ID",
    payload: user_id,
  };
};
