import { Button } from "@mui/material";
import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DisplayGenText from "./DisplayGenText";
import { useNavigate } from "react-router-dom";
import SelfassesTest from "./SelfassesTest";
const DisplayGeneratedPitch = ({
  emailpitch_ai_response,
  verbalpitch_ai_response,
  cv_ai_liner,
  interview_ai_intro,
  strengths_ai_response,
  weakness_ai_response,
  ND_Characteristics,
  UserRes_ND_Characteristics,
  setUserRes_ND_Characteristics,
  Executive_Functioning,
  UserRes_Executive_Functioning,
  setUserRes_Executive_Functioning,
  Interpersonal_Skills,
  UserRes_Interpersonal_Skills,
  setUserRes_Interpersonal_Skills,
}) => {
  const [reGenerate, setReGenerate] = useState(false);
  const navigate = useNavigate();
  const handleRegenerate = () => {
    setReGenerate(true);
  };
  return (
    <div>
      {reGenerate ? (
        <SelfassesTest
          ND_Characteristics={ND_Characteristics}
          Executive_Functioning={Executive_Functioning}
          Interpersonal_Skills={Interpersonal_Skills}
          UserRes_ND_Characteristics={UserRes_ND_Characteristics}
          setUserRes_ND_Characteristics={setUserRes_ND_Characteristics}
          UserRes_Executive_Functioning={UserRes_Executive_Functioning}
          setUserRes_Executive_Functioning={setUserRes_Executive_Functioning}
          UserRes_Interpersonal_Skills={UserRes_Interpersonal_Skills}
          setUserRes_Interpersonal_Skills={setUserRes_Interpersonal_Skills}
        />
      ) : (
        <div
          className="fc ptchGeneration-main-divi"
          style={{ marginBottom: "0" }}
        >
          <span>
            <strong style={{ fontSize: "1.5rem" }}>JoBro Response:</strong>
          </span>

          <div>
            <DisplayGenText aiGenRes={cv_ai_liner} button_id="button_91" />
            <DisplayGenText
              aiGenRes={interview_ai_intro}
              button_id="button_92"
            />
            <DisplayGenText
              aiGenRes={strengths_ai_response}
              button_id="button_93"
            />
            <DisplayGenText
              aiGenRes={weakness_ai_response}
              button_id="button_94"
            />
            <DisplayGenText
              aiGenRes={emailpitch_ai_response}
              button_id="button_95"
            />
            <DisplayGenText
              aiGenRes={verbalpitch_ai_response}
              button_id="button_96"
            />

            {/* <div className="fr res-button-div">
              {" "}
              <Button
                variant="outlined"
                onClick={() => {
                  navigator.clipboard.writeText();
                }}
              >
                <ContentCopyIcon />
              </Button>
              <Button variant="outlined">
                <FileDownloadIcon />
              </Button>
            </div> */}
          </div>

          <Button
            sx={{
              borderColor: "#012970",
              color: "#012970",
              width: "fit-content",
              margin: "2% 0",
              alignSelf: "end",
            }}
            variant="outlined"
            onClick={handleRegenerate}
          >
            REGENERATE
          </Button>
          <div
            style={{
              fontFamily: "Cutive Mono, monospace",
              textAlign: "center",
              color: "grey",
            }}
          >
            Disclaimer Remember that while AI can assist in various tasks, its
            performance is contingent on the quality of the data it interacts
            with. Taking steps to ensure data accuracy is essential for
            obtaining reliable and meaningful results. Thank you for your
            understanding and commitment to using AI responsibly. Remember that
            while AI can assist in various tasks, its performance is contingent
            on the quality of the data it interacts with. Taking steps to ensure
            data accuracy is essential for obtaining reliable and meaningful
            results. Thank you for your understanding and commitment to using AI
            responsibly.
          </div>
        </div>
      )}
    </div>
  );
};

export default DisplayGeneratedPitch;
