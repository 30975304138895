import { Button, Divider, TextField } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import COResult from "./COResult";

const CareerOptions = ({ baseUrl }) => {
  const [generate, setGenerate] = useState(false);
  const [fieldOfInterest, setFieldOfInterest] = useState("");
  const [yoe, setYoe] = useState("");

  const handleSubmit = () => {
    // navigate("/aiGeneratedTypicalQuestion");
    setGenerate(1);
  };
  return (
    <div className="fc page-container">
      <div className="fc main-header-div">
        <span className="page-heading"> Career Options</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> / career options
        </span>
      </div>

      {generate ? (
        <COResult
          baseUrl={baseUrl}
          yoe={yoe}
          fieldOfInterest={fieldOfInterest}
        />
      ) : (
        <div className="fc ic-input">
          <TextField
            required
            fullWidth
            id="outlined-required"
            label="Field Of Interest"
            value={fieldOfInterest}
            onChange={(e) => {
              setFieldOfInterest(e.target.value);
            }}
          />
          <TextField
            required
            fullWidth
            id="outlined-required"
            type="number"
            label="Relevent Years of Experience"
            value={yoe}
            onChange={(e) => {
              setYoe(e.target.value);
            }}
          />

          <Button
            variant="contained"
            sx={{
              width: "fit-content",
              padding: "1%",
              alignSelf: "center",
              backgroundColor: "#012970",
            }}
            onClick={handleSubmit}
          >
            {" "}
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export default CareerOptions;
