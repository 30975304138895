import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import { Register } from "./components/register/Register";
import Layout from "./components/Layout";
import Home from "./components/Home";
import UserProfile from "./components/UserProfile/UserProfile";
import Guides from "./components/Guides/Guides";
import GuideResponse from "./components/Guides/GuideResponse";
import CvBuilder from "./components/CVBuilder/CvBuilder";
import GeneratedCV from "./components/CVBuilder/GeneratedCV";

import CareerOptions from "./components/CareerOptions/CareerOptions";
import InterviewCoach from "./components/InterviewCoach/InterviewCoach";
import IcInterviewQuestions from "./components/InterviewCoach/IcInterviewQuestions";
import IcAiInterview from "./components/InterviewCoach/AIinterview/IcAiInterview.jsx";
import IcOptimizingResume from "./components/InterviewCoach/IcOptimizingResume";
import ICGeneratedQuestions from "./components/InterviewCoach/ICGeneratedQuestions";
import { Provider } from "react-redux";
import store from "./store/store";
import TermsAndConditions from "./components/register/Terms&conditions";
import CvGuide from "./components/Guides/CvGuide";
import CvList from "./components/CVBuilder/CvList";
import SingleCV from "./components/CVBuilder/SingleCV";
import EditCv from "./components/CVBuilder/CVedit";
import AddJD from "./components/CVBuilder/AddJD";
import IcAIInterviewPage from "./components/InterviewCoach/AIinterview/IcAIInterviewPage.jsx";
import IcAIResponsereport from "./components/InterviewCoach/AIinterview/IcAIResponsereport.jsx";
import KnowledgeBase from "./components/Knowledge Base/KnowledgeBase.jsx";
import CourseSuggestion from "./components/Knowledge Base/CourseSuggestion.jsx";
import CSResult from "./components/Knowledge Base/CSResult.jsx";
import QuesForInterviewer from "./components/Knowledge Base/QuesForInterviewer.jsx";
import Help from "./components/Help/Help.jsx";

import NeedHelpDisclosing from "./components/NeedHelpDisclosing.jsx";

import ReasonableAccomodation from "./components/Happily Unmasked/ReasonableAcc/ReasonableAccomodation.jsx";
import SelfAssessmentModules from "./components/Happily Unmasked/SelfAssesModles/SelfAssessmentModules.jsx";
import ReasonableAccomodationPitchGenerate from "./components/Happily Unmasked/ReasonableAcc/ReasonableAccomodationPitchGenerate.jsx";
import Masked from "./components/Happily Unmasked/Masked.jsx";
import DisplayGeneratedPitch from "./components/Happily Unmasked/SelfAssesModles/DisplayGeneratedPitch.jsx";
import GenerateReport from "./components/Happily Unmasked/SelfAssesModles/GenerateReport.jsx";

const baseUrl = "https://jobro.orchvate.com";
function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout baseUrl={baseUrl} />}>
              <Route index element={<Home />} />
              <Route
                path="userprofile"
                element={<UserProfile baseUrl={baseUrl} />}
              />
              <Route path="cvList" element={<CvList />} />
              <Route path="cv/:cv_id" element={<SingleCV />} />
              <Route
                path="cvbuilder"
                element={<CvBuilder baseUrl={baseUrl} />}
              />
              <Route path="/addJD" element={<AddJD />} />

              <Route path="generatedCv" element={<GeneratedCV />} />
              <Route path="guides" element={<Guides baseUrl={baseUrl} />} />
              <Route
                path="EditCv/:cv_id"
                element={<EditCv baseUrl={baseUrl} />}
              />
              <Route
                path="guides/:prompt_id"
                element={<GuideResponse baseUrl={baseUrl} />}
              />
              <Route path="/CV-Guide" element={<CvGuide />} />
              <Route
                path="careerOptions"
                element={<CareerOptions baseUrl={baseUrl} />}
              />
              <Route path="interviewCoach" element={<InterviewCoach />} />
              <Route
                path="interviewQuestions"
                element={<IcInterviewQuestions baseUrl={baseUrl} />}
              />
              <Route
                path="aiInterview"
                element={<IcAiInterview baseUrl={baseUrl} />}
              />
              <Route
                path="aiInterviewPage"
                element={<IcAIInterviewPage baseUrl={baseUrl} />}
              />
              <Route
                path="aiInterviewReport"
                element={<IcAIResponsereport baseUrl={baseUrl} />}
              />
              <Route
                path="optimizingResponse"
                element={<IcOptimizingResume baseUrl={baseUrl} />}
              />
              <Route
                path="aiGeneratedTypicalQuestion"
                element={<ICGeneratedQuestions />}
              />
              <Route path="CourseSuggestion" element={<CourseSuggestion />} />
              <Route path="knowledgeBase" element={<KnowledgeBase />} />
              <Route path="help" element={<Help />} />

              <Route path="JoBroResponse" element={<CSResult />} />
              <Route
                path="QuestionForInterviewer"
                element={<QuesForInterviewer />}
              />
              <Route path="happilyunmasked" element={<Masked />} />
              <Route
                path="NeedHelpDisclosing"
                element={<NeedHelpDisclosing />}
              />
              <Route
                path="ReasonableAccomodation"
                element={<ReasonableAccomodation />}
              />
              <Route
                path="SelfAssessmentModules"
                element={<SelfAssessmentModules />}
              />
              <Route
                path="ReasonableAccomodationPitchGenerate"
                element={<ReasonableAccomodationPitchGenerate />}
              />
              <Route
                path="DisplayGeneratedPitch"
                element={<DisplayGeneratedPitch />}
              />

              <Route path="GenerateReport" element={<GenerateReport />} />
            </Route>
            <Route
              path="/login"
              element={<Login baseUrl={baseUrl} flag={true} />}
            />
            <Route path="/register" element={<Register baseUrl={baseUrl} />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
