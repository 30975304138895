import React from "react";
import { useSelector } from "react-redux";

const UserInfoDisplay = ({ profileMap }) => {
  const userData = useSelector((state) => {
    return state.user.userData;
  });
  return (
    <div
      className="fc"
      style={{
        gap: "0.7rem",
      }}
    >
      {" "}
      <span
        style={{
          color: "#012970",
          fontSize: "1.8rem",
          fontWeight: "bold",
        }}
      >
        About
      </span>
      <p style={{ fontSize: "1.2rem" }}>
        <i>{userData.about}</i>
      </p>
      <span
        style={{
          color: "#012970",
          fontSize: "1.8rem",
          fontWeight: "bold",
        }}
      >
        Profile Details
      </span>
      <div>
        {Object.entries(userData).map(([key, value]) =>
          key === "img" ? (
            <div className="userpage-ep-details" key={key}>
              <span className="userpage-ep-key fc">Profile Image</span>
              <div style={{ marginLeft: "-5%" }}>
                <img src={value} alt="user" style={{ width: "150px" }} />
              </div>
            </div>
          ) : (
            key !== "user_id" &&
            key !== "about" &&
            key !== "linkedIn" &&
            key !== "twitter" &&
            key !== "instagram" &&
            key !== "facebook" && (
              <div className="userpage-ep-details">
                <span className="userpage-ep-key fc"> {profileMap[key]}</span>
                <div id="userpage-ep-value" className="fc">
                  {userData[key]}
                </div>
              </div>
            )
          )
        )}
      </div>
    </div>
  );
};

export default UserInfoDisplay;
