import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IcAiInterviewBlocks from "./IcAiInterviewBlocks";
// const ques = [
//   {
//     question:
//       "What is the difference between '==' and '===' operators in JavaScript?",
//     userResponse:
//       "The '==' operator checks only for equality in value whereas '===' also checks for equality in data type.",
//     joBroResponse:
//       "The '==' operator performs type coercion, while '===' does not. It's recommended to use '===' for strict equality checks.",
//     feedback:
//       "Great job on explaining the difference! Just remember to mention that '==' performs type coercion, which can lead to unexpected results in some cases.",
//   },
//   {
//     question: "What is closure in JavaScript?",
//     userResponse:
//       "Closure is when a function is able to remember and access its lexical scope even when that function is executing outside its lexical scope.",
//     joBroResponse:
//       "Closures allow JavaScript functions to access variables from an outer function even after that function has finished executing.",
//     feedback:
//       "You've got the concept right! Just ensure clarity by mentioning that closures 'close over' their lexical scope, retaining access to variables even after the outer function has finished executing.",
//   },
//   {
//     question: "Explain the event delegation in JavaScript.",
//     userResponse:
//       "Event delegation is a technique in which you add a single event listener to a parent element, rather than adding multiple event listeners to individual child elements. This allows you to handle events for multiple elements with a single event listener.",
//     joBroResponse:
//       "Event delegation is an efficient way to handle events for multiple elements by leveraging event bubbling. It improves performance and reduces memory consumption.",
//     feedback:
//       "Well explained! Just remember to emphasize that event delegation leverages the bubbling phase of the event propagation, which improves performance and reduces memory overhead.",
//   },
//   {
//     question: "What is the 'this' keyword in JavaScript?",
//     userResponse:
//       "The 'this' keyword refers to the context in which a function is called. It can dynamically change depending on how the function is invoked.",
//     joBroResponse:
//       "'this' refers to the object that is currently executing the function. It allows for dynamic context binding.",
//     feedback:
//       "You've grasped the concept! Just be sure to clarify that 'this' refers to the execution context, which may vary based on how the function is invoked.",
//   },
//   {
//     question: "What are the different ways to create objects in JavaScript?",
//     userResponse:
//       "There are several ways to create objects in JavaScript, such as object literals, constructor functions, and the 'Object.create()' method.",
//     joBroResponse:
//       "Object literals, constructor functions, 'Object.create()', and ES6 classes are common ways to create objects in JavaScript.",
//     feedback:
//       "Good job on listing the different methods! Just remember to mention ES6 classes as another way to create objects, which provide syntactic sugar over constructor functions.",
//   },
// ];

const IcAIInterviewPage = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const location = useLocation();
  const { interviewId, ques } = location.state || {};
  let questionsArray = ques.split("\n");
  // console.log("quesss", ques, "l", questionsArray);

  const handleNextQuestion = () => {
    if (currentQuestionIndex < ques.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    window.scrollTo(0, 0);
  };

  const handlePrevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };
  // console.log("hi", interviewId);

  return (
    <div className="page-container">
      <div className="fc main-header-div ">
        <span className="page-heading">AI Interview</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> /{" "}
          <Link to="/interviewCoach">interview coach</Link> /{" "}
          <Link to="/aiInterview">ai interview</Link>
        </span>
      </div>
      <div
        className="fc"
        style={{
          margin: "10% auto",
          marginBottom: "3%",
          width: "70%",
          padding: "3%",
          alignItems: "center",
          justifyItems: "center",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }}
      >
        {questionsArray.map((question, index) => {
          if (index === currentQuestionIndex) {
            return (
              <IcAiInterviewBlocks
                key={index}
                currentQuestionIndex={index}
                setCurrentQuestionIndex={setCurrentQuestionIndex}
                question={question}
                ques={questionsArray}
                handleNextQuestion={handleNextQuestion}
                interviewId={interviewId}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default IcAIInterviewPage;
