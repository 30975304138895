import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../assets/css/Cv.css";
import axios from "axios";
import { useSelector } from "react-redux";
const IcAiInterview = () => {
  const [interviewType, setInterviewType] = useState("");
  const [interviewStage, setInterviewStage] = useState("");
  const [interviewer, setInterviewer] = useState("");
  const [interviewRole, setInterviewRole] = useState("");
  const [language, setLang] = useState("");
  const [JD, setJD] = useState("");
  const [saving, setSaving] = useState(false);
  // const DummyUrl = "http://localhost:8000/api/";
  const DummyUrl = "https://jobrobackendcv.azurewebsites.net/api/";
  const baseUrl = "https://jobro.orchvate.com/api/v1/";

  const user_id = useSelector((state) => {
    return state.user.user_id;
  });
  const sessionId = useSelector((state) => {
    return state.user.sessionId;
  });
  const navigate = useNavigate();
  const handleSaving = async () => {
    setSaving(true);
    try {
      const body = {
        userId: user_id,
        interviewType: interviewType,
        interviewStage: interviewStage,
        interviewer: interviewer,
        interviewLanguage: language,
        jobDescription: JD,
        interviewRole: interviewRole,
      };
      // console.log("body", body);
      const res = await axios.post(`${DummyUrl}createinterview`, body);
      // // console.log("Hi", res);
      const interviewId = res.data._id;
      // console.log("int", interviewId);
      const data = {
        button_id: "button_86",
        user_id: user_id,
        session_id: sessionId,
      };
      const response = await axios.post(`${baseUrl}fetch_prompt`, data);
      // console.log("hi this is resss", response);
      const prompt = response.data.prompt;
      let newPrompt;
      // console.log("ppp1", prompt);

      newPrompt = prompt.replace(/\[\[Interview_Type\]\]/g, interviewType);
      newPrompt = newPrompt.replace(/\[\[Role\]\]/g, interviewRole);
      newPrompt = newPrompt.replace(/\[\[Job_Desc\]\]/g, JD);
      // console.log("pppp2", newPrompt);

      const ressss = await axios.post(`${baseUrl}get_ai_response`, {
        prompt_message: newPrompt,
      });
      const ques = ressss.data.ai_response;
      console.log(ques);
      navigate("/aiInterviewPage", { state: { interviewId, ques } });
    } catch (error) {
    } finally {
      setSaving(false);
    }
  };
  return (
    <div className="fc page-container">
      <div className="fc main-header-div ">
        <span className="page-heading">AI Interview</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> /{" "}
          <Link to="/interviewCoach">interview coach</Link> / ai interviews
        </span>
      </div>

      <div
        style={{
          margin: "2% auto",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
          width: "50%",
          padding: "3%",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }}
        className="fc"
      >
        <div className="ic-AI-Interview-items">
          <div className="ic-interview-heading">Role</div>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">
            Interview Type
          </InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={interviewRole}
              label=""
              onChange={(e) => setInterviewRole(e.target.value)}
            >
              <MenuItem value={"Front-end Developer"}>
                Front-End Developer
              </MenuItem>
              <MenuItem value={"Back-end Developer"}>
                Back-end Developer
              </MenuItem>
              <MenuItem value={"Business analyst"}>Business analyst</MenuItem>
              <MenuItem value={"Dev-ops Engineer"}>Dev-ops Engineer</MenuItem>
              <MenuItem value={"Human Resource Manager"}>
                Human Resource Manager
              </MenuItem>
              <MenuItem value={"Sales Representative"}>
                Sales Representative
              </MenuItem>
              <MenuItem value={"Product Manager"}>Product Manager</MenuItem>
              <MenuItem value={"Data Analyst"}>Data Analyst</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="ic-AI-Interview-items">
          <div className="ic-interview-heading">Interview Type</div>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">
            Interview Type
          </InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={interviewType}
              label=""
              onChange={(e) => setInterviewType(e.target.value)}
            >
              <MenuItem value={"Technical Interview"}>
                Technical Interview
              </MenuItem>
              <MenuItem value={"HR Interview"}>HR Interview</MenuItem>
              <MenuItem value={"Behavioral Interview"}>
                Behavioral interview
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="ic-AI-Interview-items">
          <div className="ic-interview-heading">Interview Stage</div>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">
              Inetrview Stage
            </InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={interviewStage}
              label=""
              onChange={(e) => setInterviewStage(e.target.value)}
            >
              <MenuItem value={"First Stage"}>First Stage</MenuItem>
              <MenuItem value={"Second Stage"}>Second Stage</MenuItem>
              <MenuItem value={"Third Stage"}>Third Stage</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="ic-AI-Interview-items">
          <div className="ic-interview-heading"> Interviewer</div>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">
              Inetrviewer
            </InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={interviewer}
              label=""
              onChange={(e) => setInterviewer(e.target.value)}
            >
              <MenuItem value={"HR"}>HR</MenuItem>
              <MenuItem value={"Hiring Manager"}>Hiring Manager</MenuItem>
              <MenuItem value={"Head of Domain"}>Head of Domain</MenuItem>
              <MenuItem value={"Co-Founder"}>Co-Founder</MenuItem>
              <MenuItem value={"Senior Employee"}>Senior Employee</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="ic-AI-Interview-items">
          <div className="ic-interview-heading"> Interview Language</div>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">
              Inetrview Language
            </InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={language}
              label=""
              onChange={(e) => setLang(e.target.value)}
            >
              <MenuItem value={"English"}>English</MenuItem>
              <MenuItem value={"Hindi"}>Hindi</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="ic-AI-Interview-items">
          <div className="ic-interview-heading">Job Description</div>
          <TextField
            onChange={(e) => {
              setJD(e.target.value);
            }}
            multiline
            rows={6}
            fullWidth
            label=""
          />
        </div>

        <Button
          onClick={handleSaving}
          style={{ width: "fit-content", backgroundColor: "#012970" }}
          variant="contained"
        >
          {saving ? (
            <CircularProgress color="inherit" />
          ) : (
            "Proceed with the Interview"
          )}
        </Button>
      </div>
    </div>
  );
};

export default IcAiInterview;
