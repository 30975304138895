import { useTheme } from "@emotion/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ICGeneratedQuestions from "./ICGeneratedQuestions";

const IcInterviewQuestions = ({ baseUrl }) => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    // navigate("/aiGeneratedTypicalQuestion");
    setGenerate(1);
  };
  const [typeOfInterview, setInterview] = useState("");
  const [role, setRole] = useState("");
  const [yoe, setYoe] = useState("");
  const [generate, setGenerate] = useState(false);
  const handleChange = (event) => {
    setInterview(event.target.value);
  };

  return (
    <div className="page-container">
      <div className="fc main-header-div">
        <span className="page-heading"> Interview Questions</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> /{" "}
          <Link to="/interviewCoach">interview coach</Link> / Typical Interview
          Questions
        </span>
      </div>
      {generate ? (
        <div>
          <div style={{ margin: "5% 20%" }}>
            {" "}
            <ICGeneratedQuestions
              typeOfInterview={typeOfInterview}
              role={role}
              yoe={yoe}
              baseUrl={baseUrl}
            />
          </div>

          <div
            style={{
              padding: " 0 3%",
              fontFamily: "Cutive Mono, monospace",
            }}
          >
            Disclaimer Remember that while AI can assist in various tasks, its
            performance is contingent on the quality of the data it interacts
            with. Taking steps to ensure data accuracy is essential for
            obtaining reliable and meaningful results. Thank you for your
            understanding and commitment to using AI responsibly. Remember that
            while AI can assist in various tasks, its performance is contingent
            on the quality of the data it interacts with. Taking steps to ensure
            data accuracy is essential for obtaining reliable and meaningful
            results. Thank you for your understanding and commitment to using AI
            responsibly.
          </div>
        </div>
      ) : (
        <div className="fc ic-input">
          <TextField
            required
            fullWidth
            id="outlined-required"
            label="Role"
            value={role}
            onChange={(e) => {
              setRole(e.target.value);
            }}
          />
          <TextField
            required
            fullWidth
            id="outlined-required"
            type="number"
            label="Relevent Years of Experience"
            value={yoe}
            onChange={(e) => {
              setYoe(e.target.value);
            }}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Type of Interview
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={typeOfInterview}
              label="Type of Interview"
              onChange={handleChange}
            >
              <MenuItem value={1}>Technical Interview</MenuItem>
              <MenuItem value={2}>HR Interview</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            sx={{
              width: "fit-content",
              padding: "1%",
              alignSelf: "center",
            }}
            onClick={handleSubmit}
          >
            {" "}
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export default IcInterviewQuestions;
