import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// import dayjs from "dayjs";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

const EducationPage = ({
  loading,

  educations,
  setEducations,
  handleeducationInputChange,
  deleteEducation,
  addEducation,
}) => {
  // useEffect(() => {
  //   onChange(educations);
  // }, [educations, onChange]);
  dayjs.extend(utc);

  return (
    <div className="fc" style={{ margin: "5%" }}>
      <h1>Education</h1>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className="fc" style={{ gap: "2.5rem " }}>
          {" "}
          {educations.map((education) => (
            <div key={education.id} className="fr">
              <div className="fc" style={{ width: "100%" }}>
                <div className="fr" style={{ justifyContent: "space-between" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      renderInput={(props) => <TextField {...props} />}
                      disableFuture
                      value={
                        education && education.startDate
                          ? dayjs(education.startDate)
                          : null
                      }
                      onChange={(e) =>
                        handleeducationInputChange(education.id, "startDate", e)
                      }
                      label="Start Date"
                      sx={{ margin: "2% 0" }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      renderInput={(props) => <TextField {...props} />}
                      disableFuture
                      minDate={education.startDate}
                      // value={dayjs(education.endDate)}
                      value={
                        education && education.endDate
                          ? dayjs(education.endDate)
                          : null
                      }
                      onChange={(e) =>
                        handleeducationInputChange(education.id, "endDate", e)
                      }
                      sx={{ margin: "2% 0" }}
                      label="End Date"
                      disabled={education.currentlyEnrolled}
                    />
                  </LocalizationProvider>
                  {/* <CurrentlyEdu
                    item={education}
                    handleeducationInputChange={handleeducationInputChange}
                  /> */}
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={education.currentlyEnrolled}
                    value={education.currentlyEnrolled}
                    onClick={() => {
                      handleeducationInputChange(
                        education.id,
                        "currentlyEnrolled",
                        !education.currentlyEnrolled
                      );
                    }}
                    label={"Currently Enrolled"}
                  />
                </div>
                <TextField
                  fullWidth
                  sx={{ margin: "2% 0" }}
                  value={education.institution}
                  onChange={(e) =>
                    handleeducationInputChange(
                      education.id,
                      "institution",
                      e.target.value
                    )
                  }
                  required
                  id="outlined-basic"
                  label="Institution"
                  variant="outlined"
                />

                <TextField
                  fullWidth
                  sx={{ margin: "2% 0", marginTop: "0" }}
                  value={education.degree}
                  onChange={(e) =>
                    handleeducationInputChange(
                      education.id,
                      "degree",
                      e.target.value
                    )
                  }
                  id="outlined-basic"
                  required
                  label="Degree"
                  variant="outlined"
                />
              </div>

              <Button
                style={{
                  color: "#012970",
                  borderColor: "#012970",
                  alignSelf: "center",
                  width: "fit-content",
                  height: "fit-content",
                }}
                onClick={() => deleteEducation(education.id)}
              >
                <DeleteIcon />
              </Button>
            </div>
          ))}
          <Button
            style={{
              width: "fit-content",
              margin: "2% 0",
              backgroundColor: "#012970",
            }}
            variant="contained"
            onClick={addEducation}
          >
            + Add Education
          </Button>
        </div>
      )}
    </div>
  );
};

export default EducationPage;
