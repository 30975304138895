import { Box, CircularProgress, Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const guidesText = [
  "How to set up LinkedIn Account",
  "How to add work experience to LinkedIn Account",
  "How do I upload my CV to my LinkedIn Profile?",
  "What should be added in the About Me section in LinkedIn?",
  "Should the LinkedIn About Me Section be in the first person?",
  "What should we add in LinkedIn Work Experience section– only Organization, Role, and Period or do we give a brief description?",
  "Where do we mention Projects on LinkedIn?",
  "Where do we mention Awards on LinkedIn?",
  "Where do we include Certifications on LinkedIn?",
  "How do we build our network on LinkedIn? Mention various tips such as - Joining Specific Groups etc.Steps to add my work experience Linkedin",
  "What’s the process to get recommendations and Endorsements on LinkedIn from our network?",
  "What should an ideal headline on LinkedIn look like?",
  "How do I post an article on LinkedIn?",
];

const GuideResponse = ({ baseUrl }) => {
  const { prompt_id } = useParams();
  const user_id = useSelector((state) => {
    return state.user.user_id;
  });
  const sessionId = useSelector((state) => {
    return state.user.sessionId;
  });
  const [resultPrompt, setResultPrompt] = useState("");
  const [resultAiResponse, setresultAiResponse] = useState("");

  useEffect(() => {
    const callAPI = async () => {
      try {
        const data = {
          button_id: `${prompt_id}`,
          user_id: user_id,
          session_id: sessionId,
        };
        const response = await axios.post(
          `${baseUrl}/api/v1/simple_prompt_button`,
          data
        );
        const result = await response.data;

        setresultAiResponse(result.ai_response);
        setResultPrompt(result.prompt);
        return;
      } catch (error) {}
    };

    callAPI();
  }, []);

  // const htmlDecode = (input) => {
  //   var e = document.createElement("div");
  //   e.innerHTML = input;
  //   return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  // };
  return (
    <div className="fc  page-container">
      <div className="fc main-header-div ">
        <span className="page-heading"> JoBro Response</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> / <Link to="/guides">guides</Link> / jobro
          response
        </span>
      </div>
      <div
        className="fc"
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          height: "fit-content",

          padding: "3%",
          margin: "5% 20%",

          gap: "1rem",
        }}
      >
        <span>
          <strong style={{ fontSize: "1.5rem" }}>Prompt:</strong>
        </span>
        <div>
          {resultPrompt ? (
            <div>{resultPrompt}</div>
          ) : (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          )}
        </div>
        <span>
          <strong style={{ fontSize: "1.5rem" }}>JoBro Response:</strong>
        </span>
        <div>
          {resultAiResponse ? (
            <div>
              <div style={{ whiteSpace: "pre-line" }}>{resultAiResponse}</div>
            </div>
          ) : (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          )}
        </div>
      </div>
      <div
        style={{
          padding: " 0 3%",
          fontFamily: "Cutive Mono, monospace",
        }}
      >
        Disclaimer Remember that while AI can assist in various tasks, its
        performance is contingent on the quality of the data it interacts with.
        Taking steps to ensure data accuracy is essential for obtaining reliable
        and meaningful results. Thank you for your understanding and commitment
        to using AI responsibly. Remember that while AI can assist in various
        tasks, its performance is contingent on the quality of the data it
        interacts with. Taking steps to ensure data accuracy is essential for
        obtaining reliable and meaningful results. Thank you for your
        understanding and commitment to using AI responsibly.
      </div>
    </div>
  );
};

export default GuideResponse;
