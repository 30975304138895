import { Button } from "@mui/material";
import React, { useState } from "react";
import NdcharacteristicBlock from "./NdcharacteristicBlock";
import ExecutiveFunctioning from "./ExecutiveFunctioning";
import InterpersonalskillsBlock from "./InterpersonalskillsBlock";

const SelfassesTest = ({
  ND_Characteristics,
  UserRes_ND_Characteristics,
  setUserRes_ND_Characteristics,
  Executive_Functioning,
  UserRes_Executive_Functioning,
  setUserRes_Executive_Functioning,
  Interpersonal_Skills,
  UserRes_Interpersonal_Skills,
  setUserRes_Interpersonal_Skills,
}) => {
  const [selectedDiv, setSelectedDiv] = useState("div1");

  const handleSelectionChange = (selectedSection) => {
    setSelectedDiv(selectedSection);
    setCurrIndex1(0);
    setCurrIndex2(0);
    setCurrIndex3(0); // Reset current index when changing section
  };

  const [currIndex1, setCurrIndex1] = useState(0);
  const [currIndex2, setCurrIndex2] = useState(0);
  const [currIndex3, setCurrIndex3] = useState(0);
  return (
    <div>
      <div>
        <Button
          onClick={() => handleSelectionChange("div1")}
          variant={selectedDiv === "div1" ? "outlined" : "text"}
        >
          ND Characteristics
        </Button>
        <Button
          onClick={() => handleSelectionChange("div2")}
          variant={selectedDiv === "div2" ? "outlined" : "text"}
        >
          Executive Functioning
        </Button>
        <Button
          onClick={() => handleSelectionChange("div3")}
          variant={selectedDiv === "div3" ? "outlined" : "text"}
        >
          Interpersonal Skills
        </Button>
      </div>
      <div>
        {selectedDiv === "div1" &&
          ND_Characteristics &&
          ND_Characteristics.map((item, index) => {
            if (currIndex1 == index)
              return (
                <NdcharacteristicBlock
                  key={index}
                  item={item}
                  ND_Characteristics={ND_Characteristics}
                  isLast={index === ND_Characteristics.length - 1}
                  currIndex={currIndex1}
                  setCurrIndex={setCurrIndex1}
                  UserRes_ND_Characteristics={UserRes_ND_Characteristics}
                  setUserRes_ND_Characteristics={setUserRes_ND_Characteristics}
                  Executive_Functioning={Executive_Functioning}
                  Interpersonal_Skills={Interpersonal_Skills}
                  handleSelectionChange={handleSelectionChange}
                />
              );
          })}
        {selectedDiv === "div2" &&
          ExecutiveFunctioning &&
          Executive_Functioning.map((item, index) => {
            if (currIndex2 === index)
              return (
                <ExecutiveFunctioning
                  key={index}
                  item={item}
                  isLast={index === Executive_Functioning.length - 1}
                  currIndex={currIndex2}
                  setCurrIndex={setCurrIndex2}
                  UserRes_Executive_Functioning={UserRes_Executive_Functioning}
                  setUserRes_Executive_Functioning={
                    setUserRes_Executive_Functioning
                  }
                  Executive_Functioning={Executive_Functioning}
                  Interpersonal_Skills={Interpersonal_Skills}
                  handleSelectionChange={handleSelectionChange}
                />
              );
          })}
        {selectedDiv === "div3" &&
          Interpersonal_Skills.map((item, index) => {
            if (currIndex3 == index)
              return (
                <InterpersonalskillsBlock
                  key={index}
                  item={item}
                  isLast={index === Interpersonal_Skills.length - 1}
                  currIndex={currIndex3}
                  setCurrIndex={setCurrIndex3}
                  UserRes_Interpersonal_Skills={UserRes_Interpersonal_Skills}
                  setUserRes_Interpersonal_Skills={
                    setUserRes_Interpersonal_Skills
                  }
                  Interpersonal_Skills={Interpersonal_Skills}
                  handleSelectionChange={handleSelectionChange}
                  UserRes_ND_Characteristics={UserRes_ND_Characteristics}
                  UserRes_Executive_Functioning={UserRes_Executive_Functioning}
                />
              );
          })}
      </div>
    </div>
  );
};

export default SelfassesTest;
