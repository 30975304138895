import React, { useState } from "react";
import img from "../../assets/img/JoBro-colorful.png";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import zxcvbn from "zxcvbn";

import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export const Register = ({ user, setUser, baseUrl }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [TAndCAgree, setTAndAgree] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isMasked, setIsMasked] = useState(false);

  const navigate = useNavigate();
  const testedResult = zxcvbn(password);
  const validateForm = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (
      !firstName ||
      firstName.trim() === "" ||
      !lastName ||
      lastName.trim() === "" ||
      !email ||
      role.value === ""
    ) {
      toast.error("Please fill all the required fields!");
      return false;
    }
    if (!emailPattern.test(email.trim())) {
      toast.error("Plese fill the email correctly!");
      return false;
    }
    if (password.length < 8) {
      toast.error("Password must be at least of 8 characters!");
      return false;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
      return false;
    }

    if (!TAndCAgree) {
      toast("Please agree to our Terms and Conditions");
      return false;
    }
    return true;
  };
  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (
        validateForm(
          firstName,
          lastName,
          email,
          role,
          password,
          confirmPassword,
          TAndCAgree,
          isMasked
        )
      ) {
        const registrationData = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          role_id: role,
          password: password,
        };
        const response = await axios.post(
          `${baseUrl}/api/v1/register`,
          registrationData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        if (response.data.message === "User registered successfully!") {
          toast.success("Successfully Registered");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }

        return;
      }
    } catch (error) {
      console.log(error);
      toast.error("There has been some error");
    } finally {
      setLoading(false);
    }

    return;
  };
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };
  const createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return "Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };
  return (
    <>
      <Toaster />
      <div className="container-login">
        <img src={img} width="20%" alt="" />

        <TextField
          sx={{ width: "80%" }}
          id="outlined-basic"
          label="First Name"
          variant="outlined"
          size="small"
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <TextField
          sx={{ width: "80%" }}
          id="outlined-basic"
          label="Last Name"
          variant="outlined"
          size="small"
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <TextField
          sx={{ width: "80%" }}
          id="outlined-basic"
          label="Email"
          size="small"
          variant="outlined"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <FormControl sx={{ width: "80%" }} size="small" required>
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            label="Role"
            onChange={handleRoleChange}
          >
            <MenuItem value={"1"}>Ten</MenuItem>
            <MenuItem value={"2"}>Twenty</MenuItem>
            <MenuItem value={"3"}>Thirty</MenuItem>
          </Select>
        </FormControl>
        <TextField
          sx={{ width: "80%" }}
          id="outlined-password-input"
          label="Password"
          type="password"
          size="small"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <div className="password-strength-meter">
          <progress
            className={`password-strength-meter-progress strength-${createPasswordLabel(
              testedResult
            )}`}
            value={testedResult.score}
            max="4"
          />
          <br />
          <label className="password-strength-meter-label">
            {password && (
              <>
                <strong>Password strength:</strong>{" "}
                {createPasswordLabel(testedResult)}
              </>
            )}
          </label>
        </div>
        <TextField
          sx={{ width: "80%" }}
          id="outlined-password-input"
          label="Confirm Password"
          size="small"
          type="password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          autoComplete="current-password"
          required
        />
        <div
          style={
            {
              // justifySelf: "center",
              // alignItems: "center",
              // textAlign: "center",
              // margin: "auto",
              // width: "fit-content",
            }
          }
        >
          <FormControlLabel
            sx={{
              margin: "auto",
              display: "flex",
              // alignContent: "left"
            }}
            size="small"
            checked={isMasked}
            onChange={() => setIsMasked(!isMasked)}
            control={
              <Checkbox
                defaultChecked
                sx={
                  {
                    // margin: "auto",
                    // justifySelf: "center",
                    // alignSelf: "center",
                  }
                }
              />
            }
            label={
              <Typography variant="body2" component="span">
                Masked
              </Typography>
            }
          ></FormControlLabel>
          <FormControlLabel
            sx={{ margin: "auto" }}
            size="small"
            checked={TAndCAgree}
            onChange={() => setTAndAgree(!TAndCAgree)}
            control={
              <Checkbox
                defaultChecked
                sx={
                  {
                    // margin: "auto",
                    // justifySelf: "center",
                    // alignSelf: "center",
                  }
                }
              />
            }
            label={
              <Typography variant="body2" component="span">
                I agree to the{" "}
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
                .
              </Typography>
            }
          ></FormControlLabel>
        </div>
        <Button onClick={handleRegister} variant="contained">
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                padding: "1%",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            "Register"
          )}
        </Button>
      </div>
    </>
  );
};
