import { Divider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
const guidesText = {
  "How to set up LinkedIn Account": "01",
  "How to add work experience to LinkedIn Account": "04",
  "How do I upload my CV to my LinkedIn Profile?": "05",
  "What should be added in the About Me section in LinkedIn?": "06",
  "Should the LinkedIn About Me Section be in the first person?": "07",
  "What should we add in LinkedIn Work Experience section– only Organization, Role, and Period or do we give a brief description?":
    "08",
  "Where do we mention Projects on LinkedIn?": "09",
  "Where do we mention Awards on LinkedIn?": "10",
  "Where do we include Certifications on LinkedIn?": "11",
  "How do we build our network on LinkedIn? Mention various tips such as - Joining Specific Groups etc.":
    "12",
  "What’s the process to get recommendations and Endorsements on LinkedIn from our network?":
    "13",
  "What should an ideal headline on LinkedIn look like?": "14",
  "How do I post an article on LinkedIn?": "15",
};
const CvGuide = () => {
  return (
    <div className="fc page-container">
      <div className="fc page-div">
        <span className="page-heading">CV Guides</span>
        <span className="page-top-item2">
          <Link to="/">Home</Link> / CV-Guides
        </span>
      </div>
      <Divider className="page-divider" />

      <div style={{ gap: "4%" }} className=" fr">
        <section id="guides-section1">
          <div
            // orientation="vertical"
            // aria-label="vertical contained button group"
            // variant="contained"
            id="guides-sec1-div1"
          >
            <h2>Linkedin Guides</h2>
            <div style={{ gap: "15px" }} className="fc">
              {Object.keys(guidesText).map((text) => (
                <Link to={``} key={guidesText[text]}>
                  {console.log("hi", guidesText[text], text)}
                  <div
                    id="guides-sec1-div1-items"
                    className="fr"
                    fullWidth
                    key={guidesText[text]}
                  >
                    {text}
                  </div>{" "}
                </Link>
              ))}{" "}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CvGuide;
