import React, { useState } from "react";
import "../../assets/css/Cv.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import jsPDF from "jspdf";
import { Link } from "react-router-dom";
import html2canvas from "html2canvas";
import { Button } from "@mui/material";
import { Page, Text, View } from "@react-pdf/renderer";
const workExperiences = [
  {
    id: 1,
    startDate: "1/11/2023",
    endDate: "2/01/2024",
    employer: "Orchvate",
    role: "Developer",
    description:
      "This is a description of work role. Also managed to achieve this with these skills",
  },
  {
    id: 2,
    startDate: "1/11/2023",
    endDate: "2/01/2024",
    employer: "XYZ",
    role: "Developer",
    description: "This is a description of work role",
  },
];
const educations = [
  {
    id: 1,
    startDate: "1/11/2023",
    endDate: "2/01/2024",
    institution: "XYZS University",
    degree: "Master's of Technology",
  },
  {
    id: 2,
    startDate: "1/11/2023",
    endDate: "2/01/2024",
    institution: "XYZS University",
    degree: "Bachelor's of Technology",
  },
];
const certifications = [
  {
    title: "Certificate 1",
    link: "",
  },
  {
    title: "Certificate 2",
    link: "",
  },
];
const achievements = [
  "Achievement 1 this is a sample.",
  "Achievement 1 this is a sample.",
];
const data = {
  id: "1",

  img: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXw3NjA4Mjc3NHx8ZW58MHx8fHx8",
  fullName: "Kevin Anderson",
  dob: "12/08/2002",
  gender: "male",
  maxQualification: "aaa",
  yoe: "0",
  phone: "9865676785",
  email: "xyz@gmail.com",
  role: "Web designer",
  twitter: "",
  facebook: "",
  instagram: "",
  linkedIn: "",
  about:
    "Sunt est soluta temporibus accusantium neque nam maiores cumque temporibus. Tempora libero non est unde veniam est qui dolor. Ut sunt iure rerum quae quisquam autem eveniet perspiciatis odit. Fuga sequi sed ea saepe at unde.",
};
const skills = ["HTML", "CSS", "JavaScript", "React js", "Node js", "OOPs"];
// const profileMap = {
//   img: "Image",
//   fullName: "Full Name",
//   dob: "Date of Birth",
//   gender: "Gender",
//   maxQualification: "Max Qualification",
//   yoe: "Years of Experience",
//   phone: "Contact number",
//   role: "Role",
//   twitter: "Twitter",
//   instagram: "Instagram",
//   linkedIn: "LinkedIN",
//   about: "About",
// };
<script
  src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js"
  integrity="sha512-GsLlZN/3F2ErC5ifS5QtgpiJtWd43JWSuIgh7mbzZ8zBps+dvLusV+eNQATqgA/HdeKFVgA5v3S/cIrLF7QnIg=="
  crossorigin="anonymous"
  referrerpolicy="no-referrer"
></script>;

const GeneratedCV = () => {
  // useEffect(() => {
  //   const retrieveData = async () => {
  //     try {
  //       const data = {
  //         User_id: user_id,
  //         Cv_id: cv_id,
  //       };

  //       console.log("misc", data);
  //       const res = await axios.get(`${DummyUrl}/CvDetails`, data);
  //       setCVData(res.data.Cv);
  //       console.log(Cvdata);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   retrieveData();
  // }, []);
  const handleDownloadPDF = () => {
    // const input = document.getElementById("resume");
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, "PNG", 0, 0);
    //   pdf.save("downloaded-file.pdf");
    //   // ReactPDF.render(input, `${__dirname}/example.pdf`);
    // });
    // const element = document.getElementById('divToConvert');
    // const options = {
    //   filename: "my-document.pdf",
    //   header:
    //     '<div style="text-align:center;">Page <span class="page"></span> of <span class="total"></span></div>',
    //   footer:
    //     '<div style="text-align:center;">Generated by my app on ' +
    //     new Date().toLocaleDateString() +
    //     "</div>",
    // };
    // const input = document.getElementById("resume");
    // html2pdf().set(options).from(input).save();
    // const pdf = html2pdf(input);
    // var worker = html2pdf().from(input).save();
    // pdf.save("downloaded-file.pdf");
    const input = document.getElementById("resume");

    html2canvas(input, {
      useCORS: true, // If fetching external resources
      logging: false, // Adjust for debugging purposes
      scale: 2, // Adjust for higher resolution (optional)
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("portrait", "mm", "a4"); // Set PDF size and orientation
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("download.pdf");

        // const imgData = canvas.toDataURL("image/png");
        // const pdf = new jsPDF("portrait", "mm", "a4");
        // const imgWidth = pdf.internal.pageSize.getWidth();
        // const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

        // // Get all links in the HTML content
        // const links = input.getElementsByTagName("a");

        // // Add clickable links to the PDF
        // for (let i = 0; i < links.length; i++) {
        //   const link = links[i];
        //   const rect = link.getBoundingClientRect();
        //   const x = rect.left;
        //   const y = rect.top;
        //   const width = rect.width;
        //   const height = rect.height;

        //   // Add link to the PDF
        //   pdf.link(x, y, width, height, { url: link.href });
        // }

        // pdf.save("download.pdf");
      })
      .catch((error) => {
        console.error("Error converting to PDF:", error);
      });
  };
  // const [divContent, setDivContent] = useState('<div>Your content goes here</div>');
  return (
    <div className="page-container">
      <div className="fc ">
        <span className="page-heading"> Guides</span>
        <span className="page-top-item2">
          <Link to="/">Home</Link> / <Link to="/cvbuilder">CV Builder</Link> /
          CV
        </span>
      </div>
      <div
        style={{
          // border: "1px solid black",
          margin: "5% auto",
          maxWidth: "1500px",
          justifySelf: "center",
        }}
      >
        {" "}
        <Page size="A4">
          <View>
            <Text>
              <div id="resume" className="GeneratedCV">
                <span
                  style={{ fontSize: "2.5rem", fontWeight: "600" }}
                  className=""
                >
                  {data.fullName}
                </span>
                <div className="fr cv-top">
                  {" "}
                  <span>{data.phone}</span>|<span>{data.email}</span>
                </div>

                <div>
                  <a className="cv-links" href={data.twitter}>
                    <TwitterIcon />
                  </a>
                  <a className="cv-links" href={data.facebook}>
                    {" "}
                    <FacebookIcon />{" "}
                  </a>
                  <a className="cv-links" href={data.instagram}>
                    <InstagramIcon />
                  </a>
                  <a className="cv-links" href={data.linkedIn}>
                    <LinkedInIcon />
                  </a>
                </div>
                <p style={{ fontSize: "1.2rem" }}>{data.about}</p>
                <hr className="cv-hr" />
                <span className="cv-heading">Work Experience</span>
                <div className="fc cv-content" style={{ gap: "10px" }}>
                  {workExperiences.map((experience) => (
                    <div
                      style={{
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        fontSize: "1.4rem",
                      }}
                      className="fr "
                      key={experience.id}
                    >
                      <div>
                        <span style={{ fontWeight: "600" }}>
                          {experience.role},
                        </span>{" "}
                        <span>{experience.employer}</span>
                        <p style={{ marginTop: "5px" }}>
                          {experience.description}
                        </p>
                      </div>
                      <p>
                        {experience.startDate} - {experience.endDate}
                      </p>
                    </div>
                  ))}
                </div>
                <hr className="cv-hr" />
                <span className="cv-heading">Education</span>
                <div className="fc ">
                  {educations.map((education) => (
                    <div
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "1.4rem",
                      }}
                      className="fr"
                      key={education.id}
                    >
                      <div>
                        <span style={{ fontWeight: "600" }}>
                          {education.degree},
                        </span>{" "}
                        <span>{education.institution}</span>
                      </div>
                      <p>
                        {education.startDate} - {education.endDate}
                      </p>
                    </div>
                  ))}
                </div>
                <hr className="cv-hr" />
                <span className="cv-heading">Skills</span>
                <nobr />
                <p
                // style={{ marginTop: "-1%" }}
                >
                  {skills.map((item, index) => (
                    <span key={index}>
                      {item}
                      {index !== skills.length - 1 && ", "}{" "}
                    </span>
                  ))}
                </p>
                <hr className="cv-hr" />
                <span className="cv-heading">Certifications</span>
                <div
                  className="fc cv-content"
                  style={{
                    gap: "10px",
                  }}
                >
                  {certifications.map((item, index) => (
                    <span key={index}>
                      <a href={item.link}> {item.title}</a>
                    </span>
                  ))}
                </div>
                <hr className="cv-hr" />
                <span className="cv-heading">Achievements</span>
                <div className="fc cv-content" style={{ gap: "10px" }}>
                  {achievements.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </div>
              </div>
            </Text>
          </View>
          <View></View>
        </Page>
        <Button
          style={{
            float: "right",
            marginRight: "160px",
            marginTop: "3%",
            backgroundColor: "#012970",
          }}
          variant="contained"
          onClick={handleDownloadPDF}
        >
          Download PDF
        </Button>
      </div>
    </div>
  );
};

export default GeneratedCV;
