import React from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "../assets/css/header.css";
import logo from "../assets/img/logo.png";

const Header = ({ handleClick, isMenu, setIsMenu }) => {
  return (
    <>
      <div className="navbar">
        <div className="fr " id="nav-item1">
          <Link
            to="/ "
            style={{ width: "fit-content", gap: "10%" }}
            className="fr"
          >
            <img
              style={{ textAlign: "center" }}
              height={"30px"}
              width={"30px"}
              src={logo}
              alt=""
            />
            <span>
              <nobr style={{ textAlign: "center" }}>
                <strong>Jo Bro</strong>
              </nobr>{" "}
            </span>
          </Link>

          <span
            style={{ padding: "10px", textAlign: "center" }}
            onClick={handleClick}
          >
            <MenuIcon sx={{ cursor: "pointer" }} />
          </span>
        </div>
        {/* <div>
          <Search />
        </div> */}
        <div style={{ padding: "1%" }}>
          <Link to="/userprofile">
            <AccountCircleIcon />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
