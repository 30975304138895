import { Box, Button, CircularProgress, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
// import dayjs from "dayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
const Certification = ({
  loading,
  onChange,
  certifications,
  setCertifications,
  handleCertificateInputChange,
  handleAddCertification,
  handleRemoveCertification,
}) => {
  // useEffect(() => {
  //   onChange(certifications);
  // }, [certifications, onChange]);
  dayjs.extend(utc);

  return (
    <div className="fc" style={{ margin: "5%" }}>
      <h1>Certificates </h1>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div>
          {" "}
          {certifications.map((cert_item) => (
            <div
              className="fr"
              key={cert_item.id}
              style={{ margin: "1% 0", width: "100%" }}
            >
              <div className="fc" style={{ width: "100%" }}>
                {" "}
                <div className="fr" style={{ gap: "6%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      renderInput={(props) => <TextField {...props} />}
                      disableFuture
                      // defaultValue={dayjs(cert_item.startDate)}

                      value={
                        cert_item && cert_item.startDate
                          ? dayjs(cert_item.startDate)
                          : null
                      }
                      onChange={(e) =>
                        handleCertificateInputChange(
                          cert_item.id,
                          "startDate",
                          e
                        )
                      }
                      label="Start Date"
                      sx={{ margin: "2% 0" }}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      renderInput={(props) => <TextField {...props} />}
                      disableFuture
                      minDate={cert_item.startDate}
                      // defaultValue={dayjs(cert_item.endDate)}
                      value={
                        cert_item && cert_item.endDate
                          ? dayjs(cert_item.endDate)
                          : null
                      }
                      onChange={(e) =>
                        handleCertificateInputChange(cert_item.id, "endDate", e)
                      }
                      sx={{ margin: "2% 0" }}
                      label="End Date"
                    />
                  </LocalizationProvider>
                </div>
                <TextField
                  fullWidth
                  sx={{ margin: "2% 0" }}
                  name="title"
                  value={cert_item.title}
                  onChange={(e) =>
                    handleCertificateInputChange(
                      cert_item.id,
                      "title",
                      e.target.value
                    )
                  }
                  required
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  sx={{ margin: "2% 0" }}
                  name="link"
                  label="Link"
                  value={cert_item.link}
                  onChange={(e) =>
                    handleCertificateInputChange(
                      cert_item.id,
                      "link",
                      e.target.value
                    )
                  }
                  required
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>

              <Button
                style={{
                  color: "#012970",
                  borderColor: "#012970",
                  alignSelf: "center",
                  width: "fit-content",
                  height: "fit-content",
                }}
                onClick={() => handleRemoveCertification(cert_item.id)}
              >
                <DeleteIcon />
              </Button>
            </div>
          ))}
          <Button
            style={{
              width: "fit-content",
              margin: "2% 0",
              backgroundColor: "#012970",
            }}
            variant="contained"
            onClick={handleAddCertification}
          >
            + Add Certification
          </Button>
        </div>
      )}
    </div>
  );
};

export default Certification;
