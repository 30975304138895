import React, { useState } from "react";
import { TextField, Button, Box, CircularProgress } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
const IcAiInterviewBlocks = ({
  currentQuestionIndex,
  setCurrentQuestionIndex,
  question,
  ques,
  handleNextQuestion,
  interviewId,
}) => {
  const [evaluated, setEvaluated] = useState(false);
  const [userReponse, setUserResponse] = useState("");
  // const DummyUrl = "http://localhost:8000/api/";
  const DummyUrl = "https://jobrobackendcv.azurewebsites.net/api/";
  const [joBroreponse, setJoBroResponse] = useState("");
  const [feedback, setFeedback] = useState("");
  const navigate = useNavigate();
  const sessionId = useSelector((state) => {
    return state.user.sessionId;
  });
  const user_id = useSelector((state) => {
    return state.user.user_id;
  });
  const baseUrl = "https://jobro.orchvate.com/api/v1/";
  const [feedbackLoading, setfeedloading] = useState(false);
  const [JoBroResLoading, setJoBroResLoading] = useState(false);

  const handleEvaluation = async () => {
    setEvaluated(true);
    setJoBroResLoading(true);
    setfeedloading(true);
    try {
      //***Feedback***
      const data = {
        button_id: "button_84",
        user_id: user_id,
        session_id: sessionId,
      };
      const res1 = await axios.post(`${baseUrl}fetch_prompt`, data);
      const prompt = res1.data.prompt;
      let newPrompt = prompt;

      newPrompt = newPrompt.replace(
        /\[\[Interview_Question\]\]/g,
        ques[currentQuestionIndex]
      );
      newPrompt = newPrompt.replace(/\[\[User_Answer\]\]/g, userReponse);
      // console.log("ppppdd", newPrompt);
      const res2 = await axios.post(`${baseUrl}get_ai_response`, {
        prompt_message: newPrompt,
      });
      // console.log("feedd", res2);
      setFeedback(res2.data.ai_response);

      //***Sample Response***
      const data2 = {
        button_id: "button_85",
        user_id: user_id,
        session_id: sessionId,
      };
      // console.log("feed", response);
      const res3 = await axios.post(`${baseUrl}fetch_prompt`, data2);
      const prompt2 = res3.data.prompt;
      let newPrompt2 = prompt2;

      newPrompt2 = newPrompt2.replace(
        /\[\[Interview_Question\]\]/g,
        ques[currentQuestionIndex]
      );
      newPrompt2 = newPrompt2.replace(/\[\[User_Answer\]\]/g, userReponse);
      // console.log("ll", newPrompt2);
      const res4 = await axios.post(`${baseUrl}get_ai_response`, {
        prompt_message: newPrompt2,
      });
      // console.log(res4.data.ai_response);
      setJoBroResponse(res4.data.ai_response);
    } catch (error) {
    } finally {
      setJoBroResLoading(false);
      setfeedloading(false);
    }
  };
  const handleSavresponse = async () => {
    try {
      console.log("reqqqqq", {
        question: ques[currentQuestionIndex],
        userResponse: userReponse,
        jobroResponse: joBroreponse,
        feedback: feedback,
      });
      const res = await axios.put(`${DummyUrl}interviews/${interviewId}`, {
        question: ques[currentQuestionIndex],
        userResponse: userReponse,
        jobroResponse: joBroreponse,
        feedback: feedback,
      });
      console.log("hi ress", res);
      if (currentQuestionIndex < ques.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
      window.scrollTo(0, 0);
    } catch (error) {}
  };
  const handleLastQuestion = async () => {
    try {
      console.log("reqqqqq", {
        question: question,
        userResponse: userReponse,
        jobroResponse: joBroreponse,
        feedback: feedback,
      });
      const res = await axios.put(`${DummyUrl}interviews/${interviewId}`, {
        question: question,
        userResponse: userReponse,
        jobroResponse: joBroreponse,
        feedback: feedback,
      });

      // console.log("hi ressss", res);

      navigate("/aiInterviewReport", { state: { interviewId } });
    } catch (error) {}
  };
  return (
    <div>
      {" "}
      <div className="interview-div fc">
        <div
          className="fr"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <span
            style={{
              fontWeight: "bolder",
              fontSize: "1.5rem",

              width: "700px",
            }}
          >
            Question
          </span>{" "}
          {currentQuestionIndex === ques.length - 1 ? (
            <Button
              style={{
                width: "fit-content",
                alignSelf: "center",
                backgroundColor: "#012970",
                color: "white",
                justifySelf: "center",
                // margin: "1% 0",
                padding: "1% 3%",
                textWrap: "nowrap",
              }}
              variant="contained"
              onClick={handleLastQuestion}
            >
              Generate Report
            </Button>
          ) : (
            <Button
              style={{
                width: "fit-content", // color: "#012970",

                alignSelf: "center",
                backgroundColor: "#012970",
                color: "white",
                justifySelf: "center",
                margin: "1% 0",
              }}
              variant="contained"
              onClick={handleSavresponse}
              disabled={currentQuestionIndex === ques.length - 1}
            >
              {/* <NavigateNextIcon />{" "} */}
              NEXT{" "}
            </Button>
          )}
        </div>

        <span style={{ textAlign: "center" }}>{question}</span>
        <div
          className="fr"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <span style={{ fontWeight: "bolder", fontSize: "1.5rem" }}>
            Your Response
          </span>
          <Button
            style={{
              width: "fit-content",
              color: "white",
              backgroundColor: "#012970",
              alignSelf: "center",

              justifySelf: "center",
              margin: "1% 0",
            }}
            variant="contained"
            onClick={handleEvaluation}
            disabled={userReponse.length < 10}
          >
            {/* <NavigateNextIcon />{" "} */}
            Get Evaluated
          </Button>
        </div>
        <TextField
          value={userReponse}
          // defaultValue={}
          multiline
          rows={4}
          fullWidth
          onChange={(e) => {
            setUserResponse(e.target.value);
          }}
        />
        {evaluated && (
          <div>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Feedback
              </AccordionSummary>
              <AccordionDetails>
                {feedbackLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  feedback
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Jo Bro Response
              </AccordionSummary>
              <AccordionDetails>
                {JoBroResLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  joBroreponse
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
};

export default IcAiInterviewBlocks;
