import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
function addProtocol(url) {
  // Check if the URL already contains a protocol
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    // If not, prepend "https://"
    return "https://" + url;
  }
  // Otherwise, return the original URL
  return url;
}
const Template1 = ({ Cvdata }) => {
  const userData = useSelector((state) => {
    return state.user.userData;
  });
  return (
    <div id="resume" className="GeneratedCV">
      <div>
        <div className="fc" id="cv-details-top-0">
          {" "}
          <span style={{ fontSize: "2rem", fontWeight: "600" }} className="">
            {userData.first_name} {userData.last_name}
          </span>
          <div>{Cvdata.role}</div>
          <div className=" cv-top">
            {" "}
            <span>{userData.contact}</span>|<span>{userData.email}</span>
          </div>
          <p style={{ fontSize: "" }}>{Cvdata.about}</p>
        </div>
        {/* <div>
        <a className="cv-links" href={userData.twitter}>
          <TwitterIcon />
        </a>
        <a className="cv-links" href={userData.facebook}>
          {" "}
          <FacebookIcon />{" "}
        </a>
        <a className="cv-links" href={userData.instagram}>
          <InstagramIcon />
        </a>
        <a className="cv-links" href={userData.linkedin}>
          <LinkedInIcon />
        </a>
      </div> */}
        {Cvdata &&
          Cvdata.work_experience &&
          Cvdata.work_experience.length > 0 && (
            <div className="fc cv-content" style={{ gap: "15px" }}>
              <hr className="cv-hr" />
              <span className="cv-heading">Work Experience</span>
              {Cvdata.work_experience &&
                Cvdata.work_experience.map((experience) => (
                  <div
                    style={{
                      // justifyContent: "space-between",
                      // alignItems: "flex-start",
                      fontSize: "1.4rem",
                      // gap: "2p",
                    }}
                    className="fc "
                    key={experience.id}
                  >
                    <div
                      className="fr"
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {" "}
                        <span style={{ fontWeight: "600" }}>
                          {experience.role},
                        </span>{" "}
                        <span>{experience.employer}</span>
                      </div>
                      <p
                        style={{
                          marginTop: "0",
                          textWrap: "no-wrap",
                        }}
                      >
                        {dayjs(experience.startDate)
                          .format("MM-DD-YYYY")
                          .toString()
                          .substring(0, 10)}{" "}
                        -{" "}
                        {experience.currentlyWorking
                          ? "Present"
                          : dayjs(experience.endDate)
                              .format("MM-DD-YYYY")
                              .toString()
                              .substring(0, 10)}
                      </p>
                    </div>
                    <p
                      style={{
                        marginTop: "0.4rem",
                      }}
                    >
                      {experience.description}
                    </p>
                  </div>
                ))}
            </div>
          )}
        {Cvdata && Cvdata.education && Cvdata.education.length > 0 && (
          <div className="fc cv-content" style={{ gap: "15px" }}>
            <hr className="cv-hr" />
            <span className="cv-heading">Education</span>
            {Cvdata.education &&
              Cvdata.education.map((education) => (
                <div
                  style={{
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                  className="fr"
                  key={education.id}
                >
                  <div style={{ width: "70%" }}>
                    <span style={{ fontWeight: "600" }}>
                      {education.degree},
                    </span>{" "}
                    <span>{education.institution}</span>
                  </div>
                  <p style={{ marginTop: "0", textWrap: "no-wrap" }}>
                    {dayjs(education.startDate)
                      .format("MM-DD-YYYY")
                      .toString()
                      .substring(0, 10)}{" "}
                    -{" "}
                    {education.currentlyEnrolled
                      ? "Present"
                      : dayjs(education.endDate)
                          .format("MM-DD-YYYY")
                          .toString()
                          .substring(0, 10)}
                  </p>
                </div>
              ))}
          </div>
        )}
        {Cvdata && Cvdata.skills && Cvdata.skills.length > 0 && (
          <div className="fc cv-content">
            <hr className="cv-hr" />
            <span className="cv-heading">Skills</span>
            <nobr />
            <p
            // style={{ marginTop: "-1%" }}
            >
              {Cvdata.skills &&
                Cvdata.skills.map((item, index) => (
                  <span key={index}>
                    {item}
                    {index !== Cvdata.skills.length - 1 && ", "}{" "}
                  </span>
                ))}
            </p>
          </div>
        )}
        {Cvdata &&
          Cvdata.certifications &&
          Cvdata.certifications.length > 0 && (
            <div
              className="fc cv-content"
              style={{
                gap: "10px",
              }}
            >
              <hr className="cv-hr" />
              <span className="cv-heading">Certifications</span>
              {Cvdata.certifications &&
                Cvdata.certifications.map((item, index) => (
                  <span
                    className="fr"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                    key={index}
                  >
                    {item.link ? (
                      <a
                        style={{ color: "black", width: "70%" }}
                        href={addProtocol(item.link)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        {item.title}
                      </a>
                    ) : (
                      <span> {item.title}</span>
                    )}
                    <p
                      style={{
                        marginTop: "0",
                        textWrap: "no-wrap",
                      }}
                    >
                      {dayjs(item.startDate)
                        .format("MM-DD-YYYY")
                        .toString()
                        .substring(0, 10)}{" "}
                      -{" "}
                      {dayjs(item.endDate)
                        .format("MM-DD-YYYY")
                        .toString()
                        .substring(0, 10)}
                    </p>
                  </span>
                ))}
            </div>
          )}
        {Cvdata && Cvdata.achievements && Cvdata.achievements.length > 0 && (
          <div className="fc cv-content" style={{ gap: "10px" }}>
            <hr className="cv-hr" />
            <span className="cv-heading">Achievements</span>
            {Cvdata.achievements &&
              Cvdata.achievements.map((item, index) => (
                <span key={index}>{item}</span>
              ))}
          </div>
        )}
        {Cvdata && Cvdata.hobbies && Cvdata.hobbies.length > 0 && (
          <div className="fc cv-content">
            <hr className="cv-hr" />
            <span className="cv-heading">Hobbies</span>
            <nobr />
            <p
            // style={{ marginTop: "-1%" }}
            >
              {Cvdata.hobbies &&
                Cvdata.hobbies.map((item, index) => (
                  <span key={index}>
                    {item}
                    {index !== Cvdata.hobbies.length - 1 && ", "}{" "}
                  </span>
                ))}
            </p>
          </div>
        )}
        {Cvdata && Cvdata.languages && Cvdata.languages.length > 0 && (
          <div className="fc cv-content">
            <hr className="cv-hr" />
            <span className="cv-heading">Languages</span>
            <nobr />
            <p
            // style={{ marginTop: "-1%" }}
            >
              {Cvdata.languages &&
                Cvdata.languages.map((item, index) => (
                  <span key={index}>
                    {item}
                    {index !== Cvdata.languages.length - 1 && ", "}{" "}
                  </span>
                ))}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Template1;
