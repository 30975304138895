import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

const AiGenAch = ({
  index,
  achievement,
  handleAchievementChange,
  handleRemoveAchievement,
  jobdescription,
}) => {
  const [dummyAch, setDummyAch] = useState(achievement);
  const [joBroRes, setJoBroRes] = useState("");
  const [generatejoBroRes, setGeneratejoBroRes] = useState(false);
  const [checked, setChecked] = useState(0);
  const [regenerate, setRegenerate] = useState(false);
  const baseURL = "https://jobro.orchvate.com/api/v1/";
  const [generating, setGenerating] = useState(false);

  const handleGenerateJoBroRes = async () => {
    setGenerating(true);
    try {
      let data;
      if (jobdescription) {
        data = {
          button_id: "button_82",
        };
      } else {
        data = {
          button_id: "button_79",
        };
      }
      const response = await axios.post(`${baseURL}fetch_prompt`, data);
      let prompt = response.data.prompt;

      let newPrompt;

      if (jobdescription) {
        newPrompt = prompt.replace(/\[\[Achievement\]\]/g, achievement);
        newPrompt = newPrompt.replace(
          /\[\[JobDescription\]\]/g,
          jobdescription
        );
      } else {
        newPrompt = prompt.replace(/\[\[AchievementDesc\]\]/g, achievement);
      }

      const res = await axios.post(`${baseURL}get_ai_response`, {
        prompt_message: newPrompt,
      });

      setJoBroRes(res.data.ai_response);
    } catch (error) {
      console.log(error);
    } finally {
      setGeneratejoBroRes(true);
      setRegenerate(true);
      setGenerating(false);
    }
  };
  return (
    <div
      className="fr"
      style={{
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        gap: "10px",
      }}
    >
      <div className="fc" style={{ width: "100%", alignItems: "center" }}>
        <TextField
          sx={{ width: "100%", margin: "2% 0" }}
          fullWidth
          value={dummyAch}
          onChange={(event) => {
            handleAchievementChange(index, event.target.value);
            setDummyAch(event.target.value);
          }}
          required
          disabled={checked}
          id="outlined-basic"
          label="Achievements"
          variant="outlined"
        />
        {generatejoBroRes ? (
          <label className="fr" style={{ width: "100%" }}>
            <input
              type="checkbox"
              value={joBroRes}
              checked={checked}
              onClick={() => {
                setChecked(!checked);

                handleAchievementChange(index, checked ? dummyAch : joBroRes);
              }}
            />
            <TextField
              fullwidth
              multiline
              rows={3}
              disabled={!checked}
              sx={{ width: "100%" }}
              value={joBroRes}
              onChange={(e) => {
                handleAchievementChange(index, e.target.value);
                setJoBroRes(e.target.value);
              }}
              required
              id="outlined-basic"
              label="Jo Bro Response"
              variant="outlined"
            />
          </label>
        ) : (
          <div style={{ textAlign: "center" }}>
            {generating ? (
              <CircularProgress />
            ) : (
              <Button
                variant="outlined"
                disabled={!achievement || achievement.length < 10}
                sx={{
                  color: "#012970",
                  width: "fit-content",

                  borderColor: "#012970",
                  "&:hover": {
                    color: "#4154f1",
                    borderColor: "#4154f1",
                  },
                }}
                onClick={handleGenerateJoBroRes}
              >
                GENERATE JOBRO RESPONSE
              </Button>
            )}
          </div>
        )}
        {regenerate && (
          <div style={{ textAlign: "center" }}>
            {" "}
            {generating ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  width: "fit-content",
                  height: "fit-content",
                  alignSelf: "center",
                }}
                onClick={handleGenerateJoBroRes}
              >
                REGENERATE RESPONSE
              </Button>
            )}
          </div>
        )}{" "}
      </div>

      <Button
        style={{
          height: "fit-content",
          width: "fit-content",
          color: "#012970",
          borderColor: "#012970",
        }}
        onClick={() => handleRemoveAchievement(index)}
      >
        <DeleteIcon />
      </Button>
    </div>
  );
};

export default AiGenAch;
