import { Box, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DisplayGenText from "./DisplayGenText";
import { useLocation } from "react-router-dom";

const GenerateReport = ({}) => {
  const user_id = useSelector((state) => {
    return state.user.user_id;
  });
  const location = useLocation();
  const {
    UserRes_ND_Characteristics,
    UserRes_Executive_Functioning,
    UserRes_Interpersonal_Skills,
    SkillList_exec_func,
    SkillList_inter_pers,
  } = location.state;
  const [emailpitch_ai_response, setEmailpitch_ai_response] = useState("a");
  const [verbalpitch_ai_response, setVerbalpitch_ai_response] = useState("a");
  const [cv_ai_liner, setCv_ai_liner] = useState("a");
  const [interview_ai_intro, setInterview_ai_intro] = useState("a");
  const [strengths_ai_response, setStrength_ai_Response] = useState("a");
  const [weakness_ai_response, setWeakness_ai_response] = useState("a");
  const [loading, setLoading] = useState(true);
  const baseURL = "https://jobro.orchvate.com/api/v1/";
  // const baseUrl = "http://localhost:8000/api/";
  const baseUrl = "https://jobrobackendcv.azurewebsites.net/api/";
  const [StrengthList, setStrengthList] = useState([]);
  const [WeaknessList, setWeaknessList] = useState([]);

  const callApiToGenerateResponse = async ({
    button_id,
    strengths,
    weakness,
  }) => {
    try {
      setLoading(true);
      const response = await axios.post(`${baseURL}fetch_prompt`, {
        button_id: button_id,
      });

      let prompt = response.data.prompt;
      let newPrompt;
      if (button_id === "button_94") {
        newPrompt = prompt.replace(/\[\[Weakness\]\]/g, weakness);
      } else {
        newPrompt = prompt.replace(/\[\[Strengths\]\]/g, strengths);
      }
      const res = await axios.post(`${baseURL}get_ai_response`, {
        prompt_message: newPrompt,
      });
      return res.data.ai_response;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    const callApi = async () => {
      try {
        let strengthdummylist = [];
        let weaknessdummylist = [];

        UserRes_ND_Characteristics.map((item, index) => {
          if (item.response > 4) {
            strengthdummylist.push(item.Description);
          } else if (item.response < 2) {
            weaknessdummylist.push(item.Description);
          }
        });

        for (const [skillName, responses] of Object.entries(
          UserRes_Interpersonal_Skills
        )) {
          const totalResponses = responses.reduce(
            (sum, { Response }) => sum + Response,
            0
          );
          const averageResponse = totalResponses / responses.length;

          if (averageResponse >= 4) {
            strengthdummylist.push(skillName);
          } else if (averageResponse <= 2) {
            weaknessdummylist.push(skillName);
          }
        }
        for (const [skillName, responses] of Object.entries(
          UserRes_Executive_Functioning
        )) {
          const totalResponses = responses.reduce(
            (sum, { Response }) => sum + Response,
            0
          );
          const averageResponse = totalResponses / responses.length;

          if (averageResponse >= 4) {
            strengthdummylist.push(skillName);
          } else if (averageResponse <= 2) {
            weaknessdummylist.push(skillName);
          }
        }
        setStrengthList(strengthdummylist);
        setWeaknessList(weaknessdummylist);
        setCv_ai_liner(() =>
          callApiToGenerateResponse(
            "button_91",
            strengthdummylist,
            weaknessdummylist
          )
        );
        setInterview_ai_intro(() =>
          callApiToGenerateResponse(
            "button_92",
            strengthdummylist,
            weaknessdummylist
          )
        );
        setStrength_ai_Response(() =>
          callApiToGenerateResponse(
            "button_93",
            strengthdummylist,
            weaknessdummylist
          )
        );
        setWeakness_ai_response(() =>
          callApiToGenerateResponse(
            "button_94",
            strengthdummylist,
            weaknessdummylist
          )
        );
        setEmailpitch_ai_response(() =>
          callApiToGenerateResponse(
            "button_95",
            strengthdummylist,
            weaknessdummylist
          )
        );
        setVerbalpitch_ai_response(() =>
          callApiToGenerateResponse(
            "button_96",
            strengthdummylist,
            weaknessdummylist
          )
        );

        if (
          emailpitch_ai_response &&
          verbalpitch_ai_response &&
          cv_ai_liner &&
          interview_ai_intro &&
          strengths_ai_response &&
          weakness_ai_response
        ) {
          console.log(
            "REs data",
            UserRes_ND_Characteristics,
            UserRes_Executive_Functioning,
            UserRes_Interpersonal_Skills
          );
          console.log("stre", strengthdummylist);
          console.log("weak", weaknessdummylist);
          console.log(
            "lines",
            emailpitch_ai_response,
            verbalpitch_ai_response,
            cv_ai_liner,
            interview_ai_intro,
            strengths_ai_response,
            weakness_ai_response
          );
          const res3 = await axios.post(
            `${baseUrl}saveSelfAssessmentResponse`,
            {
              User_id: user_id,
              Self_Assessment_Modules: {
                ND_Characteristics: UserRes_ND_Characteristics,
                Executive_Functioning: UserRes_Executive_Functioning,
                Interpersonal_Skills: UserRes_Interpersonal_Skills,
              },
              Strengths: strengthdummylist,
              Weakness: weaknessdummylist,
              emailpitch_ai_response: emailpitch_ai_response,
              verbalpitch_ai_response: verbalpitch_ai_response,
              cv_ai_liner: cv_ai_liner,
              interview_ai_intro: interview_ai_intro,
              strengths_ai_response: strengths_ai_response,
              weakness_ai_response: weakness_ai_response,
            }
          );
          console.log(res3.data.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    callApi();
  }, []);
  return (
    <div>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <div className="fc">
          <DisplayGenText
            aiGenRes={cv_ai_liner}
            setAiGenRes={setCv_ai_liner}
            button_id="button_91"
            StrengthList={StrengthList}
            loading={loading}
          />
          <DisplayGenText
            aiGenRes={interview_ai_intro}
            setAiGenRes={setInterview_ai_intro}
            button_id="button_92"
            StrengthList={StrengthList}
            loading={loading}
          />
          <DisplayGenText
            aiGenRes={strengths_ai_response}
            setAiGenRes={setStrength_ai_Response}
            button_id="button_93"
            StrengthList={StrengthList}
            loading={loading}
          />
          <DisplayGenText
            aiGenRes={weakness_ai_response}
            setAiGenRes={setWeakness_ai_response}
            button_id="button_94"
            WeaknessList={WeaknessList}
            loading={loading}
          />
          <DisplayGenText
            aiGenRes={emailpitch_ai_response}
            setAiGenRes={setEmailpitch_ai_response}
            button_id="button_95"
            StrengthList={StrengthList}
            loading={loading}
          />
          <DisplayGenText
            aiGenRes={verbalpitch_ai_response}
            setAiGenRes={setVerbalpitch_ai_response}
            button_id="button_96"
            StrengthList={StrengthList}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};

export default GenerateReport;
