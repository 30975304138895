import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TestBlock from "./TestBlock";

const ExecutiveFunctioning = ({
  Executive_Functioning,
  item,
  UserRes_Executive_Functioning,
  setUserRes_Executive_Functioning,
  Interpersonal_Skills,
  currIndex,
  setCurrIndex,
  isLast,
  handleSelectionChange,
}) => {
  const OptionList = {
    "Applies to me a lot": 5,
    "Applies to me": 4,
    "Applies to me somewhat": 3,
    "Applies to me a little": 2,
    "Does not apply to me at all": 1,
  };

  // Find the initial value for the current question
  const initialResponse = UserRes_Executive_Functioning[item.SkillName]?.find(
    (response) => response.Description === item.Description
  )?.Response;

  const [selectedValue, setSelectedValue] = useState(
    initialResponse !== undefined
      ? Object.keys(OptionList).find(
          (key) => OptionList[key] === initialResponse
        )
      : ""
  );

  useEffect(() => {
    const initialResponse = UserRes_Executive_Functioning[item.SkillName]?.find(
      (response) => response.Description === item.Description
    )?.Response;

    setSelectedValue(
      initialResponse !== undefined
        ? Object.keys(OptionList).find(
            (key) => OptionList[key] === initialResponse
          )
        : ""
    );
  }, [
    currIndex,
    item.Description,
    item.SkillName,
    UserRes_Executive_Functioning,
  ]);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const saveResponse = () => {
    const responseIndex = UserRes_Executive_Functioning[
      item.SkillName
    ]?.findIndex((response) => response.Description === item.Description);

    const updatedResponse = {
      Description: item.Description,
      Response: OptionList[selectedValue],
    };

    let updatedResponses = { ...UserRes_Executive_Functioning };

    if (responseIndex > -1) {
      // Update existing response
      updatedResponses[item.SkillName] = [
        ...UserRes_Executive_Functioning[item.SkillName],
      ];
      updatedResponses[item.SkillName][responseIndex] = updatedResponse;
    } else {
      // Add new response
      updatedResponses[item.SkillName] = [
        ...(UserRes_Executive_Functioning[item.SkillName] || []),
        updatedResponse,
      ];
    }

    setUserRes_Executive_Functioning(updatedResponses);
  };

  const handleNext = () => {
    saveResponse();
    setCurrIndex(currIndex + 1);
  };

  const handlePrevious = () => {
    saveResponse();
    setCurrIndex(currIndex - 1);
    if (currIndex === 0) {
      handleSelectionChange("div1");
    }
  };

  const SaveFinal = () => {
    saveResponse();
    handleSelectionChange("div3");
    // Handle final save logic, such as submitting the results
  };

  return (
    <div>
      <div className="fc main-div-selfAssess">
        <div id="selfAssess-heading">{item.Description}</div>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            sx={{ gap: "5px" }}
            className="fc"
            value={selectedValue}
            onChange={handleRadioChange}
          >
            {Object.keys(OptionList).map((optionItem, index) => (
              <FormControlLabel
                key={index}
                value={optionItem}
                control={<Radio />}
                label={optionItem}
                defaultChecked={optionItem === selectedValue}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <div className="fr" style={{ justifyContent: "space-between" }}>
          <Button variant="outlined" onClick={handlePrevious}>
            Previous
          </Button>
          {isLast ? (
            <Button variant="outlined" onClick={SaveFinal}>
              Next
            </Button>
          ) : (
            <Button variant="outlined" onClick={handleNext}>
              Next
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExecutiveFunctioning;
