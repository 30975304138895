import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

const AIGenAbout = ({
  about,
  setAbout,
  finalAbout,
  setFinalAbout,
  jobdescription,
  loading,
  educations,
  workExperiences,
  skills,
}) => {
  const [joBroRes, setJoBroRes] = useState("Some Response");
  const [generatejoBroRes, setGeneratejoBroRes] = useState(false);
  const [checked, setChecked] = useState(false);
  const [regenerate, setRegenerate] = useState(false);
  const [generating, setGenerating] = useState(false);

  const baseURL = "https://jobro.orchvate.com/api/v1/";
  const handleGenerateJoBroRes = async () => {
    setGenerating(true);
    try {
      let data;
      // jobdescription={jobdescription}
      if (jobdescription) {
        data = {
          button_id: "button_83",
        };
      } else {
        data = {
          button_id: "button_80",
        };
      }
      const response = await axios.post(`${baseURL}fetch_prompt`, data);

      let prompt = response.data.prompt;

      let newWorkExp = "";
      let newEducation = "";
      workExperiences.map((item, index) => {
        newWorkExp =
          newWorkExp +
          `${item.role} at ${item.employer} from ${item.startDate
            .toString()
            .slice(0, 10)} to ${item.endDate.toString().slice(0, 10)}:${
            item.description
          },`;
      });
      educations.map((item, index) => {
        newEducation =
          newEducation +
          `${item.degree} at ${item.institution} from ${item.startDate
            .toString()
            .slice(0, 10)} to ${item.endDate.toString().slice(0, 10)},`;
      });
      let newPrompt = prompt.replace(/\[\[About\]\]/g, about);
      newPrompt = newPrompt.replace(/\[\[WorkExp\]\]/g, newWorkExp);
      newPrompt = newPrompt.replace(/\[\[Education\]\]/g, newEducation);
      newPrompt = newPrompt.replace(/\[\[Skills\]\]/g, skills);

      if (jobdescription) {
        newPrompt = newPrompt.replace(
          /\[\[JobDescription\]\]/g,
          jobdescription
        );
      }
      console.log("This is new prompt", newPrompt);
      const res = await axios.post(`${baseURL}get_ai_response`, {
        prompt_message: newPrompt,
      });

      setJoBroRes(res.data.ai_response);
      // console.log(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setGeneratejoBroRes(true);
      setRegenerate(true);
      setGenerating(false);
    }
  };
  return (
    <div className="fc" style={{ margin: "5%" }}>
      <h1 style={{}}>About</h1>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className="fc" style={{ gap: "3rem" }}>
          <TextField
            fullWidth
            multiline
            disabled={checked}
            rows={4}
            name="about"
            value={about}
            onChange={(e) => {
              const updatedAbout = e.target.value;
              setAbout(updatedAbout);
              setFinalAbout(e.target.value);
            }}
            required
            id="outlined-basic"
            variant="outlined"
          />

          {generatejoBroRes ? (
            <label className="fr">
              <input
                type="checkbox"
                value={joBroRes}
                checked={checked}
                onChange={(e) => {
                  setJoBroRes(e.target.value);
                }}
                onClick={(e) => {
                  // console.log("this is smtg", about);

                  setChecked(!checked);
                  setFinalAbout((prevFinalAbout) => {
                    if (checked) {
                      return about;
                    } else {
                      return joBroRes;
                    }
                  });
                }}
              />
              <TextField
                fullWidth
                multiline
                rowa={4}
                disabled={!checked}
                // sx={{ width: "190%" }}
                value={joBroRes}
                // value={joBroRes}
                onChange={(e) => {
                  setJoBroRes(e.target.value);
                  setFinalAbout(e.target.value);
                }}
                required
                id="outlined-basic"
                label="Jo Bro Response"
                variant="outlined"
              />
            </label>
          ) : (
            <div style={{ textAlign: "center" }}>
              {generating ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="outlined"
                  disabled={!about || about.length < 10}
                  sx={{
                    width: "fit-content",
                    color: "#012970",
                    alignSelf: "center",
                    borderColor: " #012970",

                    "&:hover": {
                      color: "#4154f1",
                      borderColor: "#4154f1",
                    },
                  }}
                  onClick={handleGenerateJoBroRes}
                >
                  GENERATE JOBRO RESPONSE
                </Button>
              )}
            </div>
          )}
          {regenerate && (
            <div style={{ textAlign: "center" }}>
              {" "}
              {generating ? (
                <CircularProgress />
              ) : (
                <Button
                  sx={{
                    width: "fit-content",
                    height: "fit-content",
                    alignSelf: "center",
                  }}
                  onClick={handleGenerateJoBroRes}
                >
                  REGENERATE RESPONSE
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AIGenAbout;
