import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import jsPDF from "jspdf";
import { useNavigate } from "react-router-dom";
import ReasAccPage from "./ReasAccPage";

const DisplayUserData = ({
  userRes,

  DataDisplay,
  setUserRes,
  setCurrIndex,
  currIndex,
  formFilled,
  pitches,
}) => {
  const handleDownloadEmailasPDF = () => {
    const input = document.getElementById("email");
    const pdf = new jsPDF("portrait", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const margin = 10;
    const textWidth = pageWidth - margin * 2;
    const text = input.value;

    const lines = pdf.splitTextToSize(text, textWidth);
    let y = 20;
    for (let i = 0; i < lines.length; i++) {
      if (y > 270) {
        // Check if page limit reached, add new page if needed
        pdf.addPage();
        y = 20;
      }
      pdf.text(margin, y, lines[i]);
      y += 10; // Line height
    }

    pdf.save("email_pitch.pdf");
  };
  const handleDownloadVerbalasPDF = () => {
    const input = document.getElementById("verbal");
    const pdf = new jsPDF("portrait", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const margin = 10;
    const textWidth = pageWidth - margin * 2;
    const text = input.value;

    const lines = pdf.splitTextToSize(text, textWidth);
    let y = 20;
    for (let i = 0; i < lines.length; i++) {
      if (y > 270) {
        // Check if page limit reached, add new page if needed
        pdf.addPage();
        y = 20;
      }
      pdf.text(margin, y, lines[i]);
      y += 10; // Line height
    }

    pdf.save("verbal_pitch.pdf");
  };
  const [reGenerate, setReGenerate] = useState(false);
  const handleNavigate = () => {
    try {
      setReGenerate(true);
    } catch (error) {}
  };
  return (
    <div>
      {reGenerate ? (
        <ReasAccPage
          DataDisplay={DataDisplay}
          userRes={userRes}
          setUserRes={setUserRes}
          setCurrIndex={setCurrIndex}
          currIndex={currIndex}
          formFilled={formFilled}
        />
      ) : (
        <div
          className="fc ptchGeneration-main-divi"
          style={{ marginBottom: "0" }}
        >
          <span>
            <strong style={{ fontSize: "1.5rem" }}>JoBro Response:</strong>
          </span>
          <div className="fc" id="ptchGeneration-container-divi">
            <div>
              <div>Email Pitch:</div>{" "}
              <div>
                <TextField
                  id="email"
                  value={pitches.email}
                  fullWidth
                  rows={10}
                  multiline
                />
                <div className="fr res-button-div">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigator.clipboard.writeText(pitches.email);
                    }}
                  >
                    <ContentCopyIcon />
                  </Button>
                  <Button variant="outlined" onClick={handleDownloadEmailasPDF}>
                    <FileDownloadIcon />
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div>Verbal Pitch:</div>

              <div>
                <TextField
                  id="verbal"
                  value={pitches.pitch}
                  fullWidth
                  rows={10}
                  multiline
                />
                <div className="fr res-button-div">
                  {" "}
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigator.clipboard.writeText(pitches.pitch);
                    }}
                  >
                    <ContentCopyIcon />
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleDownloadVerbalasPDF}
                  >
                    <FileDownloadIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Button
            sx={{
              borderColor: "#012970",
              color: "#012970",
              width: "fit-content",
              margin: "2% 0",
              alignSelf: "end",
            }}
            variant="outlined"
            onClick={handleNavigate}
          >
            REGENERATE
          </Button>
          <div
            style={{
              fontFamily: "Cutive Mono, monospace",
              textAlign: "center",
              color: "grey",
            }}
          >
            Disclaimer Remember that while AI can assist in various tasks, its
            performance is contingent on the quality of the data it interacts
            with. Taking steps to ensure data accuracy is essential for
            obtaining reliable and meaningful results. Thank you for your
            understanding and commitment to using AI responsibly. Remember that
            while AI can assist in various tasks, its performance is contingent
            on the quality of the data it interacts with. Taking steps to ensure
            data accuracy is essential for obtaining reliable and meaningful
            results. Thank you for your understanding and commitment to using AI
            responsibly.
          </div>
        </div>
      )}
    </div>
  );
};

export default DisplayUserData;
