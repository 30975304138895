// SelfAssessmentModules.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import DisplayGeneratedPitch from "./DisplayGeneratedPitch";
import NdcharacteristicBlock from "./NdcharacteristicBlock";
import InterpersonalskillsBlock from "./InterpersonalskillsBlock";
import ExecutiveFunctioning from "./ExecutiveFunctioning";
import { Button } from "@mui/material";
import SelfassesTest from "./SelfassesTest";

const SelfAssessmentModules = () => {
  const [ND_Characteristics, setND_Characteristics] = useState([]);
  const [Executive_Functioning, setExecutive_Functioning] = useState([]);
  const [Interpersonal_Skills, setInterpersonal_Skills] = useState([]);
  // const [SkillList_exec_func, setSkillList_exec_func] = useState([]);
  // const [SkillList_inter_pers, setSkillList_inter_pers] = useState([]);

  // const baseUrl = "http://localhost:8000/api/";
  const baseUrl = "https://jobrobackendcv.azurewebsites.net/api/";

  const [loading, setLoading] = useState(true);

  const [cv_ai_liner, setCv_ai_liner] = useState("");
  const [interview_ai_intro, setInterview_ai_intro] = useState("");
  const [strengths_ai_response, setStrength_ai_Response] = useState("");
  const [weakness_ai_response, setWeakness_ai_response] = useState("");
  const [emailpitch_ai_response, setEmailpitch_ai_response] = useState("");
  const [verbalpitch_ai_response, setVerbalpitch_ai_response] = useState("");
  const [formFilled, setFormFilled] = useState(false);
  const user_id = useSelector((state) => state.user.user_id);
  const [UserRes_ND_Characteristics, setUserRes_ND_Characteristics] = useState(
    []
  );
  const [UserRes_Executive_Functioning, setUserRes_Executive_Functioning] =
    useState({});
  const [UserRes_Interpersonal_Skills, setUserRes_Interpersonal_Skills] =
    useState({});

  useEffect(() => {
    const callApi = async () => {
      try {
        const res = await axios.get(`${baseUrl}display-data`);
        const combinedDescriptions =
          res.data.Self_Assessment_Modules.ND_Characteristics.reduce(
            (acc, characteristic) => acc.concat(characteristic.Description),
            []
          );
        setND_Characteristics(combinedDescriptions);

        setExecutive_Functioning(
          res.data.Self_Assessment_Modules.Executive_Functioning.displayData
        );
        // setSkillList_exec_func(
        //   res.data.Self_Assessment_Modules.Executive_Functioning.SkillList
        // );
        setInterpersonal_Skills(
          res.data.Self_Assessment_Modules.Interpersonal_Skills.displayData
        );
        // setSkillList_inter_pers(
        //   res.data.Self_Assessment_Modules.Interpersonal_Skills.SkillList
        // );
        // console.log("API Response:", res.data);
        // console.log("sss");
        const response = await axios.get(
          `${baseUrl}getSelfAssessmentResponse`,
          {
            params: {
              User_id: user_id,
            },
          }
        );
        console.log("tofetch", response);
        if (response.data.success) {
          setUserRes_ND_Characteristics(
            response.data.data.Self_Assessment_Modules.ND_Characteristics
          );

          setUserRes_Executive_Functioning(
            response.data.data.Self_Assessment_Modules.Executive_Functioning
          );
          setUserRes_Interpersonal_Skills(
            response.data.data.Self_Assessment_Modules.Interpersonal_Skills
          );
          setCv_ai_liner(response.data.data.cv_ai_liner);
          setInterview_ai_intro(response.data.data.interview_ai_intro);
          setStrength_ai_Response(response.data.data.strengths_ai_response);
          setWeakness_ai_response(response.data.data.weakness_ai_response);
          setEmailpitch_ai_response(response.data.data.emailpitch_ai_response);
          setVerbalpitch_ai_response(
            response.data.data.verbalpitch_ai_response
          );

          setFormFilled(true);
        }
        console.log(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    callApi();
  }, [baseUrl, user_id]);

  return (
    <div className="fc page-container">
      <div className="fc main-header-div">
        <span className="page-heading"> Self Assessment Modules</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> /{" "}
          <Link to="/happilyunmasked">happily unmasked</Link> / self assessment
          modules
        </span>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          {formFilled ? (
            <DisplayGeneratedPitch
              ND_Characteristics={ND_Characteristics}
              Executive_Functioning={Executive_Functioning}
              Interpersonal_Skills={Interpersonal_Skills}
              UserRes_ND_Characteristics={UserRes_ND_Characteristics}
              setUserRes_ND_Characteristics={setUserRes_ND_Characteristics}
              UserRes_Executive_Functioning={UserRes_Executive_Functioning}
              setUserRes_Executive_Functioning={
                setUserRes_Executive_Functioning
              }
              UserRes_Interpersonal_Skills={UserRes_Interpersonal_Skills}
              setUserRes_Interpersonal_Skills={setUserRes_Interpersonal_Skills}
              emailpitch_ai_response={emailpitch_ai_response}
              cv_ai_liner={cv_ai_liner}
              interview_ai_intro={interview_ai_intro}
              strengths_ai_response={strengths_ai_response}
              weakness_ai_response={weakness_ai_response}
              verbalpitch_ai_response={verbalpitch_ai_response}
            />
          ) : (
            <SelfassesTest
              ND_Characteristics={ND_Characteristics}
              Executive_Functioning={Executive_Functioning}
              Interpersonal_Skills={Interpersonal_Skills}
              UserRes_ND_Characteristics={UserRes_ND_Characteristics}
              setUserRes_ND_Characteristics={setUserRes_ND_Characteristics}
              UserRes_Executive_Functioning={UserRes_Executive_Functioning}
              setUserRes_Executive_Functioning={
                setUserRes_Executive_Functioning
              }
              UserRes_Interpersonal_Skills={UserRes_Interpersonal_Skills}
              setUserRes_Interpersonal_Skills={setUserRes_Interpersonal_Skills}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SelfAssessmentModules;
