import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import "../assets/css/Login.css";
import { Box, Button, CircularProgress } from "@mui/material";

import img from "../assets/img/JoBro-colorful.png";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useDispatch } from "react-redux";
import { login, updateSession, updateUserId } from "../store/action";

const Login = ({ baseUrl, flag }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const sessionId = useSelector((state) => {
  //   return state.user.sessionId;
  // });
  // const isAuth = useSelector((state) => {
  //   return state.user.isAuth;
  // });
  // const user_id = useSelector((state) => {
  //   return state.user.user_id;
  // });

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const logindata = {
        email: email,
        password: password,
      };

      const response = await axios.post(`${baseUrl}/api/v1/login`, logindata);
      // console.log(response);
      if (response.data) {
        toast.success("Successfully Logged In");
        console.log(response.data);
        const sess_token = response.data.session_token;
        const user_id = response.data.user_id;

        dispatch(updateSession(sess_token));
        dispatch(login(true));
        dispatch(updateUserId(user_id));
        // console.log("cons_user", user_id);
        // console.log("this is updated", isAuth, sessionId);
        // console.log("session id is ", sessionId);
        if (flag) {
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      } else {
        toast.error("Login failed");
      }
    } catch (error) {
      console.log(error);
      toast.error("There has been some error");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Toaster />
      <div className="container-login">
        <img src={img} width="50%" alt="" />

        <TextField
          sx={{ width: "80%" }}
          id="outlined-basic"
          label="Username"
          variant="outlined"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          sx={{ width: "80%" }}
          id="outlined-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <Button onClick={handleLogin} variant="contained">
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                padding: "1%",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            "Login"
          )}
        </Button>
        <div>
          <p>
            Don't have an account? <Link to="/register">Register here</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
