import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import "../../assets/css/Cv.css";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";
import img1 from "../../assets/img/temp3.png";
import img2 from "../../assets/img/temp1.png";
import img3 from "../../assets/img/temp2.png";

import { updateCv } from "../../store/action";
// const data = [];
const templateList = [
  {
    name: "template1",
    link: img1,
  },
  {
    name: "template2",
    link: img2,
  },
  {
    name: "template3",
    link: img3,
  },
  {
    name: "template4",
    link: img3,
  },
];
const CvList = () => {
  // const DummyUrl = "http://localhost:8000/api/";
  const cvBaseUrl = "https://jobrobackendcv.azurewebsites.net/api/";
  // const cvBaseUrl = "http://localhost:8000/api/";
  const [openDialog, setOpenDialog] = useState(false);
  const [cvData, setCvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const user_id = useSelector((state) => {
    return state.user.user_id;
  });
  const cvListState = useSelector((state) => {
    return state.user.CvListState;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const getCvs = async () => {
      setLoading(true);
      try {
        if (cvListState != null) {
          setCvData(cvListState);
          return;
        }
        const res = await axios.get(`${cvBaseUrl}getUserCV/${user_id}`);

        if (res.data.success === true) {
          dispatch(updateCv(res.data.Cv));
          // console.log("Hiiiiiiijjj", cvListState);
          setCvData(res.data.Cv);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    getCvs();
  }, []);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const navigate = useNavigate();
  const [template, setTemplate] = useState("");
  const [step1, setStep1] = useState(true);
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className="fc  page-container">
      <div className="fc main-header-div ">
        <span className="page-heading">CV Assisstant</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> / cv assisstant
        </span>
      </div>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "70vh",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className="fr cv-list-container">
          <Button onClick={handleOpenDialog} className="cv-list-item">
            {" "}
            <AddIcon sx={{ fontSize: "2.5rem" }} />
          </Button>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {step1
                ? "Please choose a template"
                : "Do you want a CV customized according to a particular Job Description?"}
            </DialogTitle>
            {/* <DialogContent>
                <DialogContentText>SOme description</DialogContentText>
              </DialogContent> */}

            {step1 && (
              <FormControl
                sx={{
                  // height: "800px",
                  // width: "550px",
                  margin: "auto",
                  padding: "0 2%",
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  sx={{ margin: "auto" }}
                >
                  <div
                    style={{
                      flexWrap: "wrap",
                      margin: "auto",
                      width: "100%",
                      alignSelf: "center",
                      justifyContent: "space-evenly",
                      gap: "15px",
                    }}
                    className="fr"
                  >
                    {" "}
                    {templateList.map((item, index) => {
                      // console.log("l", cvListState);
                      // console.log("k", item);
                      return (
                        <div
                          className="fc"
                          style={{
                            alignItems: "left",
                            // padding: "5%",
                          }}
                        >
                          <FormControlLabel
                            value={item.name}
                            onClick={() => setTemplate(item.name)}
                            control={<Radio />}
                            label={
                              <img
                                src={item.link}
                                alt={item.name}
                                style={{ width: "150px", height: "auto" }}
                              />
                            }
                          />
                          <FormHelperText>{item.name}</FormHelperText>
                        </div>
                      );
                    })}
                  </div>
                </RadioGroup>
              </FormControl>
            )}

            <DialogActions>
              {step1 ? (
                <div className="fc">
                  <Button
                    onClick={() => {
                      setStep1(false);
                    }}
                    autoFocus
                  >
                    Proceed
                  </Button>
                </div>
              ) : (
                <div>
                  <Link
                    to="/cvbuilder"
                    state={{
                      toAddJobdescription: "false",
                      template: template,
                    }}
                  >
                    {" "}
                    <Button autoFocus>NO</Button>
                  </Link>
                  <Link
                    // to={{
                    //   pathname: "/cvbuilder",
                    //   toAddJobdescription: true,
                    // }}
                    to="/cvbuilder"
                    state={{ toAddJobdescription: "true" }}
                  >
                    {" "}
                    <Button autoFocus>YES</Button>
                  </Link>
                </div>
              )}
            </DialogActions>
          </Dialog>
          {Array.isArray(cvData) &&
            cvData.map((item) => {
              return (
                // <Link to={`/cv/${item._id}`} key={item._id}>
                <div
                  onClick={() => {
                    console.log("k", item);
                    navigate(`/cv/${item._id}`, {
                      state: { template: item.template },
                    });
                    window.scrollTo(0, 0);
                  }}
                  className="cv-list-item"
                >
                  {item.name}
                </div>
                // </Link>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default CvList;
