import axios from "axios";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Challenges from "./Challenges";
import { useSelector } from "react-redux";
import DisplayUserData from "./DisplayUserData";
import ReasAccPage from "./ReasAccPage";

const ReasonableAccomodation = () => {
  //   const [responseOne, setResponseOne] = useState("");
  //   const [responseTwo, setResponseTwo] = useState("");
  const [DataDisplay, setDataDisplay] = useState(null);
  const [userRes, setUserRes] = useState([]);
  // const baseUrl = "https://jobrobackendcv.azurewebsites.net/api/";
  // const baseUrl = "http://localhost:8000/api/";
  const baseUrl = "https://jobrobackendcv.azurewebsites.net/api/";

  const user_id = useSelector((state) => {
    return state.user.user_id;
  });
  const [formFilled, setFormFilled] = useState(false);
  const [pitches, setPitches] = useState({});

  useEffect(() => {
    const callApi = async () => {
      try {
        const res = await axios.get(`${baseUrl}display-data`);
        setDataDisplay(res.data);

        const response = await axios.get(`${baseUrl}getUserResponse`, {
          user_id: user_id,
        });
        if (response.data.success) {
          console.log("LLL", response.data.data.ReasonableAccomodation.UserRes);
          setUserRes(response.data.data.ReasonableAccomodation.UserRes);

          setPitches(response.data.data.ReasonableAccomodation.Ai_response);
          setFormFilled(true);
        }
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };
    callApi();
  }, []);
  const [currIndex, setCurrIndex] = useState(0);
  return (
    <div className="fc page-container">
      <div className="fc main-header-div">
        <span className="page-heading">Reasonable A ccomodation</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> /{" "}
          <Link to="/happilyunmasked">happily unmasked</Link> / reasonable
          accomodation
        </span>
      </div>
      {formFilled ? (
        <DisplayUserData
          userRes={userRes}
          DataDisplay={DataDisplay}
          setUserRes={setUserRes}
          setCurrIndex={setCurrIndex}
          currIndex={currIndex}
          formFilled={formFilled}
          pitches={pitches}
        />
      ) : (
        <ReasAccPage
          DataDisplay={DataDisplay}
          userRes={userRes}
          setUserRes={setUserRes}
          setCurrIndex={setCurrIndex}
          currIndex={currIndex}
        />
      )}
    </div>
  );
};

export default ReasonableAccomodation;
