import React, { useState, useEffect } from "react";
import "../../../assets/css/ChallenegsInmasked.css";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Challenges = ({
  item,
  currIndex,
  setCurrIndex,
  isLast,
  userRes,
  setUserRes,
  formFilled,
}) => {
  const accomodation = item.Accomodations;
  const [selectedAccommodations, setSelectedAccommodations] = useState([]);

  // Add console log to check item structure
  // console.log("Islast:", isLast);

  useEffect(() => {
    // Add console log to check userRes
    // console.log("User Res:", userRes);
    if (formFilled) {
      setSelectedAccommodations(userRes[currIndex].Accomodations);
    } else {
      const existingResponse = userRes.find(
        (res) => res.Challenge === item.Challenge
      );
      if (existingResponse) {
        setSelectedAccommodations(existingResponse.Accomodations);
      } else {
        setSelectedAccommodations([]);
      }
    }
  }, [item, userRes, formFilled]);

  const handleCheckboxChange = (accItem) => {
    setSelectedAccommodations((prevSelected) =>
      prevSelected.includes(accItem)
        ? prevSelected.filter((item) => item !== accItem)
        : [...prevSelected, accItem]
    );
  };

  const saveResponse = () => {
    const newResponse = {
      Challenge: item.Challenge,
      Accomodations: selectedAccommodations,
    };
    setUserRes((prevRes) => {
      const existingIndex = prevRes.findIndex(
        (res) => res.Challenge === item.Challenge
      );
      if (existingIndex >= 0) {
        const updatedRes = [...prevRes];
        updatedRes[existingIndex] = newResponse;
        return updatedRes;
      } else {
        return [...prevRes, newResponse];
      }
    });
  };

  const handleNext = () => {
    saveResponse();
    setCurrIndex(currIndex + 1);
  };

  const handlePrevious = () => {
    saveResponse();
    setCurrIndex(currIndex - 1);
  };

  const user_id = useSelector((state) => {
    return state.user.user_id;
  });
  const navigate = useNavigate();

  const SaveFinal = async () => {
    await saveResponse();
    navigate("/ReasonableAccomodationPitchGenerate", {
      state: {
        UserRes: userRes,
      },
    });
  };

  // // Check if accomodation is defined
  // if (!accomodation) {
  //   return <div> not found for this challenge.</div>;
  // }

  return (
    <div className="fc Main-challenge-div">
      <div>{item.Challenge}</div>
      <FormControl>
        <FormGroup
          sx={{
            display: "flex",
            gap: "4px",
          }}
        >
          {accomodation.map((accItem) => (
            <FormControlLabel
              sx={{}}
              value={accItem}
              defaultChecked={
                selectedAccommodations &&
                selectedAccommodations.length > 0 &&
                selectedAccommodations.includes(accItem)
              }
              checked={
                selectedAccommodations &&
                selectedAccommodations.length > 0 &&
                selectedAccommodations.includes(accItem)
              }
              control={
                <Checkbox
                  defaultChecked={
                    selectedAccommodations &&
                    selectedAccommodations.length > 0 &&
                    selectedAccommodations.includes(accItem)
                  }
                  checked={
                    selectedAccommodations &&
                    selectedAccommodations.length > 0 &&
                    selectedAccommodations.includes(accItem)
                  }
                  onChange={() => handleCheckboxChange(accItem)}
                />
              }
              label={accItem}
              key={accItem}
              labelPlacement="end"
            />
          ))}
        </FormGroup>
      </FormControl>
      <div
        className="fr chall-button-div"
        style={{ justifyContent: "space-between" }}
      >
        <Button
          variant="outlined"
          onClick={handlePrevious}
          disabled={currIndex === 0}
        >
          Previous
        </Button>
        {isLast ? (
          <Button variant="outlined" onClick={SaveFinal}>
            Generate Report
          </Button>
        ) : (
          <Button variant="outlined" onClick={handleNext}>
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default Challenges;
