import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import DescWorkExp from "./DescWorkExp";
// import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

// import timezone from "dayjs/plugin/timezone";
const WorkExperiencePage = ({
  loading,
  workExperiences,
  setWorkExperiences,
  handleWorkInputChange,
  addWorkExperience,
  deleteWorkExperience,
  jobdescription,
}) => {
  dayjs.extend(utc);
  // dayjs.extend(timezone);

  return (
    <div className="fc" style={{ margin: "5%" }}>
      <h1>Work Experience</h1>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className="fc">
          {workExperiences.map((experience) => (
            <div
              className="fr"
              key={experience.id}
              style={{ margin: "3% 0", width: "100%" }}
            >
              <div className="fc" style={{ width: "100%" }}>
                {" "}
                <div className="fr" style={{ justifyContent: "space-between" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      renderInput={(props) => <TextField {...props} />}
                      disableFuture
                      value={
                        experience && experience.startDate
                          ? dayjs(experience.startDate)
                          : null
                      }
                      onChange={(e) => {
                        handleWorkInputChange(experience.id, "startDate", e);
                      }}
                      label="Start Date"
                      sx={{ margin: "2% 0" }}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      renderInput={(props) => <TextField {...props} />}
                      disableFuture
                      minDate={experience.startDate}
                      sx={{ margin: "2% 0" }}
                      value={
                        experience && experience.endDate
                          ? dayjs(experience.endDate)
                          : null
                      }
                      onChange={(e) => {
                        handleWorkInputChange(experience.id, "endDate", e);
                      }}
                      label="End Date"
                      disabled={experience.currentlyWorking}
                    />
                  </LocalizationProvider>
                  {/* <CurrentlyWork
                    experience={experience}
                    handleWorkInputChange={handleWorkInputChange}
                  /> */}
                  <FormControlLabel
                    control={<Checkbox />}
                    // checked={checked}
                    value={experience.currentlyWorking}
                    checked={experience.currentlyWorking}
                    onChange={() => {
                      handleWorkInputChange(
                        experience.id,
                        "currentlyWorking",
                        !experience.currentlyWorking
                      );
                    }}
                    label={"Currently Working"}
                  />
                </div>
                <TextField
                  fullWidth
                  sx={{ margin: "2% 0" }}
                  defaultValue={experience.employer}
                  onChange={(e) =>
                    handleWorkInputChange(
                      experience.id,
                      "employer",
                      e.target.value
                    )
                  }
                  required
                  id="outlined-basic"
                  label="Employer"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  sx={{ margin: "2% 0", marginTop: "0" }}
                  defaultValue={experience.role}
                  onChange={(e) =>
                    handleWorkInputChange(experience.id, "role", e.target.value)
                  }
                  id="outlined-basic"
                  required
                  label="Role"
                  variant="outlined"
                />
                <DescWorkExp
                  experience={experience}
                  workExperiences={workExperiences}
                  setWorkExperiences={setWorkExperiences}
                  handleWorkInputChange={handleWorkInputChange}
                  jobdescription={jobdescription}
                />
              </div>

              <Button
                style={{
                  color: "#012970",
                  borderColor: "#012970",
                  alignSelf: "center",
                  width: "fit-content",
                  height: "fit-content",
                }}
                onClick={() => deleteWorkExperience(experience.id)}
              >
                <DeleteIcon />
              </Button>
            </div>
          ))}
          <Button
            style={{
              width: "fit-content",
              margin: "2% 0",
              backgroundColor: "#012970",
            }}
            variant="contained"
            onClick={addWorkExperience}
          >
            + Add Work Experience
          </Button>
        </div>
      )}
    </div>
  );
};

export default WorkExperiencePage;
