import React, { useState } from "react";
import "../../assets/css/KnowledgeBase.css";
import { Link } from "react-router-dom";
const KbButton = ({ label, link }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  return (
    <Link
      to={link}
      className={`button ${isPressed ? "pressed" : ""}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {label}
    </Link>
  );
};

export default KbButton;
