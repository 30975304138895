import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import { TextField, Button } from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "../../../assets/css/interviewcoach.css";
import axios from "axios";
// const DummyUrl = "http://localhost:8000/api/";
const DummyUrl = "https://jobrobackendcv.azurewebsites.net/api/";

const IcAIResponsereport = () => {
  const [reportData, setReportData] = useState([]);
  const location = useLocation();
  const { interviewId } = location.state || {};
  useEffect(() => {
    const callApi = async () => {
      try {
        const res = await axios.get(
          `${DummyUrl}getInterviewDetails/${interviewId}`
        );
        // console.log(res.data.interviewDetails);
        setReportData(res.data.interviewDetails.response);
      } catch (error) {
        console.log(error);
      }
    };
    callApi();
  }, []);
  const handleDownloadPDF = async () => {
    const input = document.getElementById("report");

    try {
      const canvas = await html2canvas(input, {
        useCORS: true,
        logging: false,
        scale: 2,
      });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("portrait", "mm", "a4");

      let imgHeight = (canvas.height * 210) / canvas.width;
      let position = 0;

      while (position < canvas.height) {
        // Add current page to PDF
        pdf.addImage(imgData, "PNG", 0, position, 210, imgHeight);
        position -= 297; // Subtract the page height (297mm for A4) for the next page
        if (position > 0) {
          pdf.addPage(); // Add new page if content exceeds current page
        }
      }
      pdf.save("InterView Report.pdf");
    } catch (error) {
      console.error("Error converting to PDF:", error);
    }
  };

  return (
    <div className="page-container">
      <div className="fc main-header-div ">
        <span className="page-heading">Interview Report</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> /{" "}
          <Link to="/interviewCoach">interview coach</Link> /{" "}
          <Link to="/aiInterview">ai interview</Link> / interview report
        </span>
      </div>
      <div style={{ margin: "2%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            style={{
              width: "fit-content",
              alignSelf: "right",
              backgroundColor: "#012970",
              color: "white",

              margin: "1% 0",
            }}
            onClick={handleDownloadPDF}
          >
            Download Report
          </Button>
        </div>
        <div
          id="report"
          style={{ margin: "2% auto", gap: "20px" }}
          className="fc"
        >
          {reportData.map((item, index) => {
            return (
              <div className="report-div">
                <AccordionSummary
                  className="ic-report-item-heading"
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div id={`accordion-${index}`} style={{ fontWeight: "600" }}>
                    {item.question}{" "}
                  </div>
                </AccordionSummary>
                <hr className="IcInterviewreport-hr" />
                <div className="report-response fc">
                  <div className="fr report-item-div">
                    <div className="mini-heading"> User Response:</div>
                    <span className="report-item">{item.userResponse}</span>
                  </div>

                  <div className="fr report-item-div">
                    <div className="mini-heading">Feedback:</div>
                    <span className="report-item"> {item.feedback}</span>
                  </div>

                  <div className="fr report-item-div">
                    <div className="mini-heading">Sample Response:</div>{" "}
                    <span className="report-item">{item.jobroResponse}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            style={{
              width: "fit-content",
              alignSelf: "right",
              backgroundColor: "#012970",
              color: "white",

              margin: "1% 0",
            }}
            onClick={handleDownloadPDF}
          >
            Download Report
          </Button>
        </div>
      </div>
    </div>
  );
};

export default IcAIResponsereport;
