import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CvBuilder from "./CvBuilder";

const AddJD = () => {
  const [jobdescription, setJD] = useState("");
  const [jdAdded, setJDAdded] = useState(false);
  return (
    <div>
      {jdAdded === true ? (
        <CvBuilder jobdescription={jobdescription} />
      ) : (
        <div className="page-container">
          <div className="fc page-top">
            <span className="page-heading"> CV Builder</span>
            <span className="page-top-item2">
              <Link to="/">Home</Link> / <Link to="cvList">Create CV </Link>/
              Add JD
            </span>
          </div>
          <div>
            <TextField
              required
              id="outlined-basic"
              variant="outlined"
              sx={{}}
              name="Job Description"
              label="Job Description"
              value={jobdescription}
              onChange={(e) => {
                setJD(e.target.value);
              }}
            />

            <Button
              onClick={() => {
                setJDAdded(true);
              }}
              disabled={!jobdescription || jobdescription.length < 10}
            >
              Create Cv
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddJD;
