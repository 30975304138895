import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/masked.css";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const Masked = () => {
  return (
    <div className="fc page-container">
      <div className="fc main-header-div">
        <span className="page-heading">Happily Unmasked</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> /{" "}
          <Link to="/happilyunmasked">happily unmasked</Link>
        </span>
      </div>
      <div className="fc Unmasked-main-div">
        <Link to="/SelfAssessmentModules" style={{ width: "80%" }}>
          <Button fullWidth variant="outlined">
            Self Assessment Modules{" "}
            <OpenInNewIcon sx={{ marginLeft: "10px" }} />
          </Button>
        </Link>
        <Link to="/ReasonableAccomodation" style={{ width: "80%" }}>
          <Button fullWidth variant="outlined">
            Reasonable Accomodation{" "}
            <OpenInNewIcon sx={{ marginLeft: "10px" }} />
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Masked;
