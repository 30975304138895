import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../../assets/css/ChallenegsInmasked.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import axios from "axios";
import { useSelector } from "react-redux";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const ReasonableAccomodationPitchGenerate = () => {
  const location = useLocation();
  const { UserRes } = location.state;
  // console.log(UserRes);
  const baseURL = "https://jobro.orchvate.com/api/v1/";
  const [emailPitch, setEmailPitch] = useState("");
  const [verbalPitch, setVerbalPitch] = useState("");
  const [emailCopySuccess, setEmailCopySuccess] = useState("");
  const [verbalCopySuccess, setVerbalCopySuccess] = useState("");
  const [emailLoading, setEmailoading] = useState(true);
  const [verbalLoading, setVerbalLoading] = useState(true);
  // const baseUrl = "https://jobrobackendcv.azurewebsites.net/api/";
  // const baseUrl = "http://localhost:8000/api/";
  const baseUrl = "https://jobrobackendcv.azurewebsites.net/api/";

  const user_id = useSelector((state) => {
    return state.user.user_id;
  });

  useEffect(() => {
    console.log("UserRes_Pitch", UserRes);
    const callApi = async () => {
      setEmailoading(true);
      setVerbalLoading(true);
      try {
        const data1 = {
          button_id: "button_89",
        };
        const response = await axios.post(`${baseURL}fetch_prompt`, data1);

        let prompt = response.data.prompt;
        const userResString = UserRes.map((item, index) => {
          console.log("acc:", item.Accomodations);
          const accommodations = item.Accomodations.join(", ");

          return `${index + 1}. Challenge: ${
            item.challenge
          }\n   Accommodations: ${accommodations}`;
        }).join("\n\n");
        let newPrompt = prompt.replace(
          /\[\[Challenges_Accomadations\]\]/g,
          userResString
        );

        const res = await axios.post(`${baseURL}get_ai_response`, {
          prompt_message: newPrompt,
        });

        setEmailPitch(res.data.ai_response);
        const data2 = {
          button_id: "button_90",
        };
        const response2 = await axios.post(`${baseURL}fetch_prompt`, data2);

        let prompt2 = response2.data.prompt;

        let newPrompt2 = prompt2.replace(
          /\[\[Challenges_Accomadations\]\]/g,
          userResString
        );

        // console.log("This is new prompt", newPrompt2);
        const res2 = await axios.post(`${baseURL}get_ai_response`, {
          prompt_message: newPrompt2,
        });

        setVerbalPitch(res2.data.ai_response);
        // console.log("ppp", newResponse);

        // console.log("data", UserRes);
        const newResponse = {
          User_id: user_id,
          ReasonableAccomodation: {
            UserRes: UserRes,
            Ai_response: {
              pitch: res2.data.ai_response,
              email: res.data.ai_response,
            },
          },
        };
        const res3 = await axios.post(
          `${baseUrl}saveUserResponse`,
          newResponse
        );
        // console.log(res3.data.message);
      } catch (error) {
        console.log(error);
      } finally {
        setEmailoading(false);
        setVerbalLoading(false);
      }
    };

    callApi();
  }, []);

  const handleDownloadEmailasPDF = () => {
    const input = document.getElementById("email");
    const pdf = new jsPDF("portrait", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const margin = 10;
    const textWidth = pageWidth - margin * 2;
    const text = input.value;

    const lines = pdf.splitTextToSize(text, textWidth);
    let y = 20;
    for (let i = 0; i < lines.length; i++) {
      if (y > 270) {
        // Check if page limit reached, add new page if needed
        pdf.addPage();
        y = 20;
      }
      pdf.text(margin, y, lines[i]);
      y += 10; // Line height
    }

    pdf.save("email_pitch.pdf");
  };

  const handleDownloadVerbalasPDF = () => {
    const input = document.getElementById("verbal");
    const pdf = new jsPDF("portrait", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const margin = 10;
    const textWidth = pageWidth - margin * 2;
    const text = input.value;

    const lines = pdf.splitTextToSize(text, textWidth);
    let y = 20;
    for (let i = 0; i < lines.length; i++) {
      if (y > 270) {
        // Check if page limit reached, add new page if needed
        pdf.addPage();
        y = 20;
      }
      pdf.text(margin, y, lines[i]);
      y += 10; // Line height
    }

    pdf.save("verbal_pitch.pdf");
  };

  return (
    <div className="fc page-container">
      <div className="fc main-header-div">
        <span className="page-heading"> Pitch Generation</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> /{" "}
          <Link to="/happilyunmasked">happily unmasked</Link> / pitch generation
        </span>
      </div>
      <div className="fc ptchGeneration-main-divi">
        <span>
          <strong style={{ fontSize: "1.5rem" }}>JoBro Response:</strong>
        </span>
        <div className="fc" id="ptchGeneration-container-divi">
          <div>
            <div>Email Pitch:</div>{" "}
            {emailLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <div>
                <TextField
                  id="email"
                  value={emailPitch}
                  fullWidth
                  rows={10}
                  multiline
                />
                <div className="fr res-button-div">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigator.clipboard.writeText(emailPitch);
                    }}
                  >
                    <ContentCopyIcon />
                  </Button>
                  <Button variant="outlined" onClick={handleDownloadEmailasPDF}>
                    <FileDownloadIcon />
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div>
            <div>Verbal Pitch:</div>
            {verbalLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <div>
                <TextField
                  id="verbal"
                  value={verbalPitch}
                  fullWidth
                  rows={10}
                  multiline
                />
                <div className="fr res-button-div">
                  {" "}
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigator.clipboard.writeText(emailPitch);
                    }}
                  >
                    <ContentCopyIcon />
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleDownloadVerbalasPDF}
                  >
                    <FileDownloadIcon />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            padding: " 0 3%",
            fontFamily: "Cutive Mono, monospace",
          }}
        >
          Disclaimer Remember that while AI can assist in various tasks, its
          performance is contingent on the quality of the data it interacts
          with. Taking steps to ensure data accuracy is essential for obtaining
          reliable and meaningful results. Thank you for your understanding and
          commitment to using AI responsibly. Remember that while AI can assist
          in various tasks, its performance is contingent on the quality of the
          data it interacts with. Taking steps to ensure data accuracy is
          essential for obtaining reliable and meaningful results. Thank you for
          your understanding and commitment to using AI responsibly.
        </div>
      </div>
    </div>
  );
};

export default ReasonableAccomodationPitchGenerate;
