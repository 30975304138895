import { Button, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";

const DescWorkExp = ({
  experience,

  workExperiences,
  setWorkExperiences,
  handleWorkInputChange,
  jobdescription,
}) => {
  const [dummyDesc, setDummyDesc] = useState(experience.description);
  const [joBroRes, setJoBroRes] = useState("");
  const [generatejoBroRes, setGeneratejoBroRes] = useState(false);
  const [checked, setChecked] = useState(false);
  const [regenerate, setRegenerate] = useState(false);
  const [generating, setGenerating] = useState(false);

  const baseURL = "https://jobro.orchvate.com/api/v1/";
  const handleGenerateJoBroRes = async () => {
    setGenerating(true);

    try {
      let data;
      if (jobdescription) {
        data = {
          button_id: "button_81",
        };
      } else {
        data = {
          button_id: "button_78",
        };
      }
      const response = await axios.post(`${baseURL}fetch_prompt`, data);

      let prompt = response.data.prompt;

      let newPrompt = prompt.replace(
        /\[\[WorkExpDesc\]\]/g,
        `I was employed at ${experience.employer} as a ${experience.role}. My description -- ${experience.description}`
      );
      if (jobdescription) {
        newPrompt = newPrompt.replace(
          /\[\[JobDescription\]\]/g,
          jobdescription
        );
      }

      const res = await axios.post(`${baseURL}get_ai_response`, {
        prompt_message: newPrompt,
      });

      setJoBroRes(res.data.ai_response);
    } catch (error) {
      console.log(error);
    } finally {
      setGeneratejoBroRes(true);
      setRegenerate(true);
      setGenerating(false);
    }
  };
  return (
    <div className="fc" style={{ gap: "1rem" }}>
      <TextField
        fullWidth
        disabled={checked}
        value={dummyDesc}
        onChange={(e) => {
          setDummyDesc(e.target.value);
          handleWorkInputChange(experience.id, "description", e.target.value);
        }}
        id="outlined-basic"
        required
        multiline
        rows={4}
        label="Description"
        variant="outlined"
      />

      {generatejoBroRes ? (
        <label className="fr">
          <input
            type="checkbox"
            value={joBroRes}
            checked={checked}
            onClick={() => {
              setChecked(!checked);
              handleWorkInputChange(
                experience.id,
                "description",
                checked ? dummyDesc : joBroRes
              );
            }}
          />
          <TextField
            disabled={!checked}
            sx={{ width: "100%" }}
            value={joBroRes}
            id="outlined-multiline-static"
            required
            multiline
            rows={4}
            label="Jo Bro Response"
            variant="outlined"
            onChange={(e) => {
              setJoBroRes(e.target.value);
              handleWorkInputChange(
                experience.id,
                "description",
                e.target.value
              );
            }}
          />
        </label>
      ) : (
        <div style={{ textAlign: "center" }}>
          {generating ? (
            <CircularProgress />
          ) : (
            <Button
              variant="outlined"
              disabled={
                !experience.description || experience.description.length < 10
              }
              sx={{
                color: "#012970",
                width: "fit-content",
                alignSelf: "center",
                borderColor: "#012970",
                "&:hover": {
                  color: "#4154f1",
                  borderColor: "#4154f1",
                },
              }}
              onClick={handleGenerateJoBroRes}
            >
              GENERATE JOBRO RESPONSE
            </Button>
          )}
        </div>
      )}
      {regenerate && (
        <div style={{ textAlign: "center" }}>
          {" "}
          {generating ? (
            <CircularProgress />
          ) : (
            <Button
              sx={{
                width: "fit-content",
                height: "fit-content",
                alignSelf: "center",
              }}
              onClick={handleGenerateJoBroRes}
            >
              REGENERATE RESPONSE
            </Button>
          )}
        </div>
      )}
      {/* </div> */}
    </div>
  );
};

export default DescWorkExp;
