import React, { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import {
  DatePicker,
  DatePickerToolbar,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { updateUser } from "../../store/action.js";

const profileMap = {
  img: "Image",
  first_name: "First Name",
  last_name: "Last Name",
  field_of_study: "Field Of Study",
  date_of_birth: "Date of Birth",
  gender: "Gender",
  max_qualification: "Max Qualification",
  years_work_exp: "Years of Experience",
  contact: "Contact number",
  email: "Email",
  role: "Role",
  twitter: "Twitter",
  facebook: "Facebook",
  instagram: "Instagram",
  linkedIn: "LinkedIN",
  about: "About",
  youtube: "YouTube",
};
const UserInfoEdit = ({ baseUrl }) => {
  const userData = useSelector((state) => {
    return state.user.userData;
  });
  const user_id = useSelector((state) => {
    return state.user.user_id;
  });
  const [copyUser, setCopyUser] = useState(userData);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  const handleEditChange = (e) => {
    const { id, value } = e.target.value;
    setCopyUser((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const handleDate = (e) => {
    // console.log("hi", e);
    let date = e.$d.toString();
    setCopyUser((prevData) => ({
      ...prevData,
      date_of_birth: date.slice(0, 10),
    }));
  };
  const SaveChanges = async (e) => {
    setIsSaving(true);
    e.preventDefault();
    try {
      // console.log("dataFinal", copyUser);
      // setCopyUser({ ...copyUser, date_of_birth: "Wed, 5 Mar 2020" });
      // setCopyUser({ ...copyUser, contact: Number(copyUser.contact) });

      console.log(copyUser);
      const response = await axios.post(
        `${baseUrl}/api/v1/update_user_profile`,
        copyUser
      );
      console.log(response);
      const newUser = await axios.get(
        `${baseUrl}/api/v1/retrieve_user_profile/${user_id}`
      );
      const temp = newUser.data;
      console.log(newUser.data);
      dispatch(updateUser(temp));

      // setUser(response.data);

      toast.success("Data changed Successfullly");
    } catch (error) {
      console.error("Error retrieving user profile", error);
      toast.error("Oops, something went wrong!");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="fc userPage_edit">
      {Object.entries(userData).map(([key, value]) =>
        key === "img" ? (
          <div className="userpage-ep-details" key={key}>
            <span className="userpage-ep-key fc">Profile Image</span>
            <div className="fc" style={{ marginLeft: "-5%" }}>
              <img
                src={value}
                alt="user"
                style={{ width: "150px", marginBottom: "5%" }}
              />
              <div className="fr">
                <input
                  type="file"
                  accept="image/*"
                  id="file-input"
                  style={{ display: "none" }}
                />
                <label htmlFor="file-input">
                  <UploadIcon sx={{ cursor: "pointer" }} />
                </label>
                <DeleteIcon />
              </div>
            </div>
          </div>
        ) : (
          key !== "user_id" && (
            <div className="userpage-ep-details">
              <span className="userpage-ep-key fc"> {profileMap[key]}</span>
              <div id="userpage-ep-value" className="fc">
                {key === "gender" && (
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">
                      Gender
                    </InputLabel> */}
                    <Select
                      // labelId="demo-simple-select-label"
                      // id="demo-simple-select"
                      value={copyUser[key]}
                      // label="Gender"
                      onChange={(e) => {
                        setCopyUser((prevData) => ({
                          ...prevData,
                          gender: e.target.value,
                        }));
                      }}
                    >
                      <MenuItem value={"male"}>Male</MenuItem>
                      <MenuItem value={"female"}>Female</MenuItem>
                      <MenuItem value={"others"}>Others</MenuItem>
                      <MenuItem value={"NotDisclosed"}>
                        Do not wish to disclose.
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                {key === "date_of_birth" && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label=""
                      // value={copyUser[key].splice(0, 10)}
                      onChange={handleDate}
                    />
                  </LocalizationProvider>
                )}
                {key !== "gender" && key !== "date_of_birth" && (
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    fullWidth
                    size="small"
                    key={key}
                    defaultValue={copyUser[key]}
                    onChange={(e) => {
                      setCopyUser((prevData) => ({
                        ...prevData,
                        [key]: e.target.value,
                      }));
                    }}
                  />
                )}
              </div>
            </div>
          )
        )
      )}
      <Button
        onClick={SaveChanges}
        sx={{ width: "fit-content" }}
        variant="contained"
      >
        {isSaving ? (
          <Box
            sx={{
              display: "flex",
              padding: "1%",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          "SAVE CHANGES"
        )}
      </Button>
    </div>
  );
};

export default UserInfoEdit;
