import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/KnowledgeBase.css";
import { Button, Divider } from "@mui/material";
import KbButton from "./Kb-Button";
const KnowledgeBase = () => {
  return (
    <div className="fc  page-container">
      <div className="fc main-header-div ">
        <span className="page-heading"> Knowledge Base</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> / knowledge base
        </span>
      </div>
      {/* <Divider className="page-divider" /> */}

      <div
        className="fc"
        style={{ alignItems: "Center", width: "50%", margin: "3% auto" }}
      >
        <KbButton label="Course Recommendation" link="/CourseSuggestion" />
        <KbButton
          label="Question for Interviewer"
          link="/QuestionForInterviewer"
        />

        <KbButton label="Course Recommendation" link="/CourseSuggestion" />
      </div>
    </div>
  );
};

export default KnowledgeBase;
