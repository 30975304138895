import React from "react";
import Challenges from "./Challenges";

const ReasAccPage = ({
  DataDisplay,
  userRes,
  setUserRes,
  setCurrIndex,
  currIndex,
  formFilled,
}) => {
  return (
    <div
      className="fr"
      style={{
        width: "70%",
        // height: "68vh",
        minHeight: "68vh",
        maxHeight: "68vh",

        justifyContent: "center",
        alignItems: "center",
        margin: "2% auto",
      }}
    >
      {DataDisplay && DataDisplay.ReasonablAccomodation ? (
        DataDisplay.ReasonablAccomodation.map((item, index) => {
          if (index === currIndex) {
            return (
              <Challenges
                item={item}
                key={index}
                userRes={userRes}
                setUserRes={setUserRes}
                currIndex={currIndex}
                setCurrIndex={setCurrIndex}
                isLast={
                  currIndex === DataDisplay.ReasonablAccomodation.length - 1
                }
                formFilled={formFilled}
              />
            );
          }
        })
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ReasAccPage;
