import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import WorkExperiencePage from "./WorkExperiencePage";
import EducationPage from "./CVeducation";
import Certification from "./Certification";
import Achievements from "./Achievements";
import toast, { Toaster } from "react-hot-toast";
import AIGenAbout from "./AIGenAbout";
import { updateCv } from "../../store/action";
import Hobbies from "./CV Components/Hobbies";

import Languages from "./CV Components/Languages";
import EditIcon from "@mui/icons-material/Edit";
function getStyles(name, personName) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? "normal" : "400",
    color: personName.indexOf(name) === -1 ? "grey" : "black",
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const CVedit = ({ baseUrl }) => {
  const { cv_id } = useParams();
  const [copyCV, setCopyCV] = useState({});
  const [skillList, setSkillList] = useState([]);
  const [technicalSkills, setTechnicalSkills] = useState([]);
  const [saving, setsaving] = useState(false);

  const navigate = useNavigate();
  // const DummyUrl = "http://localhost:8000/api";
  const cvBaseUrl = "https://jobrobackendcv.azurewebsites.net/api/";
  // const cvBaseUrl = "http://localhost:8000/api/";

  const user_id = useSelector((state) => {
    return state.user.user_id;
  });

  const [about, setAbout] = useState("");
  const [finalAbout, setFinalAbout] = useState("");
  const handleSkillsChange = (event) => {
    const {
      target: { value },
    } = event;
    setTechnicalSkills(typeof value === "string" ? value.split(",") : value);
  };

  //work Experience
  const [workExperiences, setWorkExperiences] = useState([
    {
      id: Date.now().toString(36),
      startDate: "",
      endDate: "",
      employer: "",
      role: "",
      description: "",
      currentlyWorking: false,
    },
  ]);
  const handleWorkExperienceChange = (newWorkExperiences) => {
    setWorkExperiences(newWorkExperiences);
  };

  const addWorkExperience = () => {
    setWorkExperiences((prevExperiences) => [
      ...prevExperiences,
      {
        id: Date.now().toString(36),
        startDate: "",
        endDate: "",
        employer: "",
        role: "",
        description: "",
        currentlyWorking: false,
      },
    ]);
  };

  const handleWorkInputChange = (id, field, value) => {
    setWorkExperiences((prevExperiences) =>
      prevExperiences.map((experience) =>
        experience.id === id ? { ...experience, [field]: value } : experience
      )
    );
  };
  const deleteWorkExperience = (id) => {
    setWorkExperiences((prevExperiences) =>
      prevExperiences.filter((experience) => experience.id !== id)
    );
  };
  //Education
  const [educations, setEducations] = useState([
    {
      id: Date.now().toString(36),

      startDate: null,
      endDate: null,
      institution: "",
      degree: "",
      currentlyEnrolled: false,
    },
  ]);
  const handleEducationChange = (newEducations) => {
    setEducations(newEducations);
  };

  const addEducation = () => {
    setEducations((prevEducations) => [
      ...prevEducations,
      {
        id: Date.now().toString(36),
        startDate: "",
        endDate: "",
        institution: "",
        degree: "",
        currentlyEnrolled: false,
      },
    ]);
  };

  const handleEducationInputChange = (id, field, value) => {
    setEducations((prevEducations) =>
      prevEducations.map((education) =>
        education.id === id ? { ...education, [field]: value } : education
      )
    );
  };

  const deleteEducation = (id) => {
    setEducations((prevEducations) =>
      prevEducations.filter((education) => education.id !== id)
    );
  };

  //Certification
  const [certifications, setCertifications] = useState([
    {
      id: Date.now().toString(36),
      startDate: null,
      endDate: null,
      title: "",
      link: "",
    },
  ]);
  const handleCertificationChange = (newCertificates) => {
    setCertifications(newCertificates);
  };

  const handleCertificateInputChange = (id, field, value) => {
    setCertifications((prevCert) =>
      prevCert.map((certt) =>
        certt.id === id ? { ...certt, [field]: value } : certt
      )
    );
  };
  const handleAddCertification = () => {
    setCertifications((prevCert) => [
      ...prevCert,
      {
        id: Date.now().toString(36),
        startDate: "",
        endDate: "",
        title: "",
        link: "",
      },
    ]);
  };

  const handleRemoveCertification = (id) => {
    setCertifications((prevCertifications) =>
      prevCertifications.filter((cert) => cert.id !== id)
    );
  };

  //Achievement
  const [achievements, setAchievements] = useState([]);
  const handleAchievementChange = (index, newach) => {
    const updatedAchievements = [...achievements];
    updatedAchievements[index] = newach;
    setAchievements(updatedAchievements);
  };
  const handleAddAchievement = () => {
    setAchievements([...achievements, ""]);
  };

  const handleRemoveAchievement = (index) => {
    const updatedAchievements = [...achievements];
    updatedAchievements.splice(index, 1);
    setAchievements(updatedAchievements);
  };
  //JD
  const [jobdescription, setJobdescription] = useState("");
  //Role
  const [role, setRole] = useState("");
  //Hobbies
  const [hobbies, setHobbies] = useState([]);
  const handleHobbiesChange = (event) => {
    const {
      target: { value },
    } = event;
    setHobbies(typeof value === "string" ? value.split(",") : value);
  };

  //Language
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const handleLanguageChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLanguages(typeof value === "string" ? value.split(",") : value);
  };

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    const callApi = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/v1/get_skills`);
        // console.log("THIS IS CV BUILDER", response.data);

        setSkillList(response.data);

        const res = await axios.get(
          `${cvBaseUrl}CvDetails/${user_id}/${cv_id}`
        );
        console.log("CV Data edit page", res);

        setCopyCV(res.data.Cv);
        setTechnicalSkills(res.data.Cv.skills);
        setWorkExperiences(res.data.Cv.work_experience);
        setEducations(res.data.Cv.education);
        setCertifications(res.data.Cv.certifications);
        setAchievements(res.data.Cv.achievements);
        setJobdescription(res.data.Cv.jobdescription);
        setAbout(res.data.Cv.about);
        setHobbies(res.data.Cv.hobbies);
        setSelectedLanguages(res.data.Cv.languages);
        setFinalAbout(res.data.Cv.about);
        setRole(res.data.Cv.role);
      } catch (error) {
        console.log("kkkkkkk", error);
      } finally {
        setLoading(false);
      }
    };
    callApi();
  }, []);
  useEffect(() => {
    setCopyCV({
      ...copyCV,
      skills: technicalSkills,
      work_experience: workExperiences,
      education: educations,
      certifications: certifications,
      achievements: achievements,
      jobdescription: jobdescription,
      about: finalAbout,
      hobbies: hobbies,
      languages: selectedLanguages,
      role: role,
    });
  }, [
    copyCV,
    technicalSkills,
    workExperiences,
    educations,
    certifications,
    achievements,
    jobdescription,
    finalAbout,
    hobbies,
    selectedLanguages,
    role,
  ]);
  const dispatch = useDispatch();
  const deleteCV = async () => {
    setDeleting(true);
    try {
      const userId = user_id.toString();

      const res = await axios.delete(`${cvBaseUrl}deleteCv/${userId}/${cv_id}`);
      // console.log("ji", res);
      if (res.data.success == true) {
        toast.success("Cv Deleted successfully");
        // setTimeout(() => {
        navigate("/cvList");

        // setTimeout(() => {
        navigate("/cvList");
        const res2 = await axios.get(`${cvBaseUrl}getUserCV/${user_id}`);

        if (res2.data.success === true) {
          dispatch(updateCv(res2.data.Cv));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleting(false);
    }
  };
  const saveCV = async () => {
    setsaving(true);
    try {
      // console.log("faedit", finalAbout);
      setCopyCV({
        ...copyCV,
        skills: technicalSkills,
        work_experience: workExperiences,
        education: educations,
        certifications: certifications,
        achievements: achievements,
        jobdescription: jobdescription,
        about: finalAbout,
        hobbies: hobbies,
        languages: selectedLanguages,
        role: role,
      });
      // console.log("CIOPIYVv", copyCV);
      const res = await axios.put(
        `${cvBaseUrl}updateCv/${user_id}/${cv_id}`,
        copyCV
      );
      // console.log(res);
      if (res.data.success === true) {
        toast.success("Data Saved Succesfully!");
        navigate("/cvList");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setsaving(false);
    }
  };

  return (
    <div className="page-container">
      <Toaster />
      <div className="fc main-header-div">
        <span className="page-heading">
          <EditIcon fontSize="1rem" /> {copyCV.name}{" "}
        </span>
        <span className="page-top-item2">
          <Link to="/">home</Link> / <Link to="/cvList">cv assistant</Link> /{" "}
          <Link to={`/cv/${copyCV._id}`}>{copyCV.name}</Link> /{" "}
          <EditIcon fontSize="1rem" /> {copyCV.name}
        </span>
      </div>
      {/* <div> */}
      <div className="cv-info-container">
        <div
          style={{
            margin: "5% ",
          }}
        >
          <h1>Skills</h1>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <FormControl
              sx={{ marginTop: "", marginBottom: "-2.5%" }}
              fullWidth
            >
              {/* <InputLabel id="demo-multiple-chip-label">Skills</InputLabel> */}
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={technicalSkills}
                onChange={handleSkillsChange}
                input={<OutlinedInput id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {skillList.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, technicalSkills)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        <WorkExperiencePage
          onChange={handleWorkExperienceChange}
          workExperiences={workExperiences}
          setWorkExperiences={setWorkExperiences}
          handleWorkInputChange={handleWorkInputChange}
          addWorkExperience={addWorkExperience}
          deleteWorkExperience={deleteWorkExperience}
          jobdescription={jobdescription}
          loading={loading}
        />
        <EducationPage
          onChange={handleEducationChange}
          educations={educations}
          setEducations={setEducations}
          handleeducationInputChange={handleEducationInputChange}
          deleteEducation={deleteEducation}
          addEducation={addEducation}
          loading={loading}
        />
        <Certification
          onChange={handleCertificationChange}
          certifications={certifications}
          setCertifications={setCertifications}
          handleCertificateInputChange={handleCertificateInputChange}
          handleAddCertification={handleAddCertification}
          handleRemoveCertification={handleRemoveCertification}
          loading={loading}
        />
        <Achievements
          achievements={achievements}
          handleAddAchievement={handleAddAchievement}
          handleRemoveAchievement={handleRemoveAchievement}
          handleAchievementChange={handleAchievementChange}
          jobdescription={jobdescription}
          loading={loading}
        />
        <AIGenAbout
          loading={loading}
          about={about}
          setAbout={setAbout}
          jobdescription={jobdescription}
          finalAbout={finalAbout}
          setFinalAbout={setFinalAbout}
        />
        <Hobbies
          hobbies={hobbies}
          setHobbies={setHobbies}
          handleHobbiesChange={handleHobbiesChange}
        />
        <Languages
          selectedLanguages={selectedLanguages}
          setSelectedLanguages={setSelectedLanguages}
          handleLanguageChange={handleLanguageChange}
        />
        <div
          className="fr"
          style={{
            margin: "5%",
            justifyContent: "space-between",
            paddingBottom: "2%",
          }}
        >
          <Button
            onClick={deleteCV}
            style={{
              backgroundColor: "#012970",
            }}
            variant="contained"
          >
            {deleting ? <CircularProgress /> : "Delete CV"}
          </Button>
          {/* <Button
                style={{ width: "fit-content", backgroundColor: "#012970" }}
                variant="contained"
                onClick={deleteCV}
              >
                Delete CV
              </Button> */}
          <Button
            disabled={saving}
            style={{ width: "fit-content", backgroundColor: "#012970" }}
            variant="contained"
            onClick={saveCV}
          >
            {saving ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <CircularProgress color="inherit" sx={{}} size="small" />
              </Box>
            ) : (
              "Save Details"
            )}
          </Button>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default CVedit;
