import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Box, Button, CircularProgress } from "@mui/material";
import { Page, Text, View } from "@react-pdf/renderer";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import dayjs from "dayjs";
import toast, { Toaster } from "react-hot-toast";
import { updateCv } from "../../store/action";
import Template1 from "./CVTemplates/Template1";
import Template2 from "./CVTemplates/Template2";
import Template3 from "./CVTemplates/Template3";

const templatesList = {
  Template1: Template1,
  Template2: Template2,
  Template3: Template3,
};
/*
  Check if the URL already contains a protocol. 
  If yes then render it as it is. Otherwise append "https://".
*/
function addProtocol(url) {
  // Check if the URL already contains a protocol
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    // If not, prepend "https://"
    return "https://" + url;
  }
  // Otherwise, return the original URL
  return url;
}
const SingleCV = ({ template }) => {
  // const DummyUrl = "http://localhost:8000/api/";
  // const cvBaseUrl = "http://localhost:8000/api/";
  const cvBaseUrl = " https://jobrobackendcv.azurewebsites.net/api/";

  const { cv_id } = useParams();
  const [Cvdata, setCVData] = useState({});
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const user_id = useSelector((state) => {
    return state.user.user_id;
  });
  const userData = useSelector((state) => {
    return state.user.userData;
  });
  const TemplateCom = templatesList[template];

  // const [TemplateCom, setTemplateCom] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const retrieveData = async () => {
      setLoading(true);
      try {
        const userId = user_id.toString();
        const res = await axios.get(`${cvBaseUrl}CvDetails/${userId}/${cv_id}`);
        // console.log(res.data.Cv);
        // console.log(userData);
        setCVData(res.data.Cv);

        // setTemplateCom(templatesList[res.data.Cv.template]);
        console.log(res.data.Cv);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    retrieveData();
  }, []);
  const dispatch = useDispatch();
  const handleDelete = useCallback(async () => {
    setDeleting(true);
    try {
      const userId = user_id.toString();

      const res = await axios.delete(`${cvBaseUrl}deleteCv/${userId}/${cv_id}`);
      // console.log("ji", res);
      if (res.data.success === true) {
        toast.success("Cv Deleted successfully");
        // setTimeout(() => {
        const res2 = await axios.get(`${cvBaseUrl}getUserCV/${user_id}`);

        if (res2.data.success === true) {
          dispatch(updateCv(res2.data.Cv));
        }
        navigate("/cvList");
        // }, );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleting(false);
    }
  }, []);
  const handleDownloadPDF = () => {
    const input = document.getElementById("resume");

    html2canvas(input, {
      useCORS: true, // If fetching external resources
      logging: false, // Adjust for debugging purposes
      scale: 2, // Adjust for higher resolution (optional)
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("portrait", "mm", "a4"); // Set PDF size and orientation
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save(Cvdata.name);
      })
      .catch((error) => {
        console.error("Error converting to PDF:", error);
      });
  };

  return (
    <div className="fc page-container">
      <div className="fc main-header-div">
        <span className="page-heading"> {Cvdata.name}</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> / <Link to="/cvList">cv assistant</Link> /{" "}
          {Cvdata.name}
        </span>
      </div>
      <Toaster />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            // height: "50vh",
            // width: "40vw",
            // width: "fit-content",
            // height: "fit-content",
            padding: "100px",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div
          style={{
            // border: "1px solid black",
            margin: "5% auto",
            maxWidth: "1500px",
            justifySelf: "center",
          }}
        >
          {" "}
          <Page size="A4">
            <View>
              <Text>
                {
                  {
                    Template1: <Template1 Cvdata={Cvdata} />,
                    Template2: <Template2 Cvdata={Cvdata} />,
                    Template3: <Template3 Cvdata={Cvdata} />,
                  }[Cvdata.template]
                }
              </Text>
            </View>
          </Page>
          <div
            className="fr"
            style={{
              margin: "auto",
              width: "75%",
              height: "fit-content",
              padding: "2% 0",

              alignItems: "center",
              justifyItems: "center",
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              style={{
                backgroundColor: "#012970",
              }}
              variant="contained"
              onClick={() => {
                navigate(`/EditCv/${cv_id}`);
                window.scrollTo(0, 0);
              }}
            >
              Edit CV
            </Button>

            <Button
              onClick={handleDelete}
              style={{
                backgroundColor: "#012970",
              }}
              variant="contained"
            >
              {deleting ? <CircularProgress /> : "Delete CV"}
            </Button>
            <Button
              style={{
                backgroundColor: "#012970",
              }}
              variant="contained"
              onClick={handleDownloadPDF}
            >
              Download CV
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleCV;
