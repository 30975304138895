import React, { useEffect, useState } from "react";
import "../../assets/css/userprof.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, CircularProgress, TextField } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import UserInfoEdit from "./UserInfoEdit";
import UserInfoDisplay from "./UserInfoDisplay";
import Settings from "./Settings";
import ChangePassword from "./ChangePassword";

const profileMap = {
  img: "Image",
  first_name: "First Name",
  last_name: "Last Name",
  field_of_study: "Field Of Study",
  date_of_birth: "Date of Birth",
  gender: "Gender",
  max_qualification: "Max Qualification",
  years_work_exp: "Years of Experience",
  phone: "Contact number",
  email: "Email",
  role: "Role",
  twitter: "Twitter",
  facebook: "Facebook",
  instagram: "Instagram",
  linkedIn: "LinkedIN",
  about: "About",
  youtube: "YouTube",
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const UserProfile = ({ baseUrl }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const userData = useSelector((state) => {
    return state.user.userData;
  });

  return (
    <div className="child UserPage-container fc ">
      <Toaster />
      <div id="userpage-item1" className="fc">
        <img src={userData.img} alt="profile" />
        <p>{userData.first_name + " " + userData.last_name}</p>
        <p>{userData.role}</p>
        <div>
          <a href={userData.twitter}>
            <TwitterIcon />
          </a>
          <a href={userData.facebook}>
            {" "}
            <FacebookIcon />{" "}
          </a>
          <a href={userData.instagram}>
            <InstagramIcon />
          </a>
          <a href={userData.linkedIn}>
            <LinkedInIcon />
          </a>
        </div>
      </div>
      <div id="userpage-item2">
        <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Edit Profile" {...a11yProps(1)} />
            <Tab label="Settings" {...a11yProps(2)} />
            <Tab label="Change Password" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <UserInfoDisplay data={userData} profileMap={profileMap} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <UserInfoEdit baseUrl={baseUrl} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Settings />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <ChangePassword />
        </CustomTabPanel>
      </div>
    </div>
  );
};

export default UserProfile;
