import React from "react";
import "../../assets/css/interviewcoach.css";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import { Button, Divider } from "@mui/material";
import { Link } from "react-router-dom";

const data = [
  {
    heading: "Typical Interview Questions",
    content:
      " Lorem ipsum is derived from the Latin dolorem ipsum roughly translated as pain itself.",
    link: "/interviewQuestions",
  },
  {
    heading: "AI Interview",
    content:
      " Lorem ipsum is derived from the Latin dolorem ipsum roughly translated as pain itself.",
    link: "/aiInterview",
  },
  {
    heading: "Optimizing Answers",
    content:
      " Lorem ipsum is derived from the Latin dolorem ipsum roughly translated as pain itself.",
    link: "/optimizingResponse",
  },
];
const InterviewCoach = () => {
  return (
    <div className="fc  page-container">
      <div className="fc main-header-div ">
        <span className="page-heading"> Interview Coach</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> / interview coach
        </span>
      </div>
      <div className="ic-boxes">
        {data.map((item, id) => {
          return (
            <div key={id} className="fr ic-boxes-item">
              <div className="ic-boxes-content">
                <div className="fr icboxes-icon">
                  <BorderColorIcon sx={{ fontSize: "1.5rem" }} />
                  <span style={{ fontSize: "1.3rem" }}>{item.heading}</span>
                </div>
                <p>{item.content}</p>
              </div>

              <Link to={item.link}>
                <Button variant="outlined">Get Started</Button>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InterviewCoach;
