import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import "../../../assets/css/SelfAssessment.css";
import React, { useState, useEffect } from "react";

const NdcharacteristicBlock = ({
  item,
  currIndex,
  setCurrIndex,
  isLast,
  handleSelectionChange,
  UserRes_ND_Characteristics,
  setUserRes_ND_Characteristics,
}) => {
  const OptionList = {
    "strongly agree": 1,
    agree: 2,
    "neither agree nor disagree": 3,
    disagree: 4,
    "strongly disagree": 5,
  };

  // Find the initial value for the current question
  const initialResponse = UserRes_ND_Characteristics.find(
    (response) => response.Description === item
  )?.Response;

  const [selectedValue, setSelectedValue] = useState(
    initialResponse !== undefined
      ? Object.keys(OptionList).find(
          (key) => OptionList[key] === initialResponse
        )
      : ""
  );

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const saveResponse = () => {
    const responseIndex = UserRes_ND_Characteristics.findIndex(
      (response) => response.Description === item
    );

    const updatedResponse = {
      SkillName: "Skill Name Placeholder", // Replace with actual skill name
      Description: item,
      Response: OptionList[selectedValue],
    };

    if (responseIndex > -1) {
      // Update existing response
      const updatedResponses = [...UserRes_ND_Characteristics];
      updatedResponses[responseIndex] = updatedResponse;
      setUserRes_ND_Characteristics(updatedResponses);
    } else {
      // Add new response
      setUserRes_ND_Characteristics([
        ...UserRes_ND_Characteristics,
        updatedResponse,
      ]);
    }
  };

  const handleNext = () => {
    saveResponse();
    setCurrIndex(currIndex + 1);
  };

  const handlePrevious = () => {
    saveResponse();
    setCurrIndex(currIndex - 1);
  };

  const SaveFinal = () => {
    saveResponse();
    handleSelectionChange("div2");
    // Handle final save logic, such as submitting the results
  };

  useEffect(() => {
    const initialResponse = UserRes_ND_Characteristics.find(
      (response) => response.Description === item
    )?.Response;

    if (initialResponse !== undefined) {
      setSelectedValue(
        Object.keys(OptionList).find(
          (key) => OptionList[key] === initialResponse
        )
      );
    } else {
      setSelectedValue("");
    }
  }, [currIndex, UserRes_ND_Characteristics, item]);

  return (
    <div>
      <div className="fc main-div-selfAssess">
        <div id="selfAssess-heading">{item}</div>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            sx={{ gap: "5px" }}
            className="fc"
            value={selectedValue}
            onChange={handleRadioChange}
          >
            {Object.keys(OptionList).map((optionItem, index) => (
              <FormControlLabel
                key={index}
                value={optionItem}
                control={<Radio />}
                label={optionItem}
                defaultChecked={optionItem === selectedValue}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <div className="fr" style={{ justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            onClick={handlePrevious}
            disabled={currIndex === 0}
          >
            Previous
          </Button>
          {isLast ? (
            <Button variant="outlined" onClick={SaveFinal}>
              Submit
            </Button>
          ) : (
            <Button variant="outlined" onClick={handleNext}>
              Next
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NdcharacteristicBlock;
