import React from "react";
import { Link } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ArticleIcon from "@mui/icons-material/Article";
import InterpreterModeOutlinedIcon from "@mui/icons-material/InterpreterModeOutlined";
import SentimentSatisfiedAltOutlinedIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import "../assets/css/home.css";
const Home = () => {
  // const sessionId = useSelector((state) => state.sessionId);
  // const user = useSelector((state) => state.auth.user);
  return (
    <div className="fc  page-container">
      <div className="fc main-header-div ">
        <span className="page-heading"> Home</span>
        <span className="page-top-item2">home</span>
      </div>
      <div className="container-home">
        {" "}
        <Link to="/guides" className="fc item-home">
          {" "}
          <EmojiPeopleOutlinedIcon
            style={{
              fontSize: "3rem",
            }}
          />
          Guides
        </Link>
        <Link className="fc item-home" to="/CareerOptions">
          <HelpOutlineIcon
            style={{
              fontSize: "3rem",
            }}
          />
          Career Options
        </Link>
        <Link className="fc item-home" to="/cvList">
          {" "}
          <ArticleIcon
            style={{
              fontSize: "3rem",
            }}
          />
          CV Assistant
        </Link>
        <Link className="fc item-home" to="/interviewCoach">
          <InterpreterModeOutlinedIcon
            style={{
              fontSize: "3rem",
            }}
          />
          Interview Coach
        </Link>
        <Link to="/help" className="fc item-home">
          <SentimentSatisfiedAltOutlinedIcon
            style={{
              fontSize: "3rem",
            }}
          />
          Help{" "}
        </Link>
        <Link to="/knowledgeBase" className="fc item-home">
          <TipsAndUpdatesOutlinedIcon
            style={{
              fontSize: "3rem",
            }}
          />
          Knowledge Base
        </Link>
      </div>
    </div>
  );
};

export default Home;
