import { CallSharp } from "@mui/icons-material";
import { Box, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
const baseURL = "https://jobro.orchvate.com/api/v1/";

const DisplayGenText = ({
  aiGenRes,
  setAiGenRes,
  button_id,
  strengths,
  weakness,
  loading,
}) => {
  const heading = {
    button_91: "Self Awareness CV Liner",
    button_92: "Self Awareness Interview Liner",
    button_93: "Self Awareness Strengths",
    button_94: "Self Awaress Weakness",
    button_95: "Self Awareness Email Pitch",
    button_96: "Self Awareness Verbal Pitch",
  };
  useEffect(() => {}, []);
  return (
    <div className="fc" style={{ margin: "4% 1%" }}>
      <span>{heading[button_id]}</span>
      {loading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <TextField value={aiGenRes} multiline rows={5} />
      )}
    </div>
  );
};

export default DisplayGenText;
