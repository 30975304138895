import { Box, Button, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const IcOptimizingResume = ({ baseUrl }) => {
  const [AIresponse, setAIResponse] = useState("");
  const [isResGenerated, setGenereated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [ques, setQues] = useState("");
  const [ans, setAns] = useState("");

  const generateResponse = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = {
        user_id: 0,
        question: ques,
        answer: ans,
      };

      const response = await axios.post(
        `${baseUrl}/api/v1/insert_optimise_answer`,
        data
      );
      // console.log("Optimize Answer API response:", response.data);
      setAIResponse(response.data.ai_response);
      setGenereated(true);
      // .then((response) => {
      //   console.log("Optimize Answer API response:", response.data);
      //   // Handle the response as needed
      // })
      // .catch((error) => {
      //   console.error("Error calling Optimize Answer API:", error);
      //   // Handle the error as needed
      // });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-container">
      <div className="fc main-header-div ">
        <span className="page-heading"> Optimizing Resume</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> /{" "}
          <Link to="/interviewCoach">interview coach</Link> / optimizing answers
        </span>
      </div>
      <div style={{ margin: "2%" }}>
        <div className=" fc icor-main">
          <h2>Enter your Question</h2>

          <TextField
            id="outlined-multiline-static"
            multiline
            fullWidth
            disabled={isResGenerated ? true : false}
            rows={5}
            value={ques}
            onChange={(e) => {
              setQues(e.target.value);
            }}
            // maxRows={10}
          />
          <h2>Enter your Answer</h2>

          <TextField
            id="outlined-multiline-static"
            multiline
            fullWidth
            disabled={isResGenerated ? true : false}
            value={ans}
            rows={10}
            onChange={(e) => {
              setAns(e.target.value);
            }}
            // maxRows={15}
          />
          <Button
            onClick={generateResponse}
            disabled={isResGenerated ? true : false}
            variant="contained"
          >
            Optimize With AI
          </Button>

          {isLoading && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          )}
          {isResGenerated && <h2>JoBro Response</h2>}
          {isResGenerated && (
            <TextField
              // id="outlined-multiline-static"
              multiline
              fullWidth
              sx={{ color: "black", fontWeight: "" }}
              // disabled={isResGenerated ? true : false}
              id="outlined-read-only-input"
              defaultValue={AIresponse}
              InputProps={{
                readOnly: true,
              }}
            />
          )}
        </div>

        <div
          style={{
            // marginTop: "2%",
            margin: "3% 0",
            textAlign: "center",
            fontFamily: "Cutive Mono, monospace",
          }}
        >
          Disclaimer Remember that while AI can assist in various tasks, its
          performance is contingent on the quality of the data it interacts
          with. Taking steps to ensure data accuracy is essential for obtaining
          reliable and meaningful results. Thank you for your understanding and
          commitment to using AI responsibly. Remember that while AI can assist
          in various tasks, its performance is contingent on the quality of the
          data it interacts with. Taking steps to ensure data accuracy is
          essential for obtaining reliable and meaningful results. Thank you for
          your understanding and commitment to using AI responsibly.
        </div>
      </div>
    </div>
  );
};

export default IcOptimizingResume;
