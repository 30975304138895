import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const hobbiesList = ["Painting", "Music", "Dance", "Art"];
const Hobbies = ({ hobbies, setHobbies, handleHobbiesChange }) => {
  function getStyles(name, personName) {
    if (!hobbies) return {};
    return {
      fontWeight: personName.indexOf(name) === -1 ? "normal" : "400",
      color: personName.indexOf(name) === -1 ? "grey" : "black",
    };
  }
  const baseUrl = "https://jobro.orchvate.com";

  const [hobbiesList, setHobbieslist] = useState([]);
  useEffect(() => {
    const callApi = async () => {
      try {
        const res = await axios.get(`${baseUrl}/api/v1/get_hobbies`);
        setHobbieslist(res.data);
        // console.log(res);
      } catch (error) {
        console.log(error);
      }
    };
    callApi();
  }, []);
  return (
    <div style={{ margin: "5%" }}>
      {" "}
      <h1>Hobbies</h1>
      <FormControl sx={{ marginTop: "0", marginBottom: "-2.5%" }} fullWidth>
        {/* <InputLabel id="demo-multiple-chip-label">Skills</InputLabel> */}
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={hobbies}
          onChange={handleHobbiesChange}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {hobbies &&
                hobbies.length > 0 &&
                selected.map((value) => <Chip key={value} label={value} />)}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {hobbiesList.map((name) => (
            <MenuItem key={name} value={name} style={getStyles(name, hobbies)}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Hobbies;
