import { useEffect, useState } from "react";

import { Link, Outlet } from "react-router-dom";

import "../assets/css/header.css";
import Footer from "./Footer";
import Header from "./header";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import InterpreterModeOutlinedIcon from "@mui/icons-material/InterpreterModeOutlined";
import SentimentSatisfiedAltOutlinedIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Login from "./Login";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { updateUser, updateUserId } from "../store/action";

const Layout = ({ baseUrl }) => {
  const [isMenu, setIsMenu] = useState(true);
  const handleClick = () => {
    setIsMenu((prevIsMenu) => !prevIsMenu);
  };
  const dispatch = useDispatch();

  // const isAuthenticated = false;

  const isAuth = useSelector((state) => {
    return state.user.isAuth;
    // return true;
  });

  // const isAuth = true;
  // dispatch(updateUserId("29"));

  const user_id = useSelector((state) => {
    return state.user.user_id;
  });
  // console.log("isAuth", isAuth);
  // console.log("homup", sessionId);
  // console.log("userid", user_id);

  useEffect(() => {
    if (isAuth && user_id) {
      const callApi = async () => {
        // console.log(user_id);
        try {
          const response = await axios.get(
            `${baseUrl}/api/v1/retrieve_user_profile/${user_id}`
          );
          const temp = response.data;
          dispatch(updateUser(temp));
          // console.log("Hi Home", user_id);
          // console.log("Hi Home", temp);
        } catch (error) {
          console.log(error);
        }
      };
      callApi();
    }
  }, [user_id]);
  return (
    <>
      {/* {console.log("user data", userData)} */}
      {!isAuth ? (
        <Login baseUrl={baseUrl} />
      ) : (
        <div>
          <Header
            handleClick={handleClick}
            isMenu={isMenu}
            setIsMenu={setIsMenu}
          />
          <div id="main" className="fr">
            <div
              id="sidebar"
              className="fc"
              style={{ width: isMenu ? "20%" : "0px" }}
            >
              <Link className="fr sidebar-item " to="/">
                {" "}
                <HomeOutlinedIcon /> Home
              </Link>
              <Link to="/guides" className="fr sidebar-item ">
                {" "}
                <EmojiPeopleOutlinedIcon />
                Guides
              </Link>
              <Link className="fr sidebar-item " to="/careerOptions">
                <InfoOutlinedIcon />
                Career Options
              </Link>
              <Link className="fr sidebar-item " to="/cvList">
                {" "}
                <ArticleOutlinedIcon />
                CV Assistant
              </Link>
              <Link className="fr sidebar-item " to="/interviewCoach">
                <InterpreterModeOutlinedIcon />
                Interview Coach
              </Link>
              <Link
                to="/help
              "
                className="fr sidebar-item "
              >
                <HelpOutlineIcon />
                Help{" "}
              </Link>
              <Link to="/knowledgeBase" className="fr sidebar-item ">
                <TipsAndUpdatesOutlinedIcon />
                Knowledge Base
              </Link>
              <Link to="/happilyunmasked" className="fr sidebar-item ">
                <SentimentSatisfiedAltOutlinedIcon />
                Happily Unmasked
              </Link>
            </div>
            <div
              id="body"
              style={{
                width: isMenu ? "80%" : "100%",

                marginLeft: isMenu ? "20%" : "0",
              }}
            >
              <Outlet />
              <Footer />
            </div>
            <Toaster />
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
