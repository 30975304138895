import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/KnowledgeBase.css";
import axios from "axios";
import CSResult from "./CSResult";
const CourseSuggestion = () => {
  const [areaOfInterest, setAreaOfInterest] = useState("");
  const [topics, setTopics] = useState("");
  const [certification, setCertification] = useState("");
  const [levelOfDifficulty, setLevelOfDifficulty] = useState("");
  const [proceed, setProceed] = useState(false);
  const [loading, setloading] = useState(false);
  // const dummyUrl = "http://localhost:8000/api";
  const dummyUrl = "https://jobrobackendcv.azurewebsites.net/api/";
  const baseUrl = "https://jobro.orchvate.com/api/v1/";
  const [JobroRes, setJobroRes] = useState("");
  const navigate = useNavigate();
  const handleProceed = async () => {
    try {
      setloading(true);
      const data = {
        areaOfInterest: areaOfInterest,
        levelOfDifficulty: levelOfDifficulty,
        specificTopics: topics,
        certificationNeeded: certification,
      };
      // console.log("F", data);
      const res = await axios.post(`${dummyUrl}/courseRecommendation`, data);
      const data2 = {
        button_id: "button_87",
      };

      const response = await axios.post(`${baseUrl}fetch_prompt`, data2);
      let prompt = response.data.prompt;
      console.log("LLLL", prompt);
      prompt = prompt.replace(/\[\[interests\]\]/g, areaOfInterest);
      prompt = prompt.replace(
        /\[\[Level_of_Difficulty\]\]/g,
        levelOfDifficulty
      );
      prompt = prompt.replace(/\[\[Specific_Topics\]\]/g, topics);
      prompt = prompt.replace(/\[\[Certification\]\]/g, certification);
      console.log("lll", prompt);
      const res2 = await axios.post(`${baseUrl}get_ai_response`, {
        prompt_message: prompt,
      });

      setJobroRes(res2.data.ai_response);
      setProceed(true);
      setloading(false);
      // console.log("res", res);
      // navigate("/RecommendedCourse");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="page-container">
      <div className="fc main-header-div">
        <span className="page-heading"> Course Recommendation</span>
        <span className="page-top-item2">
          <Link to="/">home</Link> /{" "}
          <Link to="/knowledgeBase">knowledge base</Link> / course
          recommendation
        </span>
      </div>
      {proceed ? (
        <CSResult JobroRes={JobroRes} />
      ) : (
        <div className="CS-input-container fc">
          <TextField
            required
            fullWidth
            id="outlined-required"
            label="Area of Interest"
            value={areaOfInterest}
            onChange={(e) => {
              setAreaOfInterest(e.target.value);
            }}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Level of Difficulty
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Level of Difficulty"
              value={levelOfDifficulty}
              onChange={(e) => {
                setLevelOfDifficulty(e.target.value);
              }}
            >
              <MenuItem value={"Beginner"}>Beginner </MenuItem>
              <MenuItem value={"Intermediate"}>Intermediate </MenuItem>

              <MenuItem value={"Advanced"}>Advanced</MenuItem>
            </Select>
          </FormControl>
          <TextField
            required
            fullWidth
            id="outlined-required"
            label="Specific Topics to Focus on"
            value={topics}
            onChange={(e) => {
              setTopics(e.target.value);
            }}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Is Certification Needed?
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Is Certification Needed?"
              value={certification}
              onChange={(e) => {
                setCertification(e.target.value);
              }}
            >
              <MenuItem value={"true"}>Yes</MenuItem>
              <MenuItem value={"false"}>No</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            onClick={handleProceed}
            sx={{
              width: "fit-content",
              padding: "1%",
              alignSelf: "center",
              backgroundColor: "#012970",
            }}
          >
            {" "}
            {loading ? <CircularProgress /> : "Proceed"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CourseSuggestion;
