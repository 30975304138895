import { Button, TextField } from "@mui/material";
import React from "react";

const ChangePassword = () => {
  return (
    <div
      className="fc"
      style={{
        alignItems: "center",
        width: "100%",
        padding: "1%",
        gap: "20px",
      }}
    >
      <div className="fr" style={{ alignItems: "center", width: "100%" }}>
        <span style={{ width: "30%" }}>Current Password</span>
        <TextField
          fullWidth
          size="small"
          id="outlined-basic"
          variant="outlined"
        />
      </div>
      <div className="fr" style={{ alignItems: "center", width: "100%" }}>
        <span style={{ width: "30%" }}>New Password</span>{" "}
        <TextField
          fullWidth
          size="small"
          id="outlined-basic"
          variant="outlined"
        />
      </div>
      <div className="fr" style={{ alignItems: "center", width: "100%" }}>
        <span style={{ width: "30%" }}>Confirm Password</span>{" "}
        <TextField
          fullWidth
          size="small"
          id="outlined-basic"
          variant="outlined"
        />
      </div>
      <Button sx={{ width: "fit-content" }} variant="contained">
        Change Password
      </Button>
    </div>
  );
};

export default ChangePassword;
